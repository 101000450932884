import { FIND_VIDEOS, Video, Videos } from '@/apollo/queries/videos/videos';
import { Settings } from '@/settings';
import { Component, OnInit } from '@angular/core';
import { Apollo } from 'apollo-angular';

@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.scss']
})
export class VideosComponent implements OnInit
{
    introContent: string;
    backendUrl: string = Settings.backEndUrl;

    videos: Video[] | undefined;

    constructor(private apollo: Apollo)
    {
        this.introContent = "Les cétacés sont protégés dans le sanctuaire Agoa des Antilles françaises.<br><br>Nos vidéos ont été réalisées dans le respect des cétacés,<br>même si certaines datent d'avant la mise en place de la charte d'approche actuellement en vigueur.<br>Certains curieux n'hésitent pas à venir près...";
    }

    ngOnInit(): void
    {
        // Videos
        this.apollo.query<Videos>({ query: FIND_VIDEOS }).subscribe(result => 
        {
            this.videos = result.data.videos;
        });
    }
}
