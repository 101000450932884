
<!-- Photo Full Viewport -->
<app-presentation title="Les cétacés" [content]="introContent" [removeGradient]="true" urlImage="assets/images/cetaces/Autres/DSC_5501aaa-Laurent-BOUVERET.jpg"></app-presentation>

<app-submenu [links]="links" [useThemeDark]="false"></app-submenu>
<separator id="qec"></separator>

<!-- Qu'est-ce ? -->
<div class="container-fluid">

    <app-title title="Qu'est-ce qu'un cétacé"></app-title>
    <app-content-double>
        <div content-left>

            <app-subtitle-txt>
                <div title class="color-blue-medium">Ce sont des mammifères marins</div>
                <div content>
                    <ul class="text-start">
                        <li>Ils portent et allaitent leurs petits.</li>
                        <li>Ils respirent de l’air à la surface avec leurs poumons.</li>
                        <li>Ils ont des poils appelés vibrisses.</li>
                        <li>Ils sont homéothermes, la température de leur corps reste stable, indépendamment du milieu ambiant.</li>
                        <li>Leur vie est liée au milieu aquatique. Leurs plus proches parents actuels sont les hippopotames.</li>
                    </ul>
                </div>
            </app-subtitle-txt>

        </div>
        <div content-right>

            <app-subtitle-txt>
                <div title class="color-blue-medium">Origine</div>
                <div content>
                    Leur ancêtre, Pakicetus, est apparu il y a environ 55 millions d'années.<br><br>
                    Ce nom d'origine latine et grecque désignait respectivement "un grand poisson" ou "un monstre marin".<br><br>
                    Les cétacés comprennent les baleines, les cachalots et les dauphins. 
                </div>
            </app-subtitle-txt>

        </div>
    </app-content-double>

    <app-content-double>
        <div content-left>

            <app-subtitle-txt>
                <div title class="color-yellow-medium">Combien sont-ils dans le monde ?</div>
                <div content>
                    La cétologie qui correspond à la branche de la zoologie étudiant les cétacés, permet en 2020 de recenser dans le monde 84 espèces dont :<br>
                    70 espèces d’odontocètes ou cétacés à dents (ex : Grand dauphin, Cachalot, Orque, etc...) et 14 espèces de mysticètes ou cétacés à fanons (ex : Baleine à bosse, Baleine bleue, etc...).<br><br>
                    Les chiffres évoluent avec les découvertes.
                </div>
            </app-subtitle-txt>

        </div>
        <div content-right>
            
            <app-subtitle-txt>
                <div title class="color-yellow-medium">Combien sont-ils dans les Antilles ?</div>
                <div content>
                    Voici la liste des 22 espèces des Antilles françaises dont les observations sont documentées en 2020 :<br><br>
                    Dauphin tacheté pantropical - Cachalot - Grand dauphin<br>
                    Dauphin de Fraser - Baleine à bosse - Globicéphale tropical<br>
                    Sténo rostré - Baleine à bec de Cuvier - Baleine à bec de Gervais<br>
                    Dauphin tacheté de l’Atlantique - Pseudorque - Péponocéphale<br>
                    Orque - Cachalot nain - Cachalot pygmée<br>
                    Petit rorqual ou Baleine de Minke - Dauphin à long bec - Dauphin de Clymène<br>
                    Rorqual de Bryde - Orque naine - Dauphin de Risso<br>
                    Dauphin rayé ou bleu et blanc
                </div>
            </app-subtitle-txt>

        </div>
    </app-content-double>

    <separator heightRem="8"></separator>
</div>

<separator-color></separator-color>

<!-- Un peu d'evolution -->
<div class="container-fluid background-paper">

    <separator id="evo"></separator>
    <app-title title="Un peu d'évolution !"></app-title>

    <app-content-double verticalAlign="center">
    <div content-left>
        
        <app-subtitle-txt [bgDisableInLargeScreen]="true">
            <div title class="color-yellow-medium">De la terre ...</div>
            <div content>
                Il y a 55 millions d’années, l’ancêtre commun de tous les cétacés ressemblait à un loup et vivait sur la terre.<br>Il a été appelé Pakicetus car il a été découvert au Pakistan.<br>
                Son alimentation était surtout composée de coquillages.<br>
                Progressivement, sur 5 millions d’années, son corps s’est allongé et a commencé à se transformer.<br>
            </div>
        </app-subtitle-txt>

    </div>
    <div content-right>
        <img class="img-fluid" src="assets/images/cetaces_illustration/Evolution_Pakicetus.png" />
    </div>
    </app-content-double>

    <app-content-double verticalAlign="center">
    <div content-left>
        
        <app-subtitle-txt [bgDisableInLargeScreen]="true">
            <div title class="color-turquoise-medium">... l'évolution continue ...</div>
            <div content>
                L’Ambulocétus est devenu amphibie et plus performant dans l’eau, grâce à ses pattes qui se sont transformées en nageoires.<br>
            </div>
        </app-subtitle-txt>

    </div>
    <div content-right>
        <img class="img-fluid" src="assets/images/cetaces_illustration/Evolution_Ambulocetus.png" />
    </div>
    </app-content-double>

    <app-content-double verticalAlign="center" >
    <div content-left>
        
        <app-subtitle-txt [bgDisableInLargeScreen]="true">
            <div title class="color-blue-medium">... jusqu'à l’océan</div>
            <div content>
                Il y a 40 millions d’années, le Dorudon vivait exclusivement dans l‘eau. 
            </div>
        </app-subtitle-txt>

    </div>
    <div content-right>
        <img class="img-fluid" src="assets/images/cetaces_illustration/Evolution_Dorudon.png" />
    </div>
    </app-content-double>

    <app-content-double verticalAlign="center">
    <div content-left>
        
        <app-subtitle-txt [bgDisableInLargeScreen]="true">
            <div title>Aujourd'hui</div>
            <div content>
                Aujourd'hui, les pattes avant sont devenues des nageoires tandis que les pattes arrière ont progressivement disparu.
            </div>
        </app-subtitle-txt>

    </div>
    <div content-right>
        <img class="img-fluid" src="assets/images/cetaces_illustration/Evolution_Baleine_Dauphin.png" />
    </div>
    </app-content-double>

    <separator></separator>

    <app-content-double>
    <div content-left>

        <app-subtitle-txt [bgDisableInLargeScreen]="true">
            <div title>Adaptation morphologique pour la locomotion</div>
            <div content>
                Leur corps est élancé très souvent en forme de torpille pour l'hydrodynamisme, les poils ont quasiment disparu ce qui leur permet de glisser avec peu de frottements dans l'eau.<br>
                Leur queue s'est transformée en une nageoire très puissante.  
            </div>
        </app-subtitle-txt>

    </div>
    <div content-right>

        <app-subtitle-txt [bgDisableInLargeScreen]="true">
            <div title>Adaptation morphologique pour la respiration</div>
            <div content>
                Leurs poumons sont proportionnellement plus grands et de nombreuses adaptations existent pour leur permettre de retenir leur respiration plus longtemps et d'aller très profond.<br>
            </div>
        </app-subtitle-txt>

    </div>
    </app-content-double>

    <app-content-double>
        <div content-left>
    
            <app-subtitle-txt [bgDisableInLargeScreen]="true">
                <div title>Fanons ou dents ?</div>
                <div content>
                    Chez les cétacés appelés mysticètes, les dents ont été remplacées par des fanons et ils possèdent un évent double (avec deux narines) tandis que les odontocètes ont gardé des dents et possèdent un évent simple (avec une seule narine).
                </div>
            </app-subtitle-txt>
    
        </div>
        
        <div content-right>
    
            <div class="fanonImg"></div>
    
        </div>
    </app-content-double>

    <separator heightRem="8"></separator>
</div>

<separator-color></separator-color>

<!-- Capacités sensorielles -->
<div class="container-fluid bg-blue-dark">
    <separator id="cas"></separator>
    <app-title title="Capacités sensorielles" [useThemeDark]="true"></app-title>
    <app-specificities [specificities]="sensoryAbilities"></app-specificities>

    <separator></separator>
</div>

<separator-color></separator-color>

<!-- Echolocation -->
<div class="container-fluid">

    <separator id="eco"></separator>
    <app-title title="L'écholocation. Un super pouvoir ?"></app-title>
    
    <div class="container">
        <div class="row">
            <div class="col typo_3">
                Comment "voir" dans l'obscurité ou chasser quand l'eau est trouble ? 
            </div>
        </div>
    </div>

    <app-content-double verticalAlign="center">
        <div content-left>
            
            <app-subtitle-txt>
                <div title>Qu'est ce que c'est ?</div>
                <div content>
                    L’écholocation ou sonar ne concerne que les cétacés à dents (ou odontocètes).<br>
                    Ce sont des clics ou ondes sonores qui partent de leur tête et qu’ils envoient devant eux.
                </div>
            </app-subtitle-txt>
    
        </div>
        <div content-right>
            <img class="img-fluid" src="assets/images/cetaces_illustration/Echolocation_schema.jpg" />
        </div>
    </app-content-double>

    <separator></separator>
</div>

<app-bg-adaptable urlImage="assets/images/cetaces/Steno/STE_4.jpg">

    <separator></separator>

    <app-content-double>
        <div content-left>
            
            <app-subtitle-txt>
                <div title>Comment ça marche ?</div>
                <div content>
                    Ces clics vont rebondir sur leur environnement, par exemple sur une proie ou sur un obstacle, et seront captés en retour par la mâchoire inférieure, l’oreille interne puis le cerveau.<br>
                    Le cétacé obtient alors une «image mentale» de son environnement.
                </div>
            </app-subtitle-txt>
            
        </div>
        <div content-right>
            
            <app-subtitle-txt>
                <div title>A quoi ça sert ?</div>
                <div content>
                    Grâce à ce système d’écholocation, les odontocètes peuvent "voir" leur environnement dans l'eau trouble et l'obscurité.<br>
                    Ils peuvent aussi discerner les animaux par exemple cachés dans le sable.<br>
                    C'est le même principe que l'échographie réalisée par un médecin pour observer le foetus d'une femme enceinte.
                </div>
            </app-subtitle-txt>
    
        </div>
    </app-content-double>

    <separator></separator>

</app-bg-adaptable>

<div class="container-fluid bg-blue-dark">

    <separator></separator>
    <app-title title="Comment communiquent-ils entre eux ?" [useThemeDark]="true"></app-title>
    <app-specificities [specificities]="sensoryCommunication"></app-specificities>

    <separator></separator>

    <div class="container">
        <div class="row">
            <div class="col">
                <div class="color-blue-light typo_3 mb-32r">Écoute le chant d'un mâle Baleine à bosse !</div>
                <audio class="w-100 test" controls src="assets/sounds/HumpbackSong.mp3">Your browser does not support the <code>audio</code> element.</audio>
            </div>
        </div>
    </div>
    
    <separator heightRem="8"></separator>
</div>

<separator-color></separator-color>

<!-- Capacité physique -->
<div class="container-fluid">

    <separator id="cap"></separator>
    <app-title title="Les capacités physiques incroyables des cétacés"></app-title>
</div>

<app-bg-adaptable bgColor="bg-blue-light">

    <app-content-double verticalAlign="center">
        <div content-right>
            
            <img class="img-fluid" src="assets/images/cetaces_illustration/Anatomie_BottleNose.png" />

        </div>
        <div content-left>

            <app-subtitle-txt>
                <div title>PARTICULARITÉS ANATOMIQUES</div>
                <div content>
                    Les cétacés sont recouverts d'une épaisse couche de lard qui les protège du froid et qui peut aller jusqu'à 50 cm d'épaisseur !<br>
                    L'estomac des cétacés est composé le plus souvent de 3 poches.
                </div>
            </app-subtitle-txt>

        </div>
    </app-content-double>

</app-bg-adaptable>

<app-bg-adaptable urlImage="assets/images/cetaces/Autres/DSC_0817c-Cedric-MILLON.jpg" bgColor="bg-blue-dark">

    <app-content-double verticalAlign="center">

        <div content-left>
            
            <app-subtitle-txt [bgDisableInLargeScreen]="true" bgColor="bg-blue-dark">
                <div title class="color-blue-light">LA RESPIRATION</div>
                <div content class="color-blue-light">
                    La durée des apnées peuvent aller de quelques minutes pour le dauphin à 90 minutes pour le Cachalot, et 2 heures pour la Baleine à bec de Cuvier, championne d’apnée.<br>
                    Les cétacés respirent grâce à un évent simple ou double fermé et étanche sous l’eau, qui s’ouvre par un acte volontaire en remontant à la surface pour expirer et reprendre de l’air. Les voies respiratoires sont totalement séparées des voies digestives.
                </div>
            </app-subtitle-txt>

        </div>
    </app-content-double>

    <separator></separator>
</app-bg-adaptable>

<app-bg-adaptable bgColor="bg-blue-light">

    <app-content-double verticalAlign="center">
        <div content-left>
            
            <img class="img-fluid" src="assets/images/cetaces_illustration/Reproduction_Bottlenose.png" />

        </div>
        <div content-right>

            <app-subtitle-txt>
                <div title>LA REPRODUCTION</div>
                <div content>
                    La plupart des espèces ont une saisonnalité de reproduction et de naissances.<br>
                    Par exemple, les Baleines à bosse s'accouplent entre janvier et mai dans les Antilles et la femelle mettra bas l'année suivante.<br>
                    L’accouplement des dauphins s’effectue ventre à ventre en quelques secondes.
                </div>
            </app-subtitle-txt>

        </div>
    </app-content-double>

</app-bg-adaptable>

<app-bg-adaptable bgColor="bg-blue-light">

    <app-content-double verticalAlign="center">
        <div content-right>
            
            <img class="img-fluid" src="assets/images/cetaces_illustration/Cachalot_Naissance.png" />

        </div>
        <div content-left>
            
            <app-subtitle-txt>
                <div title>LA NAISSANCE</div>
                <div content>
                    Une fois le terme atteint, le plus souvent autour de un an, le nouveau-né sort la queue la première, puis remonte très vite à la surface avec parfois l’aide de sa mère.<br>
                    Il va alors effectuer ses premières respirations, puis ira téter le lait très dense de sa mère toutes les demi-heures les premiers jours de sa vie, puis plusieurs fois par jour.
                </div>
            </app-subtitle-txt>

        </div>
    </app-content-double>

    <separator heightRem="8"></separator>

</app-bg-adaptable>

<separator-color></separator-color>

<app-bg-adaptable bgColor="bg-blue-gradient">

    <separator></separator>

    <!-- Partenaires Scient -->
    <div class="container">
        <div class="row title_1">
            <div class="col typo_1 color-blue-light">Comment, quand et où observer les cétacés?</div>
        </div>
        <img class="mt-8r mb-32r" src="assets/images/Separator_Multi.svg"/>
    </div>

    <app-content-double verticalAlign="center">
        <div content-left>
            
            <img class="img-fluid" src="assets/images/cetaces_illustration/ObservationWhaleWatcher.png" />

        </div>
        <div content-right>
            
            <app-subtitle-txt buttonName="Sanctuaire Agoa" buttonUrl="https://www.sanctuaire-agoa.fr" urlTarget="_blank" [bgDisableInLargeScreen]="true" bgColor="bg-blue-dark">
                <div title class="color-yellow-medium">Via les observateurs de cétacés</div>
                <div content class="color-blue-light">
                    Il est possible de voir les cétacés dans les Antilles en utilisant les services d'un "whalewatcher" ou "observateur de cétacés"...<br>
                    C'est à dire faire une excursion pour aller à la recherche des cétacés dans leur milieu naturel.<br><br>
                    Les prestataires obtiennent l'autorisation d'exercer après avoir reçu une formation par le sanctuaire Agoa.<br>
                    Certaines espèces sont présentes toute l'année comme les Cachalots femelles et les immatures et d'autres sont de passage à certaines périodes de l'année comme la Baleine à bosse ou le Cachalot mâle qui migrent alors dans leur aire de reproduction. 
                </div>
            </app-subtitle-txt>

        </div>
    </app-content-double>

    <app-content-double verticalAlign="center">
        <div content-right>
            
            <img class="img-fluid" src="assets/images/cetaces_illustration/ObservationJumelles.png" />

        </div>
        <div content-left>
            
            <app-subtitle-txt [bgDisableInLargeScreen]="true" bgColor="bg-blue-dark">
                <div title class="color-yellow-medium">À l'aide de jumelles</div>
                <div content class="color-blue-light">
                    L'observation est aussi possible depuis la côte à l'aide de jumelles. Il faut rester attentif.<br>
                    Installez-vous en hauteur, par exemple à la Pointe de la Grande Vigie en Guadeloupe.<br><br>
                    Le matin et le soir, les rayons du soleil sont obliques et les souffles se voient mieux.<br>
                    Il faut en plus rechercher des claqués de nageoires, des éclaboussures, ...<br><br>
                    Voici le matériel idéal : une montre, une casquette, des lunettes de soleil polarisantes, des jumelles.<br>
                    A plusieurs, on peut se partager l'horizon en secteurs.<br>
                    Formez-vous en téléchargeant notre application gratuite jojosousleau. Et faîtes remonter vos témoignages aux observatoires de cétacés.
                </div>
            </app-subtitle-txt>

        </div>
    </app-content-double>
    
    <separator heightRem="8"></separator>

</app-bg-adaptable>

<separator-color></separator-color>

<!-- Menaces -->
<div class="position-relative container-fluid bg-blue-gradient pb-128r">

    <img src="assets/images/dechets/Dechets_Barquette.png" class="position-absolute garbage anim_1" style="top: 5%; left: 15%; opacity: .25; width: 20vh; transform: rotate(0deg);" />
    <img src="assets/images/dechets/Dechets_Megots_1.png" class="position-absolute garbage anim_2" style="top: 7%; right: 18%; opacity: .5; width: 20vh; transform: rotate(0deg);" />
    <img src="assets/images/dechets/Dechets_Bidon.png" class="position-absolute garbage anim_3" style="top: 15%; left: 24%; opacity: .4; width: 20vh; transform: rotate(0deg);" />
    <img src="assets/images/dechets/Dechets_BouteillePlastique1.png" class="position-absolute garbage anim_1" style="top: 15%; right: 21%; opacity: .3; width: 20vh; transform: rotate(0deg);" />
    <img src="assets/images/dechets/Dechets_BouteillePlastique2.png" class="position-absolute garbage anim_3" style="top: 23%; left: 17%; opacity: .6; width: 20vh; transform: rotate(0deg);" />
    <img src="assets/images/dechets/Dechets_Cannette1.png" class="position-absolute garbage anim_2" style="top: 30%; right: 24%; opacity: .5; width: 20vh; transform: rotate(0deg);" />
    <img src="assets/images/dechets/Dechets_Cannette2.png" class="position-absolute garbage anim_2" style="top: 38%; left: 24%; opacity: .5; width: 20vh; transform: rotate(0deg);" />
    <img src="assets/images/dechets/Dechets_Conserve.png" class="position-absolute garbage anim_1" style="top: 44%; right: 18%; opacity: .5; width: 20vh; transform: rotate(45deg);" />
    <img src="assets/images/dechets/Dechets_Couteau.png" class="position-absolute garbage anim_3" style="top: 45%; left: 16%; opacity: .5; width: 20vh; transform: rotate(0deg);" />
    <img src="assets/images/dechets/Dechets_Cuillere.png" class="position-absolute garbage anim_2" style="top: 52%; right: 9%; opacity: .5; width: 20vh; transform: rotate(60deg);" />
    <img src="assets/images/dechets/Dechets_Fourchette.png" class="position-absolute garbage anim_1" style="top: 61%; left: 24%; opacity: .5; width: 15vh; transform: rotate(0deg);" />
    <img src="assets/images/dechets/Dechets_Paille.png" class="position-absolute garbage anim_3" style="top: 60%; right: 21%; opacity: .5; width: 20vh; transform: rotate(0deg);" />
    <img src="assets/images/dechets/Dechets_Filet_1.png" class="position-absolute garbage anim_2" style="top: 66%; left: 20%; opacity: .5; width: 20vh; transform: rotate(0deg);" />
    <img src="assets/images/dechets/Dechets_Filet_2.png" class="position-absolute garbage anim_3" style="top: 66%; right: 24%; opacity: .5; width: 20vh; transform: rotate(0deg);" />
    <img src="assets/images/dechets/Dechets_Filet_3.png" class="position-absolute garbage anim_1" style="top: 72%; right: 22%; opacity: .5; width: 20vh; transform: rotate(0deg);" />
    <img src="assets/images/dechets/Dechets_Batterie.png" class="position-absolute garbage anim_2" style="top: 78%; left: 16%; opacity: .5; width: 20vh; transform: rotate(0deg);" />
    <img src="assets/images/dechets/Dechets_Megots_2.png" class="position-absolute garbage anim_3" style="top: 84%; right: 21%; opacity: .5; width: 20vh; transform: rotate(0deg);" />
    <img src="assets/images/dechets/Dechets_Sac.png" class="position-absolute garbage anim_1" style="top: 90%; left: 22%; opacity: .15; width: 20vh; transform: rotate(0deg);" />
    <img src="assets/images/dechets/Dechets_TigeCotton.png" class="position-absolute garbage anim_1" style="top: 91%; right: 23%; opacity: .5; width: 20vh; transform: rotate(0deg);" />

    <div class="position-relative layerFront">
        <separator id="men"></separator>

        <div class="container-fluid">
            <div class="container">
                <div class="row title_1">
                    <div class="col typo_1 color-blue-light">Quelles menaces pour les cétacés ?</div>
                </div>
                <img class="mt-8r mb-32r" src="assets/images/Separator_Multi.svg"/>
            </div>
        </div>
    
        
        <app-content-single-center>
            <div content-center>
    
                <app-subtitle-txt bgColor="bg-blue-medium" [bgDisableInLargeScreen]="true">
                    <div title class="color-blue-light">La chasse des petits cétacés</div>
                    <div content class="color-blue-light">
                        Les dauphins sont tués pour leur viande bien que les taux de métaux lourds (toxiques pour l’Homme) présents dans la chair des cétacés dépassent largement les limites imposées par la législation Européenne.<br>
                        Ils sont jugés impropres à la consommation pourtant plusieurs pays les chassent encore pour les consommer comme à Saint-Vincent-et-les-Grenadines, au Japon ou encore aux îles Féroë.
                    </div>
                </app-subtitle-txt>
    
            </div>
        </app-content-single-center>
    
        <app-content-single-center>
            <div content-center>
    
                <app-subtitle-txt bgColor="bg-blue-medium" [bgDisableInLargeScreen]="true">
                    <div title class="color-turquoise-medium">Les pêches accidentelles</div>
                    <div content class="color-turquoise-medium">
                        Une grande quantité de dauphins meurt chaque année à cause de la pêche, on appelle cela des prises accidentelles.<br>
                        La plupart du temps elles sont dues à leur capture dans les grands filets des pêcheries industrielles.<br>
                        Incapables de remonter respirer à la surface, les dauphins finissent par mourir noyés…<br>
                        En France 1 600 dauphins seraient tués chaque année dans les filets des bateaux de pêche le long de la côte Atlantique française.
                    </div>
                </app-subtitle-txt>
    
            </div>
        </app-content-single-center>
    
        <app-content-single-center>
            <div content-center>
    
                <app-subtitle-txt bgColor="bg-blue-medium" [bgDisableInLargeScreen]="true">
                    <div title class="color-blue-light">Capture de dauphins au Japon</div>
                    <div content class="color-blue-light">
                        Au Japon, les pêcheurs capturent des groupes entiers de dauphins pour les delphinariums et la consommation.<br>
                        On estime que 23 000 dauphins sont tués chaque année dans la baie de Taiji, appellé aussi «la baie de la honte». 
                    </div>
                </app-subtitle-txt>
    
            </div>
        </app-content-single-center>
    
        <app-content-single-center>
            <div content-center>
    
                <app-subtitle-txt bgColor="bg-blue-medium" [bgDisableInLargeScreen]="true">
                    <div title class="color-turquoise-medium">Les delphinariums</div>
                    <div content class="color-turquoise-medium">
                        En delphinarium les dauphins vivent dans des conditions tellements inadaptées à leur mode de vie qu'ils vivent deux fois moins longtemps que dans la nature.<br>
                        Certains finissent par se donner la mort en fermant leur évent ou en se tapant la tête contre les murs du bassin.
                    </div>
                </app-subtitle-txt>
    
            </div>
        </app-content-single-center>
    
        <app-content-single-center>
            <div content-center>
    
                <app-subtitle-txt bgColor="bg-blue-medium" [bgDisableInLargeScreen]="true">
                    <div title class="color-blue-light">Autres menaces pour les dauphins</div>
                    <div content class="color-blue-light">
                        Au Pérou, des dauphins sont tués pour servir d’appâts aux requins, ou encore certains dauphins ont été capturés puis dressés en dauphins-soldats pour détecter les mines sous-marines, repérer les ennemis, ou même poser des bombes.<br>
                        Cette pratique semble disparaître mais certains pays continuent de l'utiliser.
                    </div>
                </app-subtitle-txt>
    
            </div>
        </app-content-single-center>
    
        <app-content-single-center>
            <div content-center>
    
                <app-subtitle-txt bgColor="bg-blue-medium" [bgDisableInLargeScreen]="true">
                    <div title class="color-turquoise-medium">Histoire de la chasse des grands cétacés</div>
                    <div content class="color-turquoise-medium">
                        Les baleines et les Cachalots ont été massacrés par l’Homme, surtout entre le 18e et le 20e siècle principalement pour l'utilisation de leur graisse et de leur viande.
                    </div>
                </app-subtitle-txt>
    
            </div>
        </app-content-single-center>
    
        <app-content-single-center>
            <div content-center>
    
                <app-subtitle-txt bgColor="bg-blue-medium" [bgDisableInLargeScreen]="true">
                    <div title class="color-blue-light">De nos jours, la chasse des grands cétacés</div>
                    <div content class="color-blue-light">
                        En 2021, la Norvège, l’Islande et le Japon chassent encore pour des raisons commerciales.<br>
                        Des pays comme Saint-Vincent-et-les-Grenadines, la Russie (Chukotka), les USA (Alaska) et le Groenland ont des quotas proposés par la CBI et validés entre autres par la France pour une chasse de «subsistance».<br>
                        Entre 1 000 et 2 000 baleines seraient capturées dans le monde chaque année par les pays baleiniers.
                    </div>
                </app-subtitle-txt>
    
            </div>
        </app-content-single-center>
    
        <app-content-single-center>
            <div content-center>
    
                <app-subtitle-txt bgColor="bg-blue-medium" [bgDisableInLargeScreen]="true">
                    <div title class="color-turquoise-medium">Les déchets</div>
                    <div content class="color-turquoise-medium">
                        Les macro et micro-plastiques s’accumulent dans leur organisme à tel point que certains animaux marins meurent de faim, leur estomac étant encombré de matières plastiques.
                    </div>
                </app-subtitle-txt>
    
            </div>
        </app-content-single-center>
    
    
        <app-content-single-center>
            <div content-center>
    
                <app-subtitle-txt bgColor="bg-blue-medium" [bgDisableInLargeScreen]="true">
                    <div title class="color-blue-light">Les engins de pêche</div>
                    <div content class="color-blue-light">
                        Les filets et les DCP (Dispositifs de Concentration de Poissons) causent des décès importants chez les mammifères marins.<br>
                        La surpêche entraîne la raréfaction de la nourriture des cétacés.
                    </div>
                </app-subtitle-txt>
    
            </div>
        </app-content-single-center>
        
        <app-content-single-center>
            <div content-center>
    
                <app-subtitle-txt bgColor="bg-blue-medium" [bgDisableInLargeScreen]="true">
                    <div title class="color-turquoise-medium">Les filets fantômes</div>
                    <div content class="color-turquoise-medium">
                        Ces filets dérivant en mer causent des décès importants chez les mammifères marins qui ne peuvent plus remonter respirer à la surface ou qui doivent tracter un poids qui les épuisent mortellement.
                    </div>
                </app-subtitle-txt>
    
            </div>
        </app-content-single-center>
    
        <app-content-single-center>
            <div content-center>
    
                <app-subtitle-txt bgColor="bg-blue-medium" [bgDisableInLargeScreen]="true">
                    <div title class="color-blue-light">Les fluides polluants</div>
                    <div content class="color-blue-light">
                        Les produits de synthèse et les métaux lourds se fixent dans la graisse des cétacés et dans le lait des mères allaitantes.
                    </div>
                </app-subtitle-txt>
    
            </div>
        </app-content-single-center>
    
        <app-content-single-center>
            <div content-center>
    
                <app-subtitle-txt bgColor="bg-blue-medium" [bgDisableInLargeScreen]="true">
                    <div title class="color-turquoise-medium">Les nuisances sonores</div>
                    <div content class="color-turquoise-medium">
                        Les nuisances sonores comme le bruit des hélices ou les tirs sismiques sont aussi un danger pour ces animaux très acoustiques.<br>
                        Les risques sont une perturbation de la communication ou une désorientation qui peuvent conduire à un échouage massif.
                    </div>
                </app-subtitle-txt>
    
            </div>
        </app-content-single-center>
    
        <app-content-single-center>
            <div content-center>
    
                <app-subtitle-txt bgColor="bg-blue-medium" [bgDisableInLargeScreen]="true">
                    <div title class="color-blue-light">Dérangement / collision</div>
                    <div content class="color-blue-light">
                        Le dérangement par les Humains peut aussi les stresser et modifier leurs comportements.<br>
                        Ces nuisances causeraient des échouages de certains cétacés.<br>
                        Par ailleurs, les risques de collision sont accrus avec l'augmentation du trafic maritime.<br>
                        Une baisse de fécondité, des défenses immunitaires et l’apparition de maladies sont alors observées.
                    </div>
                </app-subtitle-txt>
    
            </div>
        </app-content-single-center>
    </div>

</div>