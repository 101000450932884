import { FIND_NEWLETTER_RECEIVERS, NewletterReceiver, NewletterReceivers } from '@/apollo/queries/newletter-receiver/newletter-receiver';
import { AuthenticationService } from '@/services/authentication.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Apollo } from 'apollo-angular';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-membadmin-newsletter',
  templateUrl: './membadmin-newsletter.component.html',
  styleUrls: ['./membadmin-newsletter.component.scss']
})
export class MembadminNewsletterComponent implements OnInit
{
    private readonly NB_RECIPIENT_MAX: number = 4;

    title: string;
    nbReceivers: number = 0;

    newsletterReceiversChunked: string[] | undefined;
    
    watchNewsletterReceiverSubscription : Subscription | undefined;
  
    constructor(private apollo: Apollo, private authenticationService: AuthenticationService, private formBuilder : FormBuilder)
    {
        this.title = "Bonjour " + authenticationService.currentUserValue.username + ",";
        
        // NewsletterReceivers
        this.watchNewsletterReceiverSubscription = this.apollo.watchQuery<NewletterReceivers>({
            query: FIND_NEWLETTER_RECEIVERS
        })
        .valueChanges.subscribe(result => 
        {
            this.nbReceivers = result.data.newletterReceivers.length;

            this.newsletterReceiversChunked = [];
            let chunkCpt = 0;
            for (let i=0, j=this.nbReceivers; i<j; i += this.NB_RECIPIENT_MAX)
            {
                let recipientChunk = result.data.newletterReceivers.slice(i, i + this.NB_RECIPIENT_MAX);
                
                this.newsletterReceiversChunked[chunkCpt] = recipientChunk.map(x => x.email).toString();
                chunkCpt++;
            }
        });
    }

    ngOnInit(): void
    {
    }

    onSendNewletterClick()
    {
        
    }
}
