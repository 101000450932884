import { Route } from '@/models/route';
import { AuthenticationService } from '@/services/authentication.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-membadmin',
  templateUrl: './membadmin.component.html',
  styleUrls: ['./membadmin.component.scss']
})
export class MembadminComponent
{
    /*public get ConnexionError() : boolean {
        return this.connexionError;
    }*/

    public connexionError : boolean = false;

    constructor(private authenticationService: AuthenticationService, private router: Router)
    {
    }

    onAuthenticationSubmit(username : string, password : string)
    {
        this.connexionError = false;

        this.authenticationService.login(username, password).pipe(first()).subscribe(
            data => {
                this.router.navigate([Route.MembAdminNewletter]); 
            },
            error => {
                this.connexionError = true;
            }
        );
    }
}
