import { Link } from '@/models/link';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-submenu',
  templateUrl: './submenu.component.html',
  styleUrls: ['./submenu.component.scss']
})
export class SubmenuComponent
{
    @Input() links: Array<Link> | null = null;
    @Input() useThemeDark: boolean = true;
    
    @Input() dllFr: string = '';
    @Input() dllEn: string = '';
    @Input() dllEs: string = '';
    
    onDownloadFr()
    {
        window.open(this.dllFr, "_blank");
    }
    onDownloadEn()
    {
        window.open(this.dllEn, "_blank");
    }
    onDownloadEs()
    {
        window.open(this.dllEs, "_blank");
    }
}
