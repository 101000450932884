

import gql from "graphql-tag";
import { ActivityDifficulty } from "../activity-filters/activity-filters";
import { ImageMedia, PdfMedia } from "../general/general";

export interface EscapeGame
{
    title: string;
    description: string;
    linkFrUrl: string;
    linkEsUrl: string;
    linkEnUrl: string;

    image: ImageMedia;
    activity_difficulty: ActivityDifficulty;
}

export interface EscapeGames
{
    escapeGames: EscapeGame[];
}

export const FIND_ESCAPEGAMES = gql`
query EscapeGames
{
    escapeGames
    {
        title
        description
        linkFrUrl
        linkEsUrl
        linkEnUrl
        
        image
        {
            url
        }
        activity_difficulty
        {
            difficulty
        }
    }
}`;