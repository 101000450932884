
import { Cetacean, Cetaceans, FIND_CETACEANS } from '@/apollo/queries/cetaceans/cetaceans';
import { Settings } from '@/settings';
import { Component, OnInit } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-cetaceans',
  templateUrl: './cetaceans.component.html',
  styleUrls: ['./cetaceans.component.scss']
})
export class CetaceansComponent implements OnInit
{
    backendUrl: string = Settings.backEndUrl;

    cetaceans: Cetacean[] | undefined;

    watchCetaceansSubscription : Subscription | undefined;

    constructor(private apollo: Apollo)
    {
    }

    ngOnInit(): void
    {
        // Cetaceans
        this.watchCetaceansSubscription = this.apollo.watchQuery<Cetaceans>({
            query: FIND_CETACEANS
        })
        .valueChanges.subscribe(result => 
        {
            this.cetaceans = result.data.cetaceans;
        });
    }
}
