
import gql from "graphql-tag";

export interface ActivityDifficulty
{
    id: string;
    difficulty: string;
}

export interface ActivityTheme
{
    id: string;
    name: string;
    selected: boolean;
}

export interface ActivityType
{
    id: string;
    name: string;
    selected: boolean;
}

export interface ActivitySubject
{
    id: string;
    name: string;
    selected: boolean;
}

export interface ActivityThemes
{
    activityThemes: ActivityTheme[];
}

export interface ActivityTypes
{
    activityTypes: ActivityType[];
}

export interface ActivitySubjects
{
    activitySubjects: ActivitySubject[];
}

export const FIND_ACTIVITY_THEMES = gql`
query ActivityThemes
{
    activityThemes
    {
        id
        name
    }
}`;

export const FIND_ACTIVITY_TYPES = gql`
query ActivityTypes
{
    activityTypes
    {
        id
        name
    }
}`;

export const FIND_ACTIVITY_SUBJECTS = gql`
query ActivitySubjects
{
    activitySubjects
    {
        id
        name
    }
}`;