import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-vote',
  templateUrl: './vote.component.html',
  styleUrls: ['./vote.component.scss']
})
export class VoteComponent implements OnInit
{
    @Input() daysRemaining: number = 0;

    constructor()
    {
    }

    ngOnInit(): void
    {
    }
}
