
import { AnnualReport, AnnualReports, FIND_ANNUAL_REPORTS } from '@/apollo/queries/annual-reports/annual-reports';
import { Employee, Employees, FIND_EMPLOYEES } from '@/apollo/queries/employees/employees';
import { FIND_FRIENDS, Friends } from '@/apollo/queries/friends/friends';
import { FIND_PARTNERS, FIND_PARTNERS_ASSOC, FIND_PARTNERS_SCIENTIFIC, Partner, PartnerAssoc, Partners, PartnersAssociations, PartnerScientific, PartnersScientific } from '@/apollo/queries/partners/partners';
import { Component, OnInit } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Settings } from '@/settings';
import { Organizer, SingleElement } from '@/models/organizer';
import { Link } from '@/models/link';
import { Specificity } from '@/apollo/queries/cetaceans/cetaceans';

@Component({
  selector: 'app-association',
  templateUrl: './association.component.html',
  styleUrls: ['./association.component.scss']
})
export class AssociationComponent implements OnInit
{
    introContent: string;

    backendUrl: string = Settings.backEndUrl;

    links : Array<Link>;

    benevolesWithPhoto: Employee[] | undefined;
    benevolesWithoutPhoto: Employee[] | undefined;
    principalMembersWithPhoto: Employee[] | undefined;
    principalMembersWithoutPhoto: Employee[] | undefined;
    employeesWithPhoto: Employee[] | undefined;
    employeesWithoutPhoto: Employee[] | undefined;

    halfBenevolesWithoutPhoto: number = 0;
    halfPrincipalMembersWithoutPhoto: number = 0;
    halfEmployeesWithoutPhoto: number = 0;

    partners: Partner[] | undefined;
    partnersScientific: PartnerScientific[] | undefined;
    partnersAssoc: PartnerAssoc[] | undefined;
    annualReports: AnnualReport[] | undefined;

    organizerAssocObjectives: Organizer | undefined;
    organizerPartnersAssoc: Organizer | undefined;
    organizerPartnersScientific: Organizer | undefined;
    organizerFriends: Organizer | undefined;
    organizerAssocTools: Organizer | undefined;

    associationNumbers: Specificity[];

    constructor(private apollo: Apollo)
    {
        // Intro
        this.introContent = "<b>Mon École, Ma Baleine (MEMB)</b> est une association créée en Guadeloupe en 2011 avec deux illustres parrains : Albert Falco et Michel Météry, deux anciens compagnons du commandant Cousteau.<br><br>" +
            "Son but est de faire connaître les cétacés dans leur milieu naturel pour mieux les protéger et préserver leur habitat.<br><br>" +
            "Elle réalise des interventions, conférences et stands dans les milieux publics et scolaires.<br><br>" +
            "Elle vulgarise les données scientifiques avec de nombreux outils pédagogiques classés par niveau et dans plusieurs langues.<br><br>" +
            "MEMB a des antennes à St Martin, St Barthélemy et en Martinique, elle a aussi des contacts en métropole et à l'étranger.  MEMB fait partie du comité de gestion du Sanctuaire Agoa.";

        // Valeur et objectif
        this.organizerAssocObjectives = { 
            elements: [
                { icon: null, buttonName: null, buttonUrl: null, buttonUseCta: false, urlTarget: null, title: "Protéger", description: "Les cétacés sont protégés partout en France. Dans les Antilles françaises, une aire marine a été mise en place pour les protéger : le sanctuaire Agoa. Pour mieux protéger, il faut connaître. Comprendre l'impact de nos activités, comme la pollution par exemple, sur les cétacés permet de changer nos habitudes et ainsi mieux les protéger." },
                { icon: null, buttonName: null, buttonUrl: null, buttonUseCta: false, urlTarget: null, title: "Apprendre", description: "De nombreux outils très variés et en plusieurs langues sont mis à disposition gratuitement afin que parents, enseignants, familles puissent s'approprier ce fragile mais riche patrimoine naturel. " },
                { icon: null, buttonName: null, buttonUrl: null, buttonUseCta: false, urlTarget: null, title: "Comprendre", description: "Les études sur les cétacés permettent d'avoir de nouvelles données réactualisées régulièrement. Mon École, Ma Baleine reçoit des résultats de plusieurs scientifiques et structures, qu'elle vulgarise avec des outils pédagogiques variés afin de mieux comprendre les changements et découvertes." },
                { icon: null, buttonName: null, buttonUrl: null, buttonUseCta: false, urlTarget: null, title: "Respecter", description: "Mon École, Ma Baleine établit des partenariats quand il n'y a pas de lien avec la captivité ou d'impact négatif sur la biodiversité." },
                { icon: null, buttonName: null, buttonUrl: null, buttonUseCta: false, urlTarget: null, title: "Éthique", description: "Nos outils en ligne sont gratuits, à la disposition de tous, sauf si c'est pour une structure politique, religieuse, commerciale ou détenant les animaux en captivité." }
            ] 
        }

        this.organizerAssocTools = {
            elements: [
                { icon: null, buttonName: null, buttonUrl: null, buttonUseCta: false, urlTarget: null, title: "Outils en ligne", description: "Pour mieux comprendre les cétacés, vous pourrez utiliser nos histoires, chansons, fiches d'activités, films, notre application Jojosouleau (6 jeux 3D téléchargeables et en 3 langues), des cartes d'identité sur les cétacés..." },
                { icon: null, buttonName: null, buttonUrl: null, buttonUseCta: false, urlTarget: null, title: "Outils imprimés distribués", description: "Nos outils imprimés peuvent être donnés sur demande aux enseignants ou structures qui ont un projet de sensibilisation à la protection de la biodiversité. Cahiers d'activités, livres sur les cétacés des Antilles françaises, illustrés..." },
                { icon: null, buttonName: "La boutique", buttonUrl: "https://maboutiquemabaleine.com", buttonUseCta: false, urlTarget: "_blank", title: "Boutique en ligne", description: "Venez découvrir nos jeux de société éducatifs, cahiers d'exercices, illustrés, jeux de cartes, bijoux sur les cétacés, tatouages temporaires..." }
            ] 
        }

        // L'association en quelques chiffres
        this.associationNumbers = [
            { quantity: "200", name: "interventions", description: "en moyenne par an. Le but est de sensibiliser grâce à la vulgarisation de données scientifiques acquises au cours des dernières années sur les cétacés dans les Antilles." },
            { quantity: "4 500", name: "personnes", description: "C'est le nombre de personnes rencontrées en moyenne chaque année, lors des stands et interventions." },
            { quantity: "10", name: "ans", description: "En 2021, cela fait 10 ans que 3 copines se retrouvaient pour rédiger les statuts de Mon École, Ma baleine. Belle aventure !" },
            { quantity: "12 000", name: "visiteurs", description: "qui lisent nos infos du mois depuis la création en 2011 !" }
        ]

        // Menu links
        this.links = [
            { url: "Association#val", name: "Valeurs & Objectifs" },
            { url: "Association#int", name: "Nos interventions" },
            { url: "Association#mem", name: "Les membres" },
            { url: "Association#bil", name: "Nos bilans" },
            { url: "Association#par", name: "Nos partenaires & Amis" },
        ];
    }

    ngOnInit(): void
    {
        // Employees
        this.apollo.query<Employees>({
            query: FIND_EMPLOYEES
        }).subscribe(result => 
        {
            this.benevolesWithPhoto = result.data.employees.filter(em => em.employee_type && em.employee_type.suffix === "ben" && em.photo_adult !== null);
            this.benevolesWithoutPhoto = result.data.employees.filter(em => em.employee_type && em.employee_type.suffix === "ben" && em.photo_adult === null);
            this.principalMembersWithPhoto = result.data.employees.filter(em => em.employee_type && em.employee_type.suffix === "bur" && em.photo_adult !== null);
            this.principalMembersWithoutPhoto = result.data.employees.filter(em => em.employee_type && em.employee_type.suffix === "bur" && em.photo_adult === null);
            this.employeesWithPhoto = result.data.employees.filter(em => em.employee_type && em.employee_type.suffix === "sal" && em.photo_adult !== null);
            this.employeesWithoutPhoto = result.data.employees.filter(em => em.employee_type && em.employee_type.suffix === "sal" && em.photo_adult === null);

            /*
            this.benevolesWithPhoto = this.benevolesWithPhoto.sort(this.sortEmployeeByOrder);
            this.benevolesWithoutPhoto = this.benevolesWithoutPhoto.sort(this.sortEmployeeByOrder);
            this.principalMembersWithPhoto = this.principalMembersWithPhoto.sort(this.sortEmployeeByOrder);
            this.principalMembersWithoutPhoto = this.principalMembersWithoutPhoto.sort(this.sortEmployeeByOrder);
            this.employeesWithPhoto = this.employeesWithPhoto.sort(this.sortEmployeeByOrder);
            this.employeesWithoutPhoto = this.employeesWithoutPhoto.sort(this.sortEmployeeByOrder);
            */

            this.halfBenevolesWithoutPhoto = Math.round(this.benevolesWithoutPhoto.length / 2);
            this.halfPrincipalMembersWithoutPhoto = Math.round(this.principalMembersWithoutPhoto.length / 2);
            this.halfEmployeesWithoutPhoto = Math.round(this.employeesWithoutPhoto.length / 2);
        });

        // Partners
        this.apollo.query<Partners>({
            query: FIND_PARTNERS
        })
        .subscribe(result => 
        {
            this.partners = result.data.partners;
        });

        // Partners Assoc
        this.apollo.query<PartnersAssociations>({
            query: FIND_PARTNERS_ASSOC
        })
        .subscribe(result => 
        {
            let organizers: SingleElement[] = [];
            result.data.partnersAssociations.forEach((partnerA) =>
            {
                let singleElem: SingleElement = { title: partnerA.name, description: partnerA.description, icon: null, urlTarget: "_blank", buttonName: "Visiter", buttonUrl: partnerA.link, buttonUseCta: false };
                organizers.push(singleElem);
            });
            this.organizerPartnersAssoc = { elements: organizers };
        });

        // Partners Assoc
        this.apollo.query<PartnersScientific>({
            query: FIND_PARTNERS_SCIENTIFIC
        })
        .subscribe(result => 
        {
            let organizers: SingleElement[] = [];
            result.data.partnersScientifics.forEach((partnerS) =>
            {
                let singleElem: SingleElement = { title: partnerS.name, description: partnerS.description, icon: null, urlTarget: "_blank", buttonName: "Visiter", buttonUrl: partnerS.link, buttonUseCta: false };
                organizers.push(singleElem);
            });
            this.organizerPartnersScientific = { elements: organizers };
        });

        // Friends
        this.apollo.query<Friends>({
            query: FIND_FRIENDS
        })
        .subscribe(result => 
        {
            let organizers: SingleElement[] = [];
            result.data.friends.forEach((friend) =>
            {
                let singleElem: SingleElement = { title: friend.name, description: friend.description, icon: null, urlTarget: "_blank", buttonName: "Visiter", buttonUrl: friend.link, buttonUseCta: false };
                organizers.push(singleElem);
            });
            this.organizerFriends = { elements: organizers };
        });

        // Annual reports
        this.apollo.query<AnnualReports>({
            query: FIND_ANNUAL_REPORTS
        })
        .subscribe(result => 
        {
            this.annualReports = result.data.annualReports;
        });
    }

    sortEmployeeByOrder(e1: Employee, e2: Employee): number
    {
        if (e1 === null && e2 === null) return 0;
        if (e1 === null && e2 !== null) return 1;
        if (e1 !== null && e2 === null) return -1;

        if (e1.order < e2.order) return -1;
        if (e1.order > e2.order) return 1;
        return 0;
    }
}
