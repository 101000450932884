

import gql from "graphql-tag";
import { ActivityDifficulty } from "../activity-filters/activity-filters";
import { ImageMedia, PdfMedia } from "../general/general";

export interface Story
{
    name: string;
    description: string;

    image: ImageMedia;
    activity_difficulty: ActivityDifficulty;

    pdf_fr: PdfMedia;
    pdf_en: PdfMedia;
    pdf_es: PdfMedia;
}

export interface Stories
{
    stories: Story[];
}

export const FIND_STORIES = gql`
query Stories
{
    stories
    {
        name
        description
        activity_difficulty
        {
            difficulty
        }
        image
        {
            url
        }
        pdf_fr
        {
            url
        }
        pdf_en
        {
            url
        }
        pdf_es
        {
            url
        }
    }
}`;