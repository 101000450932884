import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-content-single-button',
  templateUrl: './content-single-button.component.html',
  styleUrls: ['./content-single-button.component.scss']
})
export class ContentSingleButtonComponent implements OnInit
{
    @Input() title: string = '';
    @Input() content: string = '';
    
    @Input() useCta: boolean = false;
    @Input() buttonName: string | null = null;
    @Input() urlTarget: string | null = "_self";
    @Input() buttonUrl: string | null  = null;

    constructor()
    {
    }

    ngOnInit(): void 
    {
    }

    onBtnClicked() :void
    {
        if (this.buttonUrl && this.urlTarget)
        {
            window.open(this.buttonUrl, this.urlTarget)
        }
    }
}
