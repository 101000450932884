import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'btnfilter',
  templateUrl: './btnfilter.component.html',
  styleUrls: ['./btnfilter.component.scss']
})
export class BtnfilterComponent implements OnInit
{
    @Input() name: string = "";
    @Input() color: number = 0;
    @Input() isSelected: boolean = false;
    @Input() useCross: boolean = true;
    
    constructor()
    {
    }

    ngOnInit(): void
    {
    }
}
