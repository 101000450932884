
<div class="container-fluid background-cetacean">

    <app-presentation title="Nos escape games et autres jeux" [content]="introContent"></app-presentation>

</div>

<app-submenu [links]="links" [useThemeDark]="false"></app-submenu>

<!-- Escape Games -->
<div class="container-fluid bg-blue-gradient">
    <separator id="epg"></separator>
    <app-title title="Nos escape games" [useFlag]="true"></app-title>

    <div class="container">
        <div class="row" id="scrollTarget">

            <app-card-type-a *ngFor="let egame of escapeGames; let i = index" class="col-xs-12 col-lg-3 g-0"
                [idCard]="i"
                [name]="egame.title"
                [description]="egame.description"
                [difficulty]="egame.activity_difficulty ? egame.activity_difficulty.difficulty : '?'"
                [urlImage]="egame.image ? backendUrl + egame.image.url : ''"
                [urlActivityFr]="egame.linkFrUrl ? egame.linkFrUrl : ''"
                [urlActivityEn]="egame.linkEnUrl ? egame.linkEnUrl : ''"
                [urlActivityEs]="egame.linkEsUrl ? egame.linkEsUrl : ''">
            </app-card-type-a>
            
        </div>
    </div>
    <separator heightRem="8"></separator>
</div>

<!-- Jeux Flash -->
<separator id="jfl"></separator>
<app-title title="Nos jeux flash"></app-title>

<app-bg-adaptable bgColor="bg-blue-light">
    <app-content-double verticalAlign="center">
        <div content-left>
            <img class="img-fluid" src="assets/images/jeux_flash/Silhouette.PNG" />
        </div>
        <div content-right>
    
            <app-subtitle-txt [bgDisableInLargeScreen]="true">
                <div title class="color-blue-medium">Méli Mélo chez les mammifères marins</div>
                <div content class="color-blue-dark d-flex flex-column align-items-center">
                    <div class="mb-48r">
                        Voici un jeu facile comme tout !<br><br>
                        Il te faut recomposer parfaitement le corps de 7 mammifères marins.<br>
                        Pour cela sélectionne la tête, puis fais défiler les corps et les queues dans chacune des colonnes pour reconstituer les animaux et découvrir des infos rigolotes sur eux !<br><br>
                        Bonne découverte !
                    </div>
                    
                    <a class="standardButtonBlueMedium typo_8" href="assets/flashgames/MeliMelo.zip">Télécharger</a>

                </div>
            </app-subtitle-txt>
    
        </div>
    </app-content-double>
    
    <separator heightRem="8"></separator>
</app-bg-adaptable>

<app-bg-adaptable bgColor="bg-blue-medium">
    
    <separator></separator>

    <app-content-double verticalAlign="center">
        <div content-left>
    
            <app-subtitle-txt [bgDisableInLargeScreen]="true">
                <div title class="color-blue-dark">Anatomie de la baleine</div>
                <div content class="d-flex flex-column align-items-center color-blue-light">
                    <div class="mb-48r">
                        Dans ce jeu, tu dois replacer aux bons endroits les étiquettes nommant les parties du corps de la Baleine à bosse.<br><br>
                        Facile ! 
                    </div>
                    
                    <a class="standardButtonBlueDark typo_8" href="assets/flashgames/AnatomieDeLaBaleine.zip">Télécharger</a>
                </div>
            </app-subtitle-txt>
    
        </div>
        <div content-right>
    
            <img class="img-fluid" src="assets/images/jeux_flash/Anatomie.PNG" />
        </div>
    </app-content-double>
    
    <separator heightRem="8"></separator>

</app-bg-adaptable>


<app-bg-adaptable bgColor="bg-yellow-medium">
    
    <separator></separator>

    <app-content-double verticalAlign="center">
        <div content-left>
    
            <img class="img-fluid" src="assets/images/jeux_flash/Sons.PNG" />
    
        </div>
        <div content-right>
    
            <app-subtitle-txt [bgDisableInLargeScreen]="true">
                <div title class="color-blue-medium">Des sons dans la mer</div>
                <div content class="d-flex flex-column align-items-center color-blue-dark">
                    <div class="mb-48r">
                        Le monde du silence ? Même pas vrai !<br><br>
                        Les cétacés sont bavards et produisent de nombreux sons très variés !<br><br>
                        Dans ce jeu, tu vas devoir retrouver qui produit quel son.<br><br>Bonne écoute !
                    </div>
                    
                    <a class="standardButtonBlueMedium typo_8" href="assets/flashgames/SonsDeLaMer.zip">Télécharger</a>
                </div>
            </app-subtitle-txt>

        </div>
    </app-content-double>
    
    <separator heightRem="8"></separator>

</app-bg-adaptable>

<app-bg-adaptable bgColor="bg-blue-light">
    
    <separator></separator>

    <app-content-double verticalAlign="center">
        <div content-left>
    
            <app-subtitle-txt [bgDisableInLargeScreen]="true">
                <div title>Coloriages aquatiques</div>
                <div content class="d-flex flex-column align-items-center">
                    <div class="mb-48r">
                        Dans cet atelier de coloriage tu dois redonner des couleurs à nos trois amis :<br>
                        Adam le Cachalot, Fanny la Baleine à bosse et Jojo le Grand dauphin.
                    </div>
                    
                    <a class="standardButtonBlueDark typo_8" href="assets/flashgames/ColoriagesAquatiques.zip">Télécharger</a>
                </div>
            </app-subtitle-txt>
    
        </div>
        <div content-right>
            <img class="img-fluid" src="assets/images/jeux_flash/Coloriage.PNG" />
        </div>
    </app-content-double>
    
    <separator heightRem="8"></separator>

</app-bg-adaptable>

<app-bg-adaptable bgColor="bg-blue-medium">

    <separator></separator>

    <app-content-double verticalAlign="center">
        <div content-left>
            <img class="img-fluid" src="assets/images/jeux_flash/Memory.PNG" />
        </div>
        <div content-right>
    
            <app-subtitle-txt [bgDisableInLargeScreen]="true">
                <div title class="color-blue-light">Le memory de la mer</div>
                <div content class="color-blue-light d-flex flex-column align-items-center">
                    <div class="mb-48r">
                        Pour reconnaître un mammifère marin en pleine mer il faut avoir l'oeil et avoir une bonne mémoire de sa forme, sa couleur, etc...<br><br>
                        Dans ce jeu, tu vas devoir retrouver les 12 paires qui composent ce memory.<br><br>
                        Bonne chance !
                    </div>
                    
                    <a class="standardButtonBlueLight typo_8" href="assets/flashgames/MemoryDeLaMer.zip">Télécharger</a>
                </div>
            </app-subtitle-txt>
    
        </div>
    </app-content-double>

    <separator heightRem="8"></separator>

</app-bg-adaptable>

<app-bg-adaptable bgColor="bg-yellow-medium">
    
    <separator></separator>

    <app-content-double verticalAlign="center">
        <div content-left>
    
            <app-subtitle-txt [bgDisableInLargeScreen]="true">
                <div title>Le dauphin en balade</div>
                <div content class="d-flex flex-column align-items-center">
                    <div class="mb-48r">
                        Transforme-toi en dauphin.<br>
                        Tu obtiendras alors un nouveau pouvoir : l'écholocation.<br><br>
                        Des ondes vont partir de ton front ou melon pour rebondir sur les objets ou animaux, puis revenir vers toi pour former une image dans ton cerveau.<br>
                        Une autre façon de voir sans utiliser tes yeux !<br>
                        Ainsi, le dauphin peut se déplacer en évitant les dangers et trouver sa nourriture même cachée dans le sable ou dans le noir !
                    </div>
                    <a class="standardButtonBlueDark typo_8" href="assets/flashgames/DauphinBallade.zip">Télécharger</a>
                </div>
            </app-subtitle-txt>
    
        </div>
        <div content-right>
    
            <img class="img-fluid" src="assets/images/jeux_flash/Jeudauphin.PNG" />
        </div>
    </app-content-double>

    <separator heightRem="8"></separator>

</app-bg-adaptable>


<separator-color></separator-color>


<!-- Bulles de sciences -->
<separator id="bds"></separator>
<app-title title="Nos bulles de science" [useFlag]="true"></app-title>

          
<div class="container">
    <div class="row">

        <div class="typo_3 ml-32r mb-48r">Des diaporamas interactifs accessibles dès 10 ans présentent plusieurs fonctions de 3 cétacés : anatomie, respiration, alimentation, organes des sens, reproduction, locomotion, ...</div>

        <app-card-type-a class="col-xs-12 col-lg-3 g-0" 
            [idCard]="0"
            name="Bulle baleine"
            description="Tout sur la baleine !"
            urlImage="./assets/images/sciencebubbles/Bulle_Humpback.jpg"
            urlActivityFr="./assets/sciencebubbles/exe/BULLE-BALEINE-FR.zip"
            urlActivityEn="./assets/sciencebubbles/exe/BULLE-BALEINE-EN.zip"
            urlActivityEs="./assets/sciencebubbles/exe/BULLE-BALEINE-ES.zip"
            urlCorrectionFr="./assets/sciencebubbles/mac/BULLE-BALEINE-FR.zip"
            urlCorrectionEn="./assets/sciencebubbles/mac/BULLE-BALEINE-EN.zip"
            urlCorrectionEs="./assets/sciencebubbles/mac/BULLE-BALEINE-ES.zip"
            [scienceBubbleAlternative]="true">
        </app-card-type-a>

        <app-card-type-a class="col-xs-12 col-lg-3 g-0" 
            [idCard]="1"
            name="Bulle cachalot"
            description="Plein d'info sur le cachalot !"
            urlImage="./assets/images/sciencebubbles/Bulle_Cachalot.jpg"
            urlActivityFr="./assets/sciencebubbles/exe/BULLE-CACHALOT-FR.zip"
            urlActivityEn="./assets/sciencebubbles/exe/BULLE-CACHALOT-EN.zip"
            urlActivityEs="./assets/sciencebubbles/exe/BULLE-CACHALOT-ES.zip"
            urlCorrectionFr="./assets/sciencebubbles/mac/BULLE-CACHALOT-FR.zip"
            urlCorrectionEn="./assets/sciencebubbles/mac/BULLE-CACHALOT-EN.zip"
            urlCorrectionEs="./assets/sciencebubbles/mac/BULLE-CACHALOT-ES.zip"
            [scienceBubbleAlternative]="true">
        </app-card-type-a>

        <app-card-type-a class="col-xs-12 col-lg-3 g-0" 
            [idCard]="2"
            name="Bulle dauphin"
            description="Tous les secrets du dauphin !"
            urlImage="./assets/images/sciencebubbles/Bulle_Bottlenose.jpg"
            urlActivityFr="./assets/sciencebubbles/exe/BULLE-DAUPHIN-FR.zip"
            urlActivityEn="./assets/sciencebubbles/exe/BULLE-DAUPHIN-EN.zip"
            urlActivityEs="./assets/sciencebubbles/exe/BULLE-DAUPHIN-ES.zip"
            urlCorrectionFr="./assets/sciencebubbles/mac/BULLE-DAUPHIN-FR.zip"
            urlCorrectionEn="./assets/sciencebubbles/mac/BULLE-DAUPHIN-EN.zip"
            urlCorrectionEs="./assets/sciencebubbles/mac/BULLE-DAUPHIN-ES.zip"
            [scienceBubbleAlternative]="true">
        </app-card-type-a>
        
    </div>
</div>

<separator heightRem="8"></separator>

<!-- Divers -->
<div class="container-fluid bg-blue-dark">
    <separator id="dvs"></separator>
    <app-title title="Divers" [useThemeDark]="true" [useFlag]="true"></app-title>

    <div class="container">
        <div class="row" id="scrollTarget">

            <div *ngIf="variousActivities && variousActivities.length === 0" class="color-blue-light typo_3">En cours de préparation. Surprise !</div>

            <app-card-type-a *ngFor="let variousAct of variousActivities; let i = index" class="col-xs-12 col-lg-3 g-0"
                [idCard]="i"
                [name]="variousAct.title"
                [description]="variousAct.description"
                [difficulty]="variousAct.activity_difficulty ? variousAct.activity_difficulty.difficulty : '?'"
                [urlImage]="variousAct.image ? backendUrl + variousAct.image.url : ''"
                [urlActivityFr]="variousAct.pdf_fr ? backendUrl + variousAct.pdf_fr.url : ''"
                [urlActivityEn]="variousAct.pdf_en ? backendUrl + variousAct.pdf_en.url : ''"
                [urlActivityEs]="variousAct.pdf_es ? backendUrl + variousAct.pdf_es.url : ''">
            </app-card-type-a>
            
        </div>
    </div>
    <separator heightRem="8"></separator>
</div>

<!-- A venir -->
<separator id="cmu"></separator>
<app-title title="À venir"></app-title>

<div *ngIf="toComes && toComes.length === 0" class="container">
    <div class="row">
        <div class="color-blue-dark typo_3">En cours de préparation. À venir !</div>
    </div>
</div>

<div class="container">
    <div class="row">

        <ng-container *ngFor="let toCome of toComes">
            <app-card-type-d class="col-xs-12 col-lg-3 g-0" 
                [name]="toCome.title"
                [subname]="toCome.description"
                [urlImageStandard]="toCome.image ? backendUrl + toCome.image.url : ''">
            </app-card-type-d>
        </ng-container>

    </div>
</div>

<separator heightRem="8"></separator>