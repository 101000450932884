
<div class="container">
    <div class="row mb-96r">
        <div class="col">
            
            <app-title [title]="title" [useTopSpace]="true"></app-title>

            <div class="typo_4 mb-5">
                Il y a {{ nbReceivers }} personnes inscrites à la newsletter.
            </div>
            
            <div class="container" >
                <div class="row row-cols-auto">
                    <a class="col standardButton mx-16r my-8r" *ngFor="let chunk of newsletterReceiversChunked; let i = index" [attr.href]="'mailto:' + chunk">Emailing {{ i }}</a>
                </div>
            </div>
        </div>
    </div>
</div>
