import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-type-b',
  templateUrl: './card-type-b.component.html',
  styleUrls: ['./card-type-b.component.scss']
})
export class CardTypeBComponent
{
    @Input() name: string = '';
    @Input() subname: string | null = '';    
    @Input() urlImage: string = '';
}
