
<div class="container">
    <div class="row align-items-center">

        <div class="col-12 col-lg-6">
            
            <div class="bg-blue-light-wa-std px-48r py-48r">

                <div class="title_1">
                    <div class="col typo_1">{{ title }}</div>
                </div>
                <img class="mt-8r mb-32r" src="assets/images/Separator_Multi.svg"/>
    
                <div class="typo_3">
                    <ng-content></ng-content>
                </div>
            </div>
            
        </div>

    </div>

    <div class="row mt-5 mt-lg-0">
        <div class="d-flex justify-content-end ">

            <a *ngIf="!useCta" class="standardButton typo_8" (click)="onBtnClicked()">{{ buttonName }}</a>
            <btncta *ngIf="useCta" (click)="onBtnClicked()">{{ buttonName }}</btncta>

        </div>
    </div>
</div>