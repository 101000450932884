
<div *ngIf="quizzes && quizzes.length > 0 && currentQuiz" class="container-fluid bg-blue-gradient overflow-hidden">

    <div class="container">

        <!-- Questions -->
        <div class="row calmos-1" [ngClass]="{'active': answerGiven}">

            <separator id="qzz"></separator>

            <div class="offset-lg-1 col-lg-5">

                <div class="position-relative bg-bubble-big">
                    <img class="w-100 h-auto" src="../../../assets/images/bubble_big.svg" />
                    <div class="position-absolute top-50 start-0 translate-50 typo_3 text-white text-center px-64r">{{ currentQuiz.question }}</div>
                </div>

            </div>
        </div>

        <!-- Choix réponses -->
        <div class="row mb-32r">

            <div class="position-relative col-lg-5 offset-lg-6 g-0">

                <div class="container bg-blue-medium-wa-std bg-rounded px-48r py-48r">
                    <div class="row row-cols-1 row-cols-lg-2 align-items-center text-center typo_3 lh-small text-white">
                        <div *ngIf="currentQuiz.answer1" class="col cursor-pointer" (click)="onSelectAnswer(1)" [ngClass]="{ 'strike-through': answerGiven && answerIdCorrect !== 1 }">{{ currentQuiz.answer1 }}</div>
                        <div *ngIf="currentQuiz.answer2" class="col cursor-pointer" (click)="onSelectAnswer(2)" [ngClass]="{ 'strike-through': answerGiven && answerIdCorrect !== 2 }">{{ currentQuiz.answer2 }}</div>
                        <div *ngIf="currentQuiz.answer3" class="col cursor-pointer mt-32r" (click)="onSelectAnswer(3)" [ngClass]="{ 'strike-through': answerGiven && answerIdCorrect !== 3 }">{{ currentQuiz.answer3 }}</div>
                        <div *ngIf="currentQuiz.answer4" class="col cursor-pointer mt-32r" (click)="onSelectAnswer(4)" [ngClass]="{ 'strike-through': answerGiven && answerIdCorrect !== 4 }">{{ currentQuiz.answer4 }}</div>
                    </div>
                </div>

            </div>
        </div>

        <!-- Réponses finales -->
        <div class="row calmos-2" [ngClass]="{'active': answerGiven}">

            <div class="offset-lg-1 col-lg-5 mb-32r">

                <div class="position-relative bg-bubble-big">
                    <img class="w-100 h-auto" src="../../../assets/images/bubble_big.svg" />
                    <div class="position-absolute top-50 start-0 translate-50 text-center px-64r">
                        <div class="typo_answer text-white text-center">{{ answerCorrect ? currentQuiz.resultGood : currentQuiz.resultBad }}</div>
                    </div>
                </div>

            </div>

            <div class="col-lg-5 offset-lg-6 d-flex justify-content-end">
                <div class="container bg-blue-medium-wa-std bg-rounded px-48r py-48r cursor-pointer" (click)="onNextQuestionClick()">
                    <div class="row row-cols-1 row-cols-lg-2 align-items-center text-center typo_3 lh-small text-white">{{ !isLastQuestion ? "Question suivante s'il te plaît Calmos !" : "Mince déjà la fin ? Je recommence !" }}</div>
                </div>
            </div>
        </div>

        <separator heightRem="8"></separator>

    </div>
</div>