
<app-menu></app-menu>

<!--
<div class="position-relative">
    <div class="position-absolute top-0 start-0 w-100" style="pointer-events: none; z-index: 10000;">
        <div class="container">
            <div class="row" style="height: 5000vh;">
                <div class="col bg-debug-red"><div class="h-100 bg-debug-green"></div></div>
                <div class="col bg-debug-red"><div class="h-100 bg-debug-green"></div></div>
                <div class="col bg-debug-red"><div class="h-100 bg-debug-green"></div></div>
                <div class="col bg-debug-red"><div class="h-100 bg-debug-green"></div></div>
                 
                <div class="col bg-debug-red"><div class="h-100 bg-debug-green"></div></div>
                <div class="col bg-debug-red"><div class="h-100 bg-debug-green"></div></div>
                <div class="col bg-debug-red"><div class="h-100 bg-debug-green"></div></div>
                <div class="col bg-debug-red"><div class="h-100 bg-debug-green"></div></div>
                 
                <div class="col bg-debug-red"><div class="h-100 bg-debug-green"></div></div>
                <div class="col bg-debug-red"><div class="h-100 bg-debug-green"></div></div>
                <div class="col bg-debug-red"><div class="h-100 bg-debug-green"></div></div>
                <div class="col bg-debug-red"><div class="h-100 bg-debug-green"></div></div>
            </div>
        </div>
    </div>
</div>
-->

<router-outlet (activate)="onActivate()"></router-outlet>

<app-footer-follow-us></app-footer-follow-us>
<app-footer-pages-list></app-footer-pages-list>
<app-footer-understand></app-footer-understand>
<app-footer-copyright></app-footer-copyright>