
<div class="container-fluid bg-blue-dark overflow-hidden">
    <div class="container">
        <div class="row">
            <div class="col-10 offset-1">

                <div class="carousel-container">
                    <div class="photo-legend" *ngFor="let picture of gallery; let i = index" [ngStyle]="{'transform': 'translate(' + (-100 * currentPhoto - (i-currentPhoto) * 20) + '%, 0px)'}">>

                        <div class="photo" [ngClass]="i==currentPhoto ? 'active' : 'nextprev'"
                            (click)="onActionClick(i, currentPhoto)"
                            [ngStyle]="{ 'background-image': 'url(' + backendUrl + picture.url + ')' }">
                        </div>
                        
                        <div *ngIf="picture.caption" class="typo_4 color-blue-light d-flex flex-column align-items-center text-center legend" [ngClass]="{ 'active': i==currentPhoto }">
                            <img class="mt-16r mb-8r legend-arrow" src="assets/images/Separator_Med_Yellow.svg"/>
                            {{ picture.caption }}
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>