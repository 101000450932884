import { CREATE_NEWLETTER_RECEIVER } from '@/apollo/queries/newletter-receiver/newletter-receiver';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Apollo } from "apollo-angular";

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent implements OnInit
{
    get form() { return this.newsletterForm.controls; }

    newsletterForm: FormGroup;

    submittedForm: boolean = false;

    submitSuccess: boolean = false;
    submitError: boolean = false;
    duplicateError: boolean = false;
    isLoading: boolean = false;

    constructor(private apollo: Apollo, private formBuilder : FormBuilder)
    {
        this.newsletterForm = this.formBuilder.group({
            email: [null, [Validators.required, Validators.email]],
        });
    }

    ngOnInit(): void
    {
    }

    onNewsletterSubmit(): void
    {
        this.submittedForm = true;
        
        if (this.newsletterForm.valid)
        {
            this.isLoading = true;

            this.apollo.mutate({
                mutation: CREATE_NEWLETTER_RECEIVER,
                variables: {
                    input:  {
                        data: { 
                            email: this.newsletterForm.value.email, 
                        }
                    }
                }
            }).subscribe((result) => 
            {
                this.submitSuccess = true;
                this.isLoading = false;
            }, (error) => {

                this.isLoading = false;
                this.submitError = true;
                if (error.message == "Duplicate entry")
                {
                    this.duplicateError = true;
                }
            });
        }
    }
}
