import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'btncta',
  templateUrl: './btncta.component.html',
  styleUrls: ['./btncta.component.scss']
})
export class BtnctaComponent implements OnInit
{
    @Input() disabled: boolean = false;
  
    constructor()
    {
    }

    ngOnInit(): void
    {
    }
}
