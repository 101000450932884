import { Specificity } from '@/apollo/queries/cetaceans/cetaceans';
import { Link } from '@/models/link';
import { Route } from '@/models/route';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-jojosousleau',
  templateUrl: './jojosousleau.component.html',
  styleUrls: ['./jojosousleau.component.scss']
})
export class JojosousleauComponent implements OnInit
{
    introContent: string = "Suis Jojo le dauphin dans son aventure dans les eaux tropicales.<br><br>" +
        "Calmos le Calamar te fera écouter les chants des dauphins et des baleines, découvrir leur anatomie, leur mode de vie, les menaces qui pèsent sur eux, <br>" +
        "… et tous les habitants marins qui les entourent : tortues, requins poissons, coraux.<br><br>" +
        "Tu apprendras à reconnaître certains dauphins et baleines en un coup de nageoire !<br>";
        
    specWhatisJojo: Specificity[];
    links : Array<Link>;

    constructor()
    {
        this.specWhatisJojo = [
            { name: "Explorer", quantity: "", description: "Différents environnements sous-marins du sanctuaire Agoa en compagnie d’un calamar qui n’a pas sa langue dans sa poche. Testez votre curiosité." },
            { name: "Découvrez les cétacés...", quantity: "", description: "Apprenez à les reconnaître, à les écouter, à connaître leur mode de vie, leur fragilité aussi. et laissez-vous étonner par leurs capacités sensorielles incroyables, notamment  leur système d'écholocation." },
            { name: "Apprendre et se tester", quantity: "", description: "A travers 6 mini-jeux, Calmos le Calamar va vous présenter le sage Strombi, un Lambi aux connaissances infinies. Il vous testera et vous fera accéder à des niveaux supérieurs de connaissances." },
            { name: "De nombreux poissons et coraux", quantity: "", description: "Qui peuplent l'univers de nos cétacés. Découvrez leur milieu naturel, celui des Caraïbes, avant de mettre un masque et d'aller voir par vous-même !" }
        ];

        // Menu links
        this.links = [
            { url: Route.JojoSousLeau + "#tel", name: "Télécharger" },
            { url: Route.JojoSousLeau + "#dec", name: "Découvrir" },
            { url: Route.JojoSousLeau + "#per", name: "Nos personnages" },
        ];
    }

    ngOnInit(): void
    {
    }

    dllWindows(): void
    {
        window.open("./assets/jojosousleau/JojoSousLeauSetup.exe", "_blank");
    }
    dllIOS(): void
    {
        window.open("https://apps.apple.com/fr/app/jojosousleau/id1544288649", "_blank");
    }
    dllAndroid(): void
    {
        window.open("https://play.google.com/store/apps/details?id=com.dlproduction.jojosousleau&hl=fr&gl=US", "_blank");
    }
}
