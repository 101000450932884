import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-bg-fluid',
  templateUrl: './bg-fluid.component.html',
  styleUrls: ['./bg-fluid.component.scss']
})
export class BgFluidComponent implements OnInit
{
    useImage: boolean = false;

    @Input() urlImage: string | undefined;

    constructor()
    {
    }
    
    ngOnInit(): void
    {
        this.useImage = this.urlImage != undefined;
    }
}
