import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-content-single-center',
  templateUrl: './content-single-center.component.html',
  styleUrls: ['./content-single-center.component.scss']
})
export class ContentSingleCenterComponent
{
}
