import { ImageMedia } from '@/apollo/queries/general/general';
import { Settings } from '@/settings';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit
{
    @Input() gallery: ImageMedia[] = [];
    
    backendUrl: string = Settings.backEndUrl;

    currentPhoto: number = 0;

    constructor()
    {
    }

    ngOnInit(): void
    {
    }

    onPrevClick()
    {
        this.currentPhoto--;
        if (this.currentPhoto < 0)
        {
            this.currentPhoto = 0;
        }
    }

    onNextClick()
    {
        this.currentPhoto++;
        if (this.currentPhoto >= this.gallery.length - 1)
        {
            this.currentPhoto = this.gallery.length - 1;
        }
    }

    onActionClick(i: number, c:number)
    {
        if (i> c) this.onNextClick();
        else if (i < c) this.onPrevClick();
    }
}
