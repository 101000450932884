
<ng-container *ngIf="cetacean">

    <!-- Photo Full Viewport -->
    <app-presentation [title]="cetaceanName" [content]="introSentence" [urlImage]="backendUrl + cetacean!.primeImage.url"></app-presentation>

    <app-submenu [links]="links"
        [dllFr]="cetacean.paperToPrintFr ? backendUrl + cetacean.paperToPrintFr.url : ''"
        [dllEn]="cetacean.paperToPrintEn ? backendUrl + cetacean.paperToPrintEn.url : ''"
        [dllEs]="cetacean.paperToPrintEs ? backendUrl + cetacean.paperToPrintEs.url : ''"></app-submenu>

    <!-- Cetacean Particularities -->
    <div class="container-fluid bg-blue-dark pb-128r">
        <separator id="par"></separator>
        
        <app-title title="Particularités" [useThemeDark]="true"></app-title>
        <app-specificities [specificities]="cetacean!.specificities"></app-specificities>
    </div>

    <!-- Anatomie -->
    <div class="container-fluid bg-blue-medium bubble">
        <separator-color id="ana"></separator-color>
        <app-ceta-anatomy [cetaceanId]="cetaceanId"></app-ceta-anatomy>
        <separator-color></separator-color>
    </div>

    <!-- Cetacean Behaviors Part 1 -->
    <app-bg-adaptable *ngIf="organizerCetaBehav_part1!.elements.length > 0" bgColor="bg-blue-light" [urlImage]="cetacean!.backgroundImage[0] ? backendUrl + cetacean!.backgroundImage[0].url : undefined">
        <separator></separator>
        
        <app-organizer [organizer]="organizerCetaBehav_part1"></app-organizer>

        <separator heightRem="8"></separator>
        <separator-color></separator-color>
    </app-bg-adaptable>
    
    <!-- Did you know -->
    <div class="container-fluid bgMotifPaperQuestion">
        <div class="container">
            <div class="row">
                <div class="col-10 offset-1 col-xl-8 offset-xl-2 didyouknow">

                    <div id="didyouknow" class="carousel slide pt-96r h-100" data-bs-ride="carousel">
                        
                        <div class="carousel-inner">
                            <div class="carousel-item" *ngFor="let dyk of cetacean!.didyouknows; let i = index" [ngClass]="{'active' : i==0 }">
                                <div class="text-center">
                                    <span class="typo_1 color-yellow-medium lh-zero">&ldquo;&nbsp;</span>
                                    <span class="typo_2 color-blue-medium lh-extended">{{ dyk.description }}</span>
                                    <span class="typo_1 color-yellow-medium lh-zero">&nbsp;&rdquo;</span>
                                </div>
                            </div>
                        </div>

                        <div class="carousel-indicators pb-64r">
                            <button data-bs-target="#didyouknow" [attr.data-bs-slide-to]="i" class="point" *ngFor="let dyk of cetacean!.didyouknows; let i = index" [ngClass]="{'active' : i==0 }"></button>
                        </div>
                    </div>
                    
                    <separator heightRem="8"></separator>
                    <separator-color></separator-color>
                </div>

            </div>
        </div>
    </div>
    
    <!-- Cetacean Behaviors Part 2 -->
    <app-bg-adaptable *ngIf="organizerCetaBehav_part2!.elements.length > 0" bgColor="bg-blue-light" [urlImage]="cetacean!.backgroundImage[1] ? backendUrl + cetacean!.backgroundImage[1].url : undefined">
        <separator></separator>
        <app-organizer [organizer]="organizerCetaBehav_part2"></app-organizer>
        <separator heightRem="8"></separator>
        <separator-color></separator-color>
    </app-bg-adaptable>
    
    <!-- Cetacean Quizz -->
    <app-quiz [quizzes]="cetacean!.quizzes"></app-quiz>
    <separator-color></separator-color>

    <!-- Cetacean Behaviors Part 3 -->
    <app-bg-adaptable *ngIf="organizerCetaBehav_part3!.elements.length > 0" bgColor="bg-blue-light" [urlImage]="cetacean!.backgroundImage[2] ? backendUrl + cetacean!.backgroundImage[2].url : undefined">
        <separator></separator>
        <app-organizer [organizer]="organizerCetaBehav_part3"></app-organizer>
        <separator heightRem="8"></separator>
        <separator-color></separator-color>
    </app-bg-adaptable>

    <!-- Cetacean Caroussel -->
    <app-bg-adaptable bgColor="bg-blue-dark">
        <separator id="gal"></separator>
        <app-title title="Quelques photos" [useThemeDark]="true"></app-title>
        <app-carousel [gallery]="cetacean!.galleryImage"></app-carousel>
        <separator heightRem="8"></separator>
    </app-bg-adaptable>

</ng-container>

