
<div class="container">
    <div class="row mb-96r">
        <div class="col-4 offset-4 d-flex flex-column align-items-center useTopSpace">
                        
            <div class="typo_1">
                Authentification
            </div>
            <img class="mt-8r mb-32r" src="../../../assets/images/Separator_Multi.svg"/>

            
            <input type="email" class="mb-5" placeholder="Nom d'utilisateur" #userName required />
            <input type="password" class="mb-5" placeholder="Mot de passe" #userPassword required />

            <div *ngIf="connexionError" class="mb-5 typo_4 color-error">Le nom d'utilisateur ou le mot de passe est incorrect.</div>

            <btncta (click)="onAuthenticationSubmit(userName.value, userPassword.value)">Connexion</btncta>

        </div>
    </div>
</div>
