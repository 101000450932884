
<div class="container-fluid bg-blue-dark">

    <!-- PART 1 --> 
    <app-title id="geo" title="Géographie des Antilles françaises" [useTopSpace]="true" [useThemeDark]="true"></app-title>
    <app-content-double verticalAlign="center">
        <div content-left>
            <div class="typo_3 color-turquoise-medium">
                Les Antilles françaises ou "French West Indies" ou "FWI" sont situées à 7000 km au Sud-Ouest de la métropole.
            </div>
        </div>
        <div content-right>
            <img class="img-fluid" src="assets/images/antilles/Antille_part1.png" />
        </div>
    </app-content-double>

</div>

<!-- PART 2 -->
<app-bg-adaptable urlImage="assets/images/antilles/Antille_part2.png" bgColor="bg-blue-dark" minHeight="480px">

    <app-content-single-center verticalAlign="center">

        <div content-center>
            
            <app-subtitle-txt [bgDisableInLargeScreen]="true" bgColor="bg-blue-dark">
                <div content class="typo_3 color-turquoise-medium">
                    Il y a plus ou moins 8 heures d'avion<br>entre la métropole et les Antilles, moins de 2 mois<br>de navigation en voilier poussé par les alizés,<br>et très très longtemps à la nage...<br>
                    Le décalage horaire est de 5h l'hiver et 6h l'été.
                </div>
            </app-subtitle-txt>

        </div>
    </app-content-single-center>

</app-bg-adaptable>

<!-- PART 3 -->
<app-bg-adaptable urlImage="assets/images/antilles/Antille_part3.jpg" bgColor="bg-blue-dark" minHeight="761px">

    <app-content-double verticalAlign="center">

        <div content-right>
            
            <app-subtitle-txt [bgDisableInLargeScreen]="true" bgColor="bg-blue-light">
                <div content class="typo_3 color-blue-dark">
                    Elles comprennent plusieurs îles : Saint-Martin<br>et Saint-Barthélemy, l'Archipel guadeloupéen constitué<br>de la Guadeloupe, les Saintes, Marie-Galante<br>et la Désirade et enfin plus au sud la Martinique.<br><br>Elles sont alignées Nord-Sud avec d'autres îles<br>des Caraïbes.
                </div>
            </app-subtitle-txt>

        </div>
    </app-content-double>

</app-bg-adaptable>

<!-- PART 4 -->
<app-bg-adaptable urlImage="assets/images/antilles/Antille_part4.jpg" bgColor="bg-blue-dark" minHeight="825px">

    <img class="small-cloud" src="assets/images/antilles/Nuage_Flottant1.png">
    <img class="big-cloud" src="assets/images/antilles/Nuage_Flottant2.png">
    
    <app-content-single-center verticalAlign="center">
    
        <div content-center>
            
            <app-subtitle-txt [bgDisableInLargeScreen]="true" bgColor="bg-blue-dark">
                <div content class="typo_3 color-blue-light">
                    Aux Antilles, on se baigne dans l'océan Atlantique quand on regarde du côté de la métropole<br>et dans la mer des Caraïbes quand on regarde<br>vers l'Amérique.
                </div>
            </app-subtitle-txt>

        </div>
    </app-content-single-center>

</app-bg-adaptable>

<!-- Sous-menu -->
<app-submenu [links]="links"></app-submenu>
<separator id="his"></separator>

<div class="container-fluid">
    <app-title title="Les Caraïbes"></app-title>
</div>

<app-bg-adaptable urlImage="assets/images/antilles/Petroglyphe.png">

    <app-content-double>
        <div content-left>

            <app-subtitle-txt>
                <div title>Population</div>
                <div content>
                    Répartie sur plusieurs îles dont la superficie totale est de 2&nbsp;835 km²,<br>la population totale des Antilles françaises est supérieure à 800&nbsp;000 habitants.<br><br>La langue parlée est le français et le créole de l'île. 
                </div>
            </app-subtitle-txt>

        </div>
        <div content-right>

            <app-subtitle-txt>
                <div title>Diversité culturelle</div>
                <div content>
                    Les Antilles françaises possèdent une diversité culturelle très riche<br>et propre aussi à chaque île.<br><br>
                    De nombreux ouvrages permettent d'aborder ces richesses, présentant<br>les variétés culinaires, musicales, traditionnelles telles que le Carnaval<br>ou le Chanté Nowel...<br><br>
                    Ce brassage culturel met en avant les différentes cultures présentes sur<br>ces îles qui sont un mélange de 4 continents (Amérique, Afrique, Asie, Europe).
                </div>
            </app-subtitle-txt>

        </div>
    </app-content-double>


    <app-content-single-center>
        <div content-center>

            <app-subtitle-txt>
                <div title>Une histoire riche et violente</div>
                <div content>
                    Les implantations successives des populations font que les Antilles<br>ont une histoire unique et souvent violente.<br><br>
                    Les peuples d'Amérindiens, venant donc d'Amérique, dont les Arawaks,<br>vivaient surtout de l'agriculture, de la cueillette et de la pêche. 
                </div>
            </app-subtitle-txt>

        </div>
    </app-content-single-center>


    <app-content-double>
        <div content-left>

            <app-subtitle-txt>
                <div title>De l'esclavage aux départements français</div>
                <div content>
                    Fin du XVe siècle, l'arrivée de Christophe Colomb dans les Caraïbes<br>puis des flibustiers au XVIIe siècle ont permis aux Français d'installer des comptoirs et des colonies.<br>
                    Les Amérindiens sont progressivement décimés par les colons<br>qui mettent alors en place l'esclavagisme.<br>Pendant près de quatre siècles, des esclaves sont déportés d'Afrique.<br>
                    Ce n'est qu'en 1848, que l'esclavage est finalement aboli.<br><br>
                    En 1946, la Martinique, la Guadeloupe, Saint-Martin et Saint-Barthélemy deviennent des départements français.
                </div>
            </app-subtitle-txt>
            
        </div>
        <div content-right>
            
            <app-subtitle-txt>
                <div title>Agriculture locale</div>
                <div content>
                    On peut observer de grands champs et des grandes plantations de canne à sucre<br>qui donneront du rhum et du sucre, mais aussi des cultures de bananiers,<br>de pastèques ou de melons, d'ignames et de patates douces,<br>de papayers, d'ananas...
                </div>
            </app-subtitle-txt>

        </div>
    </app-content-double>
    
    <separator></separator>

</app-bg-adaptable>

<separator-color></separator-color>

<div class="container-fluid">

    <separator id="nat"></separator>
    <app-title title="Faune et flore tropicale"></app-title>
    
    <div class="container">
        <div class="row">
            <div class="col typo_3">
                Les paysages sont très variés, on peut observer une végétation sèche avec des petits arbres,<br>une végétation totalement luxuriante dont les arbres emportent leur cime très haut vers le ciel<br>ou une mangrove mi-terre mi-mer avec des palétuviers dignes de la forêt du Seigneur des Anneaux.<br><br>
                Impossible de lister ici la totalité de cette flore, parcourue de fleurs magnifiques et odorantes.<br>
                Une bibliographie importante a été rédigée sur le sujet.<br><br>
                Quant à la faune, elle est riche et variée, parfois endémique, c'est à dire : qui n'existe que dans une région précise.
            </div>
        </div>
    </div>
    
    <separator heightRem="8"></separator>
</div>

<app-bg-adaptable urlImage="assets/images/antilles/Mangue_Bananier_Bg.png" bgColor="bg-yellow-medium">

    <app-content-double verticalAlign="center">
        <div content-left>
            
            <img class="img-fluid" src="assets/images/antilles/Mangue_Bananier.png" />

        </div>
        <div content-right>
            
            <separator></separator>

            <app-subtitle-txt bgColor="bg-yellow-medium">
                <div title>Quelques éléments de la flore</div>
                <div content>
                    Le balisier enchante l'oeil et apporte de l'eau aux insectes,<br>aux araignées ou aux oiseaux.<br><br>
                    Le bananier est une grande plante qui peut fournir des bananes sucrées<br>ou des bananes légumes.<br><br>
                    La mangue est l'un des fruits les plus consommés au monde !<br>Elle peut être mangée verte, cuite en légume ou orange en fruit. 
                </div>
            </app-subtitle-txt>

            <separator heightRem="8"></separator>

        </div>
    </app-content-double>

</app-bg-adaptable>

<app-bg-adaptable>

    <app-content-double verticalAlign="center">
        <div content-left>
            
            <separator></separator>

            <app-subtitle-txt>
                <div title>Quelques oiseaux</div>
                <div content>
                    Voici quelques personnages de cette faune tropicale :<br><br>La Frégate mâle, grand oiseau noir avec une poche rouge et gonflée au niveau de cou pendant la période nuptiale.<br><br>
                    Le Pélican brun qui plonge en mer tête la première dans les bancs<br>de petits poissons.<br><br>
                    Le colibri, magnifique oiseau minuscule battant très rapidement des ailes<br>de fleur en fleur. 
                </div>
            </app-subtitle-txt>
    
            <separator heightRem="8"></separator>

        </div>
        <div content-right>
            <img class="img-fluid" src="assets/images/antilles/Colibri.png" />
        </div>
    </app-content-double>

</app-bg-adaptable>

<app-bg-adaptable bgColor="bg-blue-dark">

    <app-content-double verticalAlign="center">
        <div content-left>
            
            <separator></separator>

            <app-subtitle-txt bgColor="bg-blue-dark">
                <div title class="color-blue-light">Emblématique</div>
                <div content class="color-blue-light">
                    La petite grenouillle qui chante fort à la tombée de la nuit.<br><br>
                    Endémique rime avec Pic comme le Pic de Guadeloupe,<br>oiseau qui creuse son nid dans les troncs d'arbres morts. 
                </div>
            </app-subtitle-txt>
            
            <separator heightRem="8"></separator>

        </div>
        <div content-right>
            
            <img class="img-fluid" src="assets/images/antilles/Notes_musique.jpg" />

        </div>
    </app-content-double>

</app-bg-adaptable>

<app-bg-adaptable bgColor="bg-blue-medium">

    <app-content-double verticalAlign="center">
        <div content-left>
            
            <img class="img-fluid" src="assets/images/antilles/Personnages_SousMarins.png" />

        </div>
        <div content-right>

            <separator></separator>

            <app-subtitle-txt bgColor="bg-blue-medium">
                <div title class="color-blue-light">Quelques personnages sous-marins</div>
                <div content class="color-blue-light">
                    Le Poisson perroquet broute les algues sur les coraux et produit,<br>selon des chercheurs, jusqu'à 100 kilogrammes de sable par an.<br>
                    Au début de sa vie, c'est une femelle puis il devient mâle.<br><br>
                    Le corail est un animal qui vit fixé sur les rochers.<br><br>
                    Le Poisson lion possède des épines venimeuses.<br>
                    Il a été introduit récemment de façon accidentelle dans les Caraïbes.<br><br>
                    Sur 1000 oeufs déposés sur une plage par une tortue marine,<br>un seul atteindra l'âge de la reproduction.<br><br>
                    Le requin est aussi appelé le nettoyeur de la mer. 
                </div>
            </app-subtitle-txt>
            
            <separator heightRem="8"></separator>

        </div>
    </app-content-double>

</app-bg-adaptable>

<separator-color></separator-color>

<div class="container-fluid">

    <separator></separator>
    <app-title title="Le climat particulier des Antilles"></app-title>
    
    <div class="container">
        <div class="row">
            <div class="col typo_3">
                Le climat est de type maritime subtropical.<br>
                Les écarts de température entre l'été et l'hiver sont faibles.<br>
                Les précipitations sous forme d'averses sont importantes, notamment en montagne.<br>
                La saison sèche est en hiver tandis que la saison humide est en été.<br><br>
                Le doux vent des alizés adoucit la sensation de chaleur.<br>
                La température de la mer varie entre 26°C et 29°C.<br>
                Les rivières sont plus fraîches sauf quand on tombe sur une source chaude, réchauffée par la proximité du volcan.
            </div>
        </div>
    </div>
    
    <separator heightRem="8"></separator>
</div>

<separator-color></separator-color>

<app-bg-adaptable urlImage="assets/images/antilles/Ouragan_FullScreenLeft_Crop.png" bgPosition="bgLeft">

    <separator></separator>
    <app-title title="Les forces de la nature"></app-title>

    <app-content-double verticalAlign="center">

        <div content-right>

            <app-subtitle-txt [bgDisableInLargeScreen]="true" bgColor="bg-blue-light">
                <div title>Les ouragans</div>
                <div content>
                    Aussi appelés cyclones, ils peuvent survenir entre juillet et fin novembre.<br>
                    Il y a régullièrement des ondes tropicales qui amènent beaucoup de pluie pendant 24 à 48h.<br>
                    Puis si le vent augmente, cela peut devenir une tempête tropicale<br>à laquelle on attribuera un nom,<br>
                    puis devenir un cyclone, classé de 1 à 5, le plus dangereux étant<br>le plus grand chiffre.<br>
                    Des communiqués sont publiés régulièrement<br>pendant cette période particulière.
                </div>
            </app-subtitle-txt>
            
            <separator heightRem="8"></separator>

        </div>
    </app-content-double>

</app-bg-adaptable>

<app-bg-adaptable urlImage="assets/images/antilles/Volcan_Stylized.png" bgColor="bg-blue-dark" bgPosition="bgRight">

    <app-content-double verticalAlign="center">

        <div content-left>
            
            <separator></separator>

            <app-subtitle-txt [bgDisableInLargeScreen]="true" bgColor="bg-blue-dark">
                <div title class="color-blue-light">Les volcans</div>
                <div content class="color-blue-light">
                    Dans les Petites Antilles, les plaques tectoniques américaines,<br>soit le fond de l'océan Atlantique, plongent sous la plaque caraïbe<br>(environ 2 cm tous les ans), ce qui a entrainé la formation de volcans.<br><br>
                    Il y a une dizaine de volcans actifs dans l'arc des Petites Antilles<br>dont 2 dans les Antilles françaises : un en Guadeloupe appelé la Soufrière<br>dont la dernière grande éruption remonte à 1976 et un volcan en Martinique appelé la Montagne Pelée tristement célèbre pour sa terrible éruption de 1902 (29 000 victimes).<br><br>
                    Leur activité est évidemment très surveillée.
                </div>
            </app-subtitle-txt>
            
            <separator heightRem="8"></separator>

        </div>
    </app-content-double>

</app-bg-adaptable>

<app-bg-adaptable urlImage="assets/images/antilles/Seisme.png">

    <app-content-double verticalAlign="center">

        <div content-right>
            
            <separator></separator>

            <app-subtitle-txt [bgDisableInLargeScreen]="true" bgColor="bg-brown-medium">
                <div title class="color-blue-light">Les tremblements de terre</div>
                <div content class="color-blue-light">
                    Appelés aussi séismes.<br><br>Les plaques tectoniques glissent par à-coups, libérant une forte quantité d'énergie entrainant un tremblement de terre plus ou moins important,<br>dont la force est calculée selon la magnitude et l'intensité.<br><br>Ils sont classés selon l'échelle de Richter de 1 à 7.<br><br>
                    Des instruments appelés sismographes enregistrent plusieurs séismes par jour dans les Petites Antilles mais ils sont souvent trop faibles pour être ressentis. 
                </div>
            </app-subtitle-txt>
            
            <separator heightRem="8"></separator>

        </div>
    </app-content-double>

</app-bg-adaptable>

<app-bg-adaptable urlImage="assets/images/antilles/Tsunamie_Stylized2.png" bgColor="bg-blue-medium" bgPosition="bgRight">

    <app-content-double verticalAlign="center">

        <div content-left>
            
            <separator></separator>
            
            <app-subtitle-txt [bgDisableInLargeScreen]="true" bgColor="bg-blue-medium">
                <div title class="color-blue-light">Les tsunamis</div>
                <div content class="color-blue-light">
                    Appelés aussi raz-de-marée.<br><br>
                    Une vague plus ou moins haute se déverse avec violence sur la côte.<br>
                    Ce phénomène heureusement très rare est provoqué<br>lors d'une éruption volcanique, un effondrement brutal d'un pan du volcan<br>ou un séisme sous-marin.<br><br>
                    Les signes annonciateurs sont le recul ou la baisse du niveau de la mer, annonçant l'arrivée d'une onde pouvant être dangereuse.<br>
                    Il faut alors s'éloigner de la côte. 
                </div>
            </app-subtitle-txt>
            
            <separator heightRem="8"></separator>

        </div>
    </app-content-double>

</app-bg-adaptable>

<separator-color></separator-color>

<div class="container-fluid">

    <separator id="dan"></separator>
    <app-title title="Richesses en péril ... Quelques responsables :"></app-title>
    
    <app-content-double verticalAlign="center">
    
        <div content-left>
            
            <separator></separator>
    
            <app-subtitle-txt>
                <div title>Pollution au chlordécone</div>
                <div content>
                    Cet insecticide a été utilisé dans les Antilles françaises entre 1972 et 1993 pour lutter contre le charançon du bananier.<br>
                    Il était pourtant déjà interdit dès 1976 aux États-Unis et interdit de vente<br>en France en 1990.<br>
                    La contamination s'est faite dans le sol, le littoral, les animaux, les rivières...<br>et chez les Humains.<br><br>
                    Cette molécule mettrait 7 siècles à disparaître de l'environnement.<br>
                    La dangerosité sur la santé a été reconnue.<br><br>
                    Catastrophe en cours...
                </div>
            </app-subtitle-txt>
    
            <separator heightRem="8"></separator>
    
        </div>
        
        <div content-right>
                
            <img class="img-fluid" src="assets/images/antilles/Rejet_Dechets.png" />
    
        </div>
    </app-content-double>
    
    <app-content-double>
        <div content-left>
            
            <img class="img-fluid" src="assets/images/antilles/Peche_Filet_Web.png" />
    
        </div>
        
        <div content-right>
            
            <app-subtitle-txt>
                <div title>La pêche</div>
                <div content>
                    La surpêche de certaines espèces entraine des perturbations<br>de la chaine alimentaire et de l'écosystème.<br><br>
                    Par exemple : les poissons brouteurs d'algues ne sont plus assez nombreux pour jouer leur rôle de nettoyeur de corail.<br>
                    A cause de la pollution et de la pêche, 80% des récifs coralliens de la Caraïbe ont disparu.<br>
                    Les techniques de pêche non ciblées comme les nasses ou les filets entraînent des captures accidentelles d'espèces<br>qui ne seront pas consommées.
                </div>
            </app-subtitle-txt>
    
        </div>
    </app-content-double>
    
    <separator heightRem="8"></separator>
</div>

<app-bg-adaptable urlImage="assets/images/antilles/Fond_Dechets_FullScreen.png">

    <div style="min-height: 608px;">
        <app-content-double>
            <div content-left>
        
                <app-subtitle-txt>
                    <div title>Sargasses</div>
                    <div content>
                        Ce sont des algues flottantes qui peuvent se multiplier en pleine mer.<br>
                        Quand elles arrivent sur les côtes poussées par les courants, elles s'échouent<br>et leur décomposition engendre des problèmes de santé.<br><br>
                        L'écosystème sous-marin est impacté, de nombreux poissons morts<br>sont alors observés, les tortues ont des difficultés pour aller pondre,<br>les coraux souffrent du manque de lumière et d'oxygène dans l'eau. 
                    </div>
                </app-subtitle-txt>
        
            </div>

            <div content-right>
                
                <app-subtitle-txt>
                    <div title>La pollution vient de la terre</div>
                    <div content>
                        Le traitement des eaux usées est très insuffisant, les stations de traitement des eaux ne sont pas toujours efficaces.<br><br>
                        Les détergents et les produits chimiques terminent en mer<br>et ont un impact néfaste sur le milieu marin.
                    </div>
                </app-subtitle-txt>
        
            </div>
        </app-content-double>
    </div>

</app-bg-adaptable>

<separator-color></separator-color>

<div class="container-fluid bg-yellow-medium">
    
    <separator id="eff"></separator>
    <app-title title="Équilibre entre Ciel, Terre et mer"></app-title>

    <app-content-double verticalAlign="center">

        <div content-left>

            <app-subtitle-txt [bgDisableInLargeScreen]="true">
                <div title>Effet papillon écologique.</div>
                <div content>
                    Chaque déséquilibre dans un élément peut impacter un autre élément.<br><br>
                    <b>Un exemple :</b><br>Un parent achète un jouet avec des piles non rechargeables.<br>Dans la maison, il n'y a pas de boîte pour mettre les piles usagées.<br>
                    La pile finit dans la poubelle ménagère, elle sera ensuite stockée<br>dans une décharge et la pile s'abimant, le liquide libéré<br>se retrouve dans la terre.<br>
                    La pluie draine le sol et emporte les liquides vers la nappe phréatique<br>ou dans les rivières pour finir dans la mer.<br>
                    Les métaux lourds vont alors se fixer dans les animaux marins<br>et s'accumuleront tout au long de la chaine alimentaire pour se fixer<br>dans le gras des cétacés.<br>
                    Une femelle éliminera ce produit dangereux lors de l'allaitement.<br>Et alors le petit... Trouvez la fin. 
                </div>
            </app-subtitle-txt>

        </div>
        
        <div content-right>
                
            <img class="img-fluid" src="assets/images/antilles/EffetPapillon.png" />

        </div>
    </app-content-double>

    <separator heightRem="8"></separator>
</div>

<separator-color></separator-color>

<app-bg-adaptable bgColor="bg-blue-gradient">

    <separator id="sol"></separator>

    <!-- Partenaires Scient -->
    <div class="container">
        <div class="row title_1">
            <div class="col typo_1 color-blue-light">Quelques mesures conservatoires en cours...</div>
        </div>
        <img class="mt-8r mb-32r" src="assets/images/Separator_Multi.svg"/>
    </div>

    <app-organizer [organizer]="organizeConservation" bgColor="bg-blue-medium" color="color-blue-light" [bgDisableInLargeScreen]="true"></app-organizer>

    <separator heightRem="8"></separator>

</app-bg-adaptable>
