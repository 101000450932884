
<div class="btncta position-relative">
    
    <img class="position-absolute top-0 start-0 h-100" src="assets/images/btncta_left.svg" />
    <img class="position-absolute top-0 end-0 h-100" src="assets/images/btncta_right.svg" />

    <div class="container g-0">
        <div class="row g-0">
            <div class="col whaleBtnLeft"></div>
            <div class="col whaleBtnRight"></div>
        </div>

        <div class="row g-0">
            <div class="col whaleBtnBottom typo_8 text-white text-uppercase d-flex align-items-center">
                <div class="position-relative text-center text">
                    <ng-content></ng-content>
                </div>
            </div>
        </div>
    </div>

</div>