
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { User } from '@/models/user';

import { Settings } from '@/settings';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthenticationService
{
    private readonly LOCAL_STORAGE_CURRENT_USER : string = "currentUser";
    private readonly LOCAL_STORAGE_TOKEN : string = "token";

    // Accessors
    public get CurrentUserSubject(): BehaviorSubject<User>
    {
        return this.currentUserSubject;
    }
    public get currentUserValue(): User 
    {
        return this.currentUserSubject.value;
    }

    private currentUserSubject: BehaviorSubject<User>;

    constructor(private http: HttpClient)
    {
        let lStorage = localStorage.getItem(this.LOCAL_STORAGE_CURRENT_USER);
        if (lStorage != null)
        {
            this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(lStorage));
        }
        else
        {
            this.currentUserSubject = new BehaviorSubject<User>(new User());
        }
    }

    public login(username: string, password: string)
    {
        return this.http.post<any>(Settings.authenticationUrl, { "identifier": username, "password": password }).pipe(map(response =>
        {
            // Token reçu
            if (response && response.jwt)
            {
                let userResponse : User = response.user;

                localStorage.setItem(this.LOCAL_STORAGE_CURRENT_USER, JSON.stringify(userResponse));
                localStorage.setItem(this.LOCAL_STORAGE_TOKEN, response.jwt);

                this.currentUserSubject.next(userResponse);
            }
        }));
    }

    public logout()
    {
        localStorage.removeItem(this.LOCAL_STORAGE_CURRENT_USER);
        localStorage.removeItem(this.LOCAL_STORAGE_TOKEN);
        
        this.currentUserSubject.next(new User());
    }
}