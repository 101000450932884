import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit
{
    introContent: string;

    constructor()
    {
        this.introContent = "JojoSousLeau est édité par Mon École, Ma Baleine, association loi 1901, Route Hégesippe Légégitimus, Beauport, 97 117 Port Louis, Guadeloupe.<br>" +
                            "Notre Application est une application pour PC, Mac, mobile et tablette (Android / IOS) nommée « JojoSousLeau ».<br>" +
                            "Nos règles de confidentialité expliquent que nous ne collectons aucune données tel qu’elles soient.";
                        
    }

    ngOnInit(): void
    {
    }
}
