
<div class="container-fluid bg-cloud">
    
    <app-title title="Joindre Mon École, Ma baleine" [useTopSpace]="true"></app-title>

    <div class="container">

        <div class="row">

            <div class="container mb-128r" >
                <div class="row">
                    <div class="col-12 col-lg-5 mb-5 mb-lg-0">

                        <!--
                        <form class="row" [formGroup]="contactUsForm">
                            <div class="mb-1 mb-lg-3">
                                <label for="exampleFormControlInput1" class="form-label typo_9_B color-blue-medium">Antenne</label>
                                <select formControlName="antennasSelect">
                                    <option *ngFor="let antenna of antennas" class="options" [ngValue]="antenna">{{ antenna.antenna }}</option>
                                </select>
                            </div>
                            <div class="mb-1 mb-lg-3">
                                <label class="form-label typo_9_B color-blue-medium">Objet</label>
                                <input [class.inputInvalid]="submittedForm && form.subject.errors" placeholder="Votre objet" formControlName="subject">

                                <div *ngIf="submittedForm && form.subject.errors">
                                    <div class="color-error" *ngIf="form.subject.errors.required">L'objet est obligatoire</div>
                                </div>
                            </div>
                            <div class="mb-1 mb-lg-3">
                                <label class="form-label typo_9_B color-blue-medium">Nom</label>
                                <input [class.inputInvalid]="submittedForm && form.name.errors" placeholder="Votre nom" formControlName="name">
                                
                                <div *ngIf="submittedForm && form.name.errors">
                                    <div class="color-error" *ngIf="form.name.errors.required">Le nom est obligatoire</div>
                                </div>
                            </div>
                            <div class="mb-4 mb-lg-5">
                                <label for="exampleFormControlInput1" class="form-label typo_9_B color-blue-medium">Téléphone <span class="typo_10_C">(facultatif)</span></label>
                                <input id="exampleFormControlInput1" placeholder="__ __ __ __ __" formControlName="phone">
                            </div>
                            <div class="d-flex flex-column align-items-center mb-4 mb-lg-5">
                                <btncta (click)="onContactUsSubmit()">Contacter</btncta>
                            </div>
                            <div class="">
                                Vous pouvez sinon nous contacter via l'adresse : <i>contact.monecolemabaleine@gmail.com</i>
                            </div>
                        </form>
                        -->
                        <div class="d-flex flex-column justify-content-center align-items-center h-100 typo_3">
                            <div>Pour nous contacter :</div>
                            <div><i>contact.monecolemabaleine@gmail.com</i></div>
                        </div>

                    </div>

                    <div class="col-12 col-lg-6 offset-lg-1 typo_3">
                        <div>Vous pouvez nous contacter pour :</div>
                        <ul>
                            <li>avoir une intervention,</li>
                            <li>demander des outils pédagogiques pour vous aider dans votre projet pédagogique,</li>
                            <li>emprunter des outils,</li>
                            <li>avoir de l'aide pour monter vos projets,</li>
                            <li>vous n'habitez pas dans les Antilles et vous voulez des conseils à distance,</li>
                            <li>vous n'êtes pas aux Antilles, vous souhaitez créer un jumelage avec une classe des Antilles françaises,</li>
                            <li>vous avez des questions sur les cétacés ou vous souhaitez rejoindre l'équipe.</li>
                        </ul>
                        <div>...Et plein d'autres raisons de nous contacter !</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>