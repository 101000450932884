import { Quiz } from '@/apollo/queries/quiz/quiz';
import { FIND_STORIES, Stories, Story } from '@/apollo/queries/stories/stories';
import { Link } from '@/models/link';
import { Route } from '@/models/route';
import { Settings } from '@/settings';
import { Component, OnInit } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-stories',
  templateUrl: './stories.component.html',
  styleUrls: ['./stories.component.scss']
})
export class StoriesComponent implements OnInit
{
    introContent: string;
    quizzes: Quiz[];
    links : Array<Link>;

    backendUrl: string = Settings.backEndUrl;
    
    stories: Story[] | undefined;

    watchStoriesSubscription : Subscription | undefined;

    constructor(private apollo: Apollo)
    {
        this.introContent = "\"Mon École, Ma baleine\" te propose deux histoires originales sous forme de BD.<br>" + 
            "Nos personnages vont devoir éviter les dangers et les pièges qui rôdent autour d'eux.<br><br>" +
            "Retrouve Léa, Matthieu, Fanny, Adam, Jojo et bien d'autres.<br><br>" +
            "Et bien sûr, les versions en ligne sont gratuites. Sers-toi et profite bien.";

        this.quizzes =
        [
            { question: "Combien le cerveau du cachalot pèse t-il ?", answer1: "3 kilos", answer2: "8 kilos", answer3: "12 kilos", answer4: "17 kilos", resultGood: "Il pèse 8 kilogrammes", resultBad: "Le cerveau de Cachalot est le plus gros de tous les êtres vivants. A titre de comparaison, le cerveau de l'être humain pèse 1,4 kg en moyenne.", answerCorrectId: 1 },
        ];

        this.links = [
            { url: Route.Stories + "#his", name: "Nos histoires" },
            { url: Route.Stories + "#imp", name: "Versions imprimées" },
        ];
    }

    ngOnInit(): void
    {
        // Stories
        this.watchStoriesSubscription = this.apollo.watchQuery<Stories>({
            query: FIND_STORIES
        })
        .valueChanges.subscribe(result => 
        {
            this.stories = result.data.stories;
        });
    }
    
    onOpenShop()
    {
        window.open("https://maboutiquemabaleine.com", "_blank");
    }
}
