import { GetNewAndNeighbourResponse, GET_NEW_AND_NEIGHBOUR, New, NewResponse } from '@/apollo/queries/news/news';
import { Settings } from '@/settings';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-new',
  templateUrl: './new.component.html',
  styleUrls: ['./new.component.scss']
})
export class NewComponent implements OnInit
{
    backendUrl: string = Settings.backEndUrl;

    previousNew : New | null = null;
    currentNew : New | null = null;
    nextNew : New | null = null;

    watchNewSubscription : Subscription | undefined;
    
    constructor(private apollo: Apollo, private route: ActivatedRoute)
    {
    }

    ngOnInit(): void
    {
        this.loadNew(this.route.snapshot.params['id']);
    }

    loadNew(newId: string)
    {
        // New
        this.watchNewSubscription = this.apollo.query<GetNewAndNeighbourResponse>({
            query: GET_NEW_AND_NEIGHBOUR,
            variables: {
                newId: newId,
            }
        })
        .subscribe(result => 
        {
            this.previousNew = result.data.getNewAndNeighbour[0];
            this.currentNew = result.data.getNewAndNeighbour[1];
            this.nextNew = result.data.getNewAndNeighbour[2];
        });
    }

    onGotoNews(newId: string)
    {
        this.loadNew(newId);
    }
}
