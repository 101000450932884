import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-type-d',
  templateUrl: './card-type-d.component.html',
  styleUrls: ['./card-type-d.component.scss']
})
export class CardTypeDComponent
{
    @Input() name: string = '';
    @Input() subname: string = '';
    
    @Input() urlImageStandard: string = '';
    @Input() urlImageHover: string = '';
}
