

import gql from "graphql-tag";

export interface NewletterReceiver
{
    id: string;
    email: string;
}

export interface NewletterReceivers
{
    newletterReceivers: NewletterReceiver[];
}

export interface NewletterReceiverInput
{
    email: string;
}

// CREATE
export interface createNewletterReceiverInput
{
    data : NewletterReceiverInput;
}

export interface createNewletterReceiverPayload
{
    newletterReceiver: NewletterReceiver;
}

export interface createNewletterReceiverResponse
{
    createNewletterReceiver : createNewletterReceiverPayload;
}

//DELETE
export interface deleteNewletterReceiverInput
{
    where: number;
}

export interface deleteNewletterReceiverPayload
{
    newletterReceiver: NewletterReceiver;
}

export interface deleteNewletterReceiverResponse
{
    deleteNewletterReceiver : deleteNewletterReceiverPayload;
}

export const FIND_NEWLETTER_RECEIVERS = gql`
query NewletterReceivers
{
    newletterReceivers
    {
        id
        email
    }
}`;

export const CREATE_NEWLETTER_RECEIVER = gql`
mutation CreateNewletterReceiver($input: createNewletterReceiverInput)
{
    createNewletterReceiver(input: $input)
    {
        newletterReceiver
        {
            email
        }
    }
}`;