
export enum Route
{
    Base = '/',

    Home = "Accueil",
    MembAdmin = "Memb",
    MembAdminNewletter = "MembNewsletter",

    GeneralAntilles = "GeneralitesAntilles",
    GeneralCetaceans = "GeneralitesCetace",
    Cetaceans = "ZoomCetaces",
    Cetacean = "Cetace/:id",
    Videos = "Videos",

    Tutos = "Tutoriels",
    OurTools = "NosOutils",
    Stories = "Histoires",
    PaperActivities = "ActivitesPapier",
    JojoSousLeau = "JojoSousLeau",
    OurGames = "JeuxInteractifs",
    Association = "Association",
    
    News = "Actu",
    New = "Actu/:id",
    HelpUs = "AidezNous",
    Privacy = "Confidentialite",

    ContactUs = "ContactezNous",
    Newsletter = "Newsletter",
}