import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { Route } from '@/models/route';

import { HomeComponent } from './pages/home/home.component';
import { OurToolsComponent } from './pages/our-tools/our-tools.component';
import { AssociationComponent } from './pages/association/association.component';
import { NewsComponent } from './pages/news/news.component';
import { HelpUsComponent } from './pages/help-us/help-us.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { NewsletterComponent } from './pages/newsletter/newsletter.component';
import { JojosousleauComponent } from './pages/jojosousleau/jojosousleau.component';
import { StoriesComponent } from './pages/stories/stories.component';
import { PaperActivitiesComponent } from './pages/paper-activities/paper-activities.component';
import { OurGamesComponent } from './pages/our-games/our-games.component';
import { GeneralAntillesComponent } from './pages/general-antilles/general-antilles.component';
import { GeneralCetaceansComponent } from './pages/general-cetaceans/general-cetaceans.component';
import { CetaceanComponent } from './pages/cetacean/cetacean.component';
import { NewComponent } from './pages/new/new.component';
import { CetaceansComponent } from './pages/cetaceans/cetaceans.component';
import { MembadminComponent } from './pages/membadmin/membadmin.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { MembadminNewsletterComponent } from './pages/membadmin-newsletter/membadmin-newsletter.component';
import { MenuGuard } from './guards/menu.guards';
import { VideosComponent } from './pages/videos/videos.component';
import { TutosComponent } from './pages/tutos/tutos.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';

const routes: Routes = [
    { path: '' , redirectTo: Route.Home, pathMatch: 'full' },

    { path: Route.Home, component: HomeComponent },
    { path: Route.MembAdmin, component: MembadminComponent },
    { path: Route.MembAdminNewletter, component: MembadminNewsletterComponent, canActivate: [MenuGuard] },
    
    { path: Route.GeneralAntilles, component: GeneralAntillesComponent },
    { path: Route.GeneralCetaceans, component: GeneralCetaceansComponent },
    { path: Route.Cetaceans, component: CetaceansComponent },
    { path: Route.Cetacean, component: CetaceanComponent },
    { path: Route.Videos, component: VideosComponent },

    { path: Route.Tutos, component: TutosComponent },
    { path: Route.OurTools, component: OurToolsComponent },
    { path: Route.Stories, component: StoriesComponent },
    { path: Route.PaperActivities, component: PaperActivitiesComponent },
    { path: Route.JojoSousLeau, component: JojosousleauComponent },
    { path: Route.OurGames, component: OurGamesComponent },
    { path: Route.Association, component: AssociationComponent },

    { path: Route.News, component: NewsComponent },
    { path: Route.New, component: NewComponent },
    { path: Route.HelpUs, component: HelpUsComponent },
    { path: Route.Privacy, component: PrivacyComponent },

    { path: Route.ContactUs, component: ContactUsComponent },
    { path: Route.Newsletter, component: NewsletterComponent },
    
    { path: '**', component: PageNotFoundComponent, pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
