import { Specificity } from '@/apollo/queries/cetaceans/cetaceans';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-specificities',
  templateUrl: './specificities.component.html',
  styleUrls: ['./specificities.component.scss']
})
export class SpecificitiesComponent implements OnInit
{
    randomNumber: number[];

    @Input() specificities: Specificity[] | undefined;
    
    constructor()
    {
        this.randomNumber = [];
    }

    ngOnInit(): void
    {
        if (this.specificities)
        {
            for(let i=0; i<this.specificities!.length; i++)
            {
                this.randomNumber[i] = this.randomIn6Col(i);
            }
        }
    }

    randomIn6Col(i: number): number
    {
        let random: number = Math.floor(Math.random() * 2);
        if (i % 2 != 0)
        {
            random += 4;
        }
        return random;
    }
}
