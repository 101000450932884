import { Component, OnInit } from '@angular/core';

export interface QandA
{
    question: string;
    answer: string;
    isOpen: boolean;
}

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit
{
    qandaList: QandA[] = [
        {
            isOpen: false, question: "En tant que bénévole, puis-je partir en mer avec l'association ?", answer: "Non, l'association n'a pas de bateau."
        },
        {
            isOpen: false, question: "Puis-je proposer mes créations comme adhérant ?", answer: "Bien sûr. Nous serions ravîs de voir vos créations. Afin de les inclures dans nos outils pédagogiques, nos spécialites devront au préalable validé la pertinence et justesse des informations de vos créations."
        },
        {
            isOpen: false, question: "Ai-je droit à certains avantages en fonctions du montant du don ?", answer: "Ce n'est que de la générosité pour aider l'association, ..."
        },
        {
            isOpen: false, question: "En tant que bénévole, doit-je faire partie des adhérents pour aider sur les stands ?", answer: "Oui, l'adhésion est obligatoire, c'est une question d'assurance."
        },
        {
            isOpen: false, question: "Quel intérêt ai-je à devenir adhérent ?", answer: "Obtenir des réductions sur la boutique de Mon École, Ma Baleine et des réductions avec certains prestataires partenaires."
        },
        {
            isOpen: false, question: "Je suis enfant, puis-je devenir adhérent ?", answer: "C'est une bonne initiative de ta part, il faudra l'autorisation de tes parents."
        },
        {
            isOpen: false, question: "Je suis enfant, puis-je être bénévole actif dans l'association ?", answer: "Ce n'est que de la générosité pour aider l'association, ..."
        },
        {
            isOpen: false, question: "Ai-je droit à certains avantages en fonctions du montant du don?", answer: "Oui avec plaisir, tu peux tester en avant-première les nouvelles activités en utilisant le mail de tes parents. En devenant adhérent tu peux choisir la façon de nous aider : Relecteur, musicien, dessinateur, etc."
        }
    ];

    constructor()
    {
    }

    ngOnInit(): void
    {
        this.qandaList[0].isOpen = true;
    }

    onAandQClick(idQandA: number)
    {
        for(let i=0; i<this.qandaList.length; i++)
        {
            this.qandaList[i].isOpen = false;
        }
        this.qandaList[idQandA].isOpen = true;
    }
}
