
<ng-container *ngIf="currentNew">
    <div class="container-fluid bg-blue-dark pt-128r">

        <div class="container">
            <div class="row align-items-center">
                <div class="col-4 col-lg-3 offset-lg-1 p-4">
                    <div *ngIf="previousNew" class="position-relative mask-img" (click)="onGotoNews(previousNew.id)">
                        <div class="position-absolute background-cover top-0 start-0 w-100 pt-100 prevnext-bubble" [ngStyle]="{'background-image': 'url(' + backendUrl + previousNew.primeImage.url + ')'}"></div>
                        <div class="position-absolute top-50 start-50 typo_7 color-blue-light text-center prevnext-text">{{previousNew.title}}</div>
                    </div>
                </div>
                <div class="col-4 col-lg-4">
                    <div class="position-relative mask-img">
                        <div class="position-absolute background-cover top-0 start-0 w-100 pt-100" [ngStyle]="{'background-image': 'url(' + backendUrl + currentNew.primeImage.url + ')'}"></div>
                    </div>
                    <svg class="position-absolute">
                        <clipPath id="svgmask" clipPathUnits="objectBoundingBox">
                            <path d="M0.194,0.254c0.163-0.208,0.545-0.166,0.639,0.04c0.124,0.275,0.018,0.462-0.118,0.538 C0.609,0.891,0.326,0.937,0.187,0.731C0.06,0.543,0.117,0.352,0.194,0.254z"></path>
                        </clipPath>
                    </svg>
                </div>
                <div class="col-4 col-lg-3 p-4">
                    <div *ngIf="nextNew" class="position-relative mask-img" (click)="onGotoNews(nextNew.id)">
                    <div class="position-absolute background-cover top-0 start-0 w-100 pt-100 prevnext-bubble" [ngStyle]="{'background-image': 'url(' + backendUrl + nextNew.primeImage.url + ')'}"></div>
                    <div class="position-absolute top-50 start-50 typo_7 color-blue-light text-center prevnext-text">{{nextNew.title}}</div>
                </div></div>
            </div>
    
            <div class="row">
                <div class="col text-center">
                    <div class="typo_1 color-blue-light text-center mb-16r">{{ currentNew.title }}</div>
                    <div class="typo_4 color-blue-medium text-center">{{ currentNew.creationDate | date : 'd MMMM YYYY'}}</div>
                    <img class="mt-8r mb-32r" src="assets/images/Separator_Multi.svg"/>
                </div>
            </div>
        </div>
        
    </div>

    
    <!-- Sections -->
    <ng-container *ngFor="let section of currentNew.newSection">
        <div class="container-fluid" [ngClass]="section.new_background_color && section.new_background_color.backgroundColor === 'Blue-Medium' ? 'bg-blue-gradient' : 'bg-blue-light'">

            <separator-color></separator-color>
            <separator heightRem="6"></separator>

            <!-- Rows -->
            <div class="container">
                <div class="row align-items-center" *ngFor="let row of section.NewRow; let last=last">

                    <!-- Avec Image : 2 colonnes - Texte à gauche -->
                    <ng-container *ngIf="(row.media || row.embedMedia) && (!row.new_align || row.new_align.alignement === 'Left')">
    
                        <div class="col-12 col-lg-6 d-flex flex-column align-items-center">
                            <div *ngIf="row.title" class="typo_2 mb-16r px-24r" [ngClass]="{'color-blue-light': section.new_background_color && section.new_background_color.backgroundColor === 'Blue-Medium'}">{{ row.title }}</div>
                            <div class="typo_4 px-24r pre-line" [ngClass]="{'color-blue-light': section.new_background_color && section.new_background_color.backgroundColor === 'Blue-Medium'}">{{ row.description }}</div>
                            <a *ngIf="row.linkName && row.linkUrl" class="standardButton typo_8 mt-32r" [href]="row.linkUrl">{{ row.linkName }}</a>
                        </div>
                
                        <div class="col-12 col-lg-6 d-flex flex-column align-items-center">
                            <img *ngIf="!row.embedMedia" class="img-fluid" [src]="backendUrl + row.media.url" />
                            <div *ngIf="row.embedMedia" class="iframe-parent"><iframe class="position-absolute top-0 start-0 w-100 h-100" [src]="row.embedMedia | safe"></iframe></div>
                        </div>
    
                    </ng-container>
    
                    <!-- Avec Image : 2 colonnes - Texte à droite -->
                    <ng-container *ngIf="(row.media || row.embedMedia) && row.new_align && row.new_align.alignement === 'Right'">
    
                        <div class="col-12 col-lg-6 d-flex flex-column align-items-center">
                            <img *ngIf="!row.embedMedia" class="img-fluid" [src]="backendUrl + row.media.url" />
                            <div *ngIf="row.embedMedia" class="iframe-parent"><iframe class="position-absolute top-0 start-0 w-100 h-100" [src]="row.embedMedia | safe"></iframe></div>
                        </div>
    
                        <div class="col-12 col-lg-6 d-flex flex-column align-items-center">
                            <div *ngIf="row.title" class="typo_2 mb-16r px-24r" [ngClass]="{'color-blue-light': section.new_background_color && section.new_background_color.backgroundColor === 'Blue-Medium'}">{{ row.title }}</div>
                            <div class="typo_4 px-24r pre-line" [ngClass]="{'color-blue-light': section.new_background_color && section.new_background_color.backgroundColor === 'Blue-Medium'}">{{ row.description }}</div>
                            <a *ngIf="row.linkName && row.linkUrl" class="standardButton typo_8 mt-32r" [href]="row.linkUrl">{{ row.linkName }}</a>
                        </div>
    
                    </ng-container>
    
                    <!-- Sans Image : 1 colonne -->
                    <ng-container *ngIf="!row.media && !row.embedMedia">
    
                        <div class="col-6 offset-3 text-center d-flex flex-column align-items-center">
                            <div *ngIf="row.title" class="typo_2 mb-16r" [ngClass]="{'color-blue-light': section.new_background_color && section.new_background_color.backgroundColor === 'Blue-Medium'}">{{ row.title }}</div>
                            <div class="typo_4 pre-line" [ngClass]="{'color-blue-light': section.new_background_color && section.new_background_color.backgroundColor === 'Blue-Medium'}">{{ row.description }}</div>
                            <a *ngIf="row.linkName && row.linkUrl" class="standardButton typo_8 mt-32r" [href]="row.linkUrl" target="_blank">{{ row.linkName }}</a>
                        </div>
    
                    </ng-container>
                    
                    <separator *ngIf="!last" heightRem="5"></separator>
                    
                </div>
            </div>
            
            <separator heightRem="8"></separator>
        
        </div>
    </ng-container>

    <!-- Gallerie d'images -->
    <app-bg-adaptable *ngIf="currentNew.gallery.length > 0" bgColor="bg-blue-dark">
        <separator></separator>
        <app-title title="Quelques photos" [useThemeDark]="true"></app-title>
        <app-carousel [gallery]="currentNew.gallery"></app-carousel>
        <separator heightRem="8"></separator>
    </app-bg-adaptable>
    
</ng-container>


