import { Cetacean, CetaceanResponse, FIND_CETACEAN } from '@/apollo/queries/cetaceans/cetaceans';
import { Link } from '@/models/link';
import { Organizer, SingleElement } from '@/models/organizer';
import { Settings } from '@/settings';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Apollo } from 'apollo-angular';


@Component({
  selector: 'app-cetacean',
  templateUrl: './cetacean.component.html',
  styleUrls: ['./cetacean.component.scss']
})
export class CetaceanComponent implements OnInit
{
    cetaceanId: string = "";
    cetaceanName: string = "";
    introSentence: string = "";
    
    links : Array<Link>;

    backendUrl: string = Settings.backEndUrl;

    cetacean: Cetacean | undefined;    
    organizerCetaBehav_part1: Organizer | undefined;    // Contient les 3 premiers behavior
    organizerCetaBehav_part2: Organizer | undefined;    // Contient les 3 behaviors suivants
    organizerCetaBehav_part3: Organizer | undefined;    // Contient le reste des behaviors
    
    constructor(private apollo: Apollo, private route: ActivatedRoute)
    {
        // Menu links
        this.links = [
            { url: "Cetace/" + this.route.snapshot.params['id'] + "#par", name: "Particularités" },
            { url: "Cetace/" + this.route.snapshot.params['id'] + "#ana", name: "Anatomie" },
            { url: "Cetace/" + this.route.snapshot.params['id'] + "#qzz", name: "Quizz" },
            { url: "Cetace/" + this.route.snapshot.params['id'] + "#gal", name: "Galerie" },
        ];
    }

    ngOnInit(): void
    {
        // Cetacean
        this.apollo.query<CetaceanResponse>({
            query: FIND_CETACEAN,
            variables:
            {
                cetaceanId: this.route.snapshot.params['id'],
            },
        })
        .subscribe(result => 
        {
            this.cetacean = result.data.cetacean;
            
            this.cetaceanId = this.cetacean.id;
            this.cetaceanName = this.cetacean.commonName;
            this.introSentence = "<b>" + this.cetacean.sentenceIntro + "</b><br><br><b>" +
                this.cetacean.commonName + ",</b> ou <i>" + this.cetacean.scientificName + "</i>, fait partie de la famille <span class='cetaceanFamilyTxt'>" + this.cetacean.familyName + "</span>.<br>" +
                "Son status UICN : " + this.cetacean.UICN + "<br><br>";
                this.cetacean.sentenceWhereToFindIt;
            
            let organizers_p1: SingleElement[] = [];
            let organizers_p2: SingleElement[] = [];
            let organizers_p3: SingleElement[] = [];
            for (let i=0; i<result.data.cetacean.behaviors.length; i++)
            {
                const behav = result.data.cetacean.behaviors[i];
                let singleElem: SingleElement = { title: behav.cetacean_behavior_type.name, description: behav.description, icon: behav.cetacean_behavior_type.icon.url, buttonName: null, buttonUrl: null, buttonUseCta: false, urlTarget: null };
                if (i < 3) {
                    organizers_p1.push(singleElem);
                }
                else if (i >= 3 && i < 6) {
                    organizers_p2.push(singleElem);
                }
                else {
                    organizers_p3.push(singleElem);
                }
            }
            this.organizerCetaBehav_part1 = { elements: organizers_p1 };
            this.organizerCetaBehav_part2 = { elements: organizers_p2 };
            this.organizerCetaBehav_part3 = { elements: organizers_p3 };
        });
    }
}
