import { Organizer } from '@/models/organizer';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-us',
  templateUrl: './help-us.component.html',
  styleUrls: ['./help-us.component.scss']
})
export class HelpUsComponent
{
    helpusContent: string;
    organizerHelpus: Organizer;

    constructor()
    {
        this.helpusContent = "<b>\"Mon École, Ma Baleine\"</b> est une association qui vit des donations et des aides de nos partenaires pour proposer des outils gratuits et jeux immersifs au plus grand nombre.<br><br>" +
            "Nous aider, c'est militer pour la protection des mammifères marins dans les Antilles mais aussi à l'échelle de la planète, à travers la diffusion de connaissances et de la sensibilisation tout âge.<br><br>" +
            "Avoir des adhérents est important pour MEMB, cela traduit un impact, des personnes qui s'impliquent et avec vos dons, nous pouvons refabriquer de nouveaux outils et réimprimer les éditions épuiseés.<br><br>" +
            "Merci !!!";

        this.organizerHelpus = { 
            elements: [
                { icon: null, buttonName: "Adhérer", urlTarget: "_blank", buttonUseCta: true, buttonUrl: "https://www.helloasso.com/associations/mon-ecole-ma-baleine", title: "Vous pouvez adhérez", description: "Le nombre d'adhérents a un rôle important pour l'association puisqu'il crédibilise l'association auprès des financeurs mais aussi auprès des décisionnaires. Les adhérents bénéficient d'une réduction de 10% sur notre boutique en ligne et des réductions chez nos prestataires partenaires. Liste réactualisée régulièrement et fournie à l'adhésion. Toute structure souhaitant notre venue doit adhérer.<br>Adhésion en ligne, c'est facile !" },
                { icon: null, buttonName: "Voir nos outils", urlTarget: "_self", buttonUseCta: false, buttonUrl: "./PaperActivities", title: "En utilisant nos outils !", description: "Télécharger, partager, projeter ..." },
                { icon: null, buttonName: "Nous contacter", urlTarget: "_self", buttonUseCta: false, buttonUrl: "./ContactezNous", title: "En devenant bénévole", description: "Même si vous êtes loin, nous sommes toujours à la recherche de testeurs et relecteurs pour nos nouveaux exercices, illustrés, histoires...<br>Nous recherchons des traducteurs, chanteurs, musiciens, dessinateurs.<br><br>Sur les Antilles, les bénévoles qui nous aident sur les stands sont toujours les bienvenus. Contactez-nous." },
                { icon: null, buttonName: "Faire un don", urlTarget: "_blank", buttonUseCta: true, buttonUrl: "https://www.helloasso.com/associations/mon-ecole-ma-baleine", title: "Grâce à vos dons", description: "Plus de créations, plus de contenus!<br><br>Vos dons seront déductibles des impôts à hauteur de 66%. Merci!!!" },
                { icon: null, buttonName: "La boutique", urlTarget: "_blank", buttonUseCta: false, buttonUrl: "https://maboutiquemabaleine.com", title: "Faire un tour dans notre boutique", description: "Nos outils sont aussi mis en vente dans notre boutique en ligne.<br>Tous les bénéfices sont entièrement réinvestis dans l'association.<br><br>Nous avons un réseau de \"Points contacts\" afin d'être plus proche de l'acheteur et réduire l'impact carbone. Nous envoyons aussi par voie postale." },
                { icon: null, buttonName: null, urlTarget: null, buttonUseCta: false, buttonUrl: null, title: "En protégeant les cétacés,", description: "Réduire ses déchets, recycler, réduire sa consommation, développer le covoiturage, manger en privilégiant les produits de saison et locaux, privilégier les pêches et cultures résonnées, ne pas alimenter les réseaux de captivité, de maltraitance animalière, éviter les pesticides et insecticides ou divers produits toxiques, réduire au maximum son impact carbone ....<br><br>&#128515;" }
            ] 
        }
    }
}
