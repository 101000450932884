
import gql from "graphql-tag";

export interface Antenna
{
    antenna: string;
    email: string;
}

export interface Antennas
{
    antennas: Antenna[];
}

export const FIND_ANTENNAS = gql`
query Antennas
{
    antennas
    {
        antenna
        email
    }
}`;