import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-type-e',
  templateUrl: './card-type-e.component.html',
  styleUrls: ['./card-type-e.component.scss']
})
export class CardTypeEComponent
{
    @Input() name: string = '';
    @Input() cetaceanId: string = '';
    
    @Input() urlImageStandard: string = '';
}
