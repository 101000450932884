
<!-- Photo Full Viewport -->
<app-presentation title="Mon École, Ma Baleine" [content]="introContent" urlImage="assets/images/association.jpg"></app-presentation>

<!-- Sous-menu -->
<app-submenu [links]="links"></app-submenu>
<separator id="val"></separator>

<!-- Nos valeurs et objectifs -->
<div class="container-fluid">
    <app-title title="Nos valeurs et nos objectifs"></app-title>
</div>
<app-organizer [organizer]="organizerAssocObjectives"></app-organizer>

<separator></separator>
<separator-color></separator-color>

<!-- L'association en chiffres -->
<div class="container-fluid bg-blue-dark">
    <separator></separator>
    <app-title title="L'association en chiffres" [useThemeDark]="true"></app-title>
    <app-specificities [specificities]="associationNumbers"></app-specificities>
    <separator heightRem="8"></separator>
</div>

<separator-color></separator-color>

<!-- Comment intervenons nous ? -->
<app-bg-adaptable bgColor="bg-blue-gradient">

    <separator id="int"></separator>

    <div class="container">
        <div class="row title_1">
            <div class="col typo_1 color-blue-light">Comment intervenons-nous ?</div>
        </div>
        <img class="mt-8r mb-32r" src="assets/images/Separator_Multi.svg"/>
    </div>

    <app-content-double verticalAlign="center">
        <div content-left>
            
            <app-subtitle-txt [bgDisableInLargeScreen]="true" bgColor="bg-blue-light">
                <div title class="color-yellow-medium">Intervenir en milieu scolaire</div>
                <div content class="color-blue-light">
                    Les interventions sont ludiques et interactives, allant de la crèche à l’université, ou pour des formations à la demande du rectorat ou des centres de formation pour adultes. Nous présentons des films, des diaporamas et de nombreux jeux pédagogiques qui permettent de pouvoir échanger, de s'impliquer, de prendre le temps de comprendre.
                </div>
            </app-subtitle-txt>

        </div>
        <div content-right>
            <img class="img-fluid" src="assets/images/interventions/interventions_scolaire.jpg" />
        </div>
    </app-content-double>
    
    <separator></separator>

    <app-content-double verticalAlign="center">

        <div content-left>
            
            <app-subtitle-txt [bgDisableInLargeScreen]="true" bgColor="bg-blue-light">
                <div title class="color-yellow-medium">Se déplacer sur le littoral</div>
                <div content class="color-blue-light">
                    Nous allons en bord de mer pour approfondir les connaissances vues en classe avec des nouvelles activités de terrain : par exemple pour apprendre à rechercher les baleines depuis la côte ou mieux comprendre l'écholocation.
                </div>
            </app-subtitle-txt>

        </div>
        <div content-right>
            <img class="img-fluid" src="assets/images/interventions/deplacer_littoral.JPG" />
        </div>
    </app-content-double>
    
    <separator></separator>

    <app-content-double verticalAlign="center">
        <div content-right>
            
            <app-subtitle-txt [bgDisableInLargeScreen]="true" bgColor="bg-blue-light">
                <div title class="color-yellow-medium">Accompagner en mer</div>
                <div content class="color-blue-light">
                    Mon École, Ma Baleine peut être aménée à accompagner les sorties en mer pour des classes qui ont un projet, le plus souvent sur l'année, en choisissant des partenaires respectueux des approches des cétacés (à partir du collège).
                </div>
            </app-subtitle-txt>

        </div>
        <div content-left>
            <img class="img-fluid" src="assets/images/interventions/accompagner_mer.jpg" />
        </div>
    </app-content-double>
    
    <separator></separator>

    <app-content-double verticalAlign="center">
        <div content-right>

            <app-subtitle-txt [bgDisableInLargeScreen]="true" bgColor="bg-blue-light">
                <div title class="color-yellow-medium">Encadrer à distance</div>
                <div content class="color-blue-light">
                    Mon École, Ma Baleine peut accompagner à distance des classes en proposant des progressions, des outils ou des visio-conférences quand il n'y a pas d'intervenants de l'association sur place.
                </div>
            </app-subtitle-txt>

        </div>
        <div content-left>
            <img class="img-fluid" src="assets/images/interventions/outils_distance.jpg" />
        </div>
    </app-content-double>
    
    <separator heightRem="8"></separator>

</app-bg-adaptable>

<separator-color></separator-color>

<!-- Nos valeurs et objectifs -->
<separator></separator>
<div class="container-fluid">
    <app-title title="Quels sont nos outils"></app-title>
</div>
<app-organizer [organizer]="organizerAssocTools"></app-organizer>

<separator-color></separator-color>

<div class="container-fluid background-coral">
        
    <separator id="mem"></separator>

    <!-- Les membres principaux -->
    <app-title title="Qui sommes nous ?"></app-title>
    <div class="container">
        <div class="row">
            <div class="col">

                <div class="typo_2 mb-8r">Le bureau</div>
                <div class="typo_3 ml-32r mb-48r">Le bureau et le conseil d'administration sont constitués de bénévoles passionnés.</div>
                
                <div class="d-flex flex-row flex-wrap">
                    <ng-container *ngFor="let pcp of principalMembersWithPhoto">
                        
                        <app-card-type-c *ngIf="pcp.photo_adult" class="col-12 col-sm-6 col-lg-3 g-0" 
                            [name]="pcp.firstname + ' ' + pcp.lastname"
                            [subname]="pcp.function"
                            [description]="pcp.description"
                            [urlImageStandard]="pcp.photo_adult ? backendUrl + pcp.photo_adult.url : ''"
                            [urlImageHover]="pcp.photo_child ? backendUrl + pcp.photo_child.url : ''">
                        </app-card-type-c>
        
                    </ng-container>
                </div>
    
                <div *ngIf="halfPrincipalMembersWithoutPhoto > 0" class="typo_2 mt-48r mb-16r">Mais aussi ...</div>
                
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-lg-6">
                            
                            <ng-container *ngFor="let pcp of principalMembersWithoutPhoto; let i=index">
                                <div *ngIf="!pcp.photo_adult && i<halfPrincipalMembersWithoutPhoto">
                                    <span class="typo_6 mr-16r">{{pcp.firstname}} {{pcp.lastname}}</span>
                                    <span class="typo_4">{{pcp.function}}</span>
                                </div>
                            </ng-container>
    
                        </div>
                        <div class="col-12 col-lg-6">
    
                            <ng-container *ngFor="let pcp of principalMembersWithoutPhoto; let i=index">
                                <div *ngIf="!pcp.photo_adult && i>=halfPrincipalMembersWithoutPhoto">
                                    <span class="typo_6 mr-16r">{{pcp.firstname}} {{pcp.lastname}}</span>
                                    <span class="typo_4">{{pcp.function}}</span>
                                </div>
                            </ng-container>
    
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <separator heightRem="8"></separator>

        <div class="row">
            <div class="col">

                <div class="typo_2 mb-8r">Les bénévoles : intervenants, traducteurs, relecteurs et artistes</div>
                <div class="typo_3 ml-32r mb-48r">Découvrez les bénévoles généreux formés par Mon École, Ma baleine et que vous retrouverez dans les écoles et sur les stands.<br>Merci aux très nombreux traducteurs, relecteurs et artistes qui permettent aux outils d'être réalistes, ludiques, esthétiques, actualisés et disponibles dans de nombreuses langues.</div>
                
                <div class="d-flex flex-row flex-wrap">
                    <ng-container *ngFor="let ben of benevolesWithPhoto">
        
                        <app-card-type-d *ngIf="ben.photo_adult" class="col-6 col-sm-4 col-lg-2 g-0" 
                            [name]="ben.firstname + ' ' + ben.lastname"
                            [subname]="ben.function"
                            [urlImageStandard]="ben.photo_adult ? backendUrl + ben.photo_adult.url : ''"
                            [urlImageHover]="ben.photo_child ? backendUrl + ben.photo_child.url : ''">
                        </app-card-type-d>
        
                    </ng-container>
                </div>
    
                <div *ngIf="halfBenevolesWithoutPhoto > 0" class="typo_6 mt-48r mb-16r">Mais aussi ...</div>
                
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-lg-6">
                            
                            <ng-container *ngFor="let ben of benevolesWithoutPhoto; let i=index">
                                <div *ngIf="!ben.photo_adult && i<halfBenevolesWithoutPhoto">
                                    <span class="typo_6 mr-16r">{{ben.firstname}} {{ben.lastname}}</span>
                                    <span class="typo_4">{{ben.function}}</span>
                                </div>
                            </ng-container>
    
                        </div>
                        <div class="col-12 col-lg-6">
    
                            <ng-container *ngFor="let ben of benevolesWithoutPhoto; let i=index">
                                <div *ngIf="!ben.photo_adult && i>=halfBenevolesWithoutPhoto">
                                    <span class="typo_6 mr-16r">{{ben.firstname}} {{ben.lastname}}</span>
                                    <span class="typo_4">{{ben.function}}</span>
                                </div>
                            </ng-container>
    
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <separator heightRem="8"></separator>

        <div class="row">
            <div class="col">
     
                <div class="typo_2 mb-8r">Les salariés et service civiques</div>
                <div class="typo_3 ml-32r mb-48r">Julie est notre première salariée en CDI. Avant elle, Marlène était en contrat aidé et nous avons reçu de nombreuses personnes en service civique qui nous ont offert plus que le contrat demandé, ajoutant beaucoup de passion, d'amitié et de générosité. Merci à eux.</div>
                
                <div class="d-flex flex-row flex-wrap">
                    <ng-container *ngFor="let emp of employeesWithPhoto">

                        <app-card-type-d *ngIf="emp.photo_adult" class="col-6 col-sm-4 col-lg-2 g-0" 
                            [name]="emp.firstname + ' ' + emp.lastname"
                            [subname]="emp.function"
                            [urlImageStandard]="emp.photo_adult ? backendUrl + emp.photo_adult.url : ''"
                            [urlImageHover]="emp.photo_child ? backendUrl + emp.photo_child.url : ''">
                        </app-card-type-d>
                        
                    </ng-container>
                </div>

                <div *ngIf="halfEmployeesWithoutPhoto > 0"  class="typo_2 mt-48r mb-16r">Mais aussi ...</div>
                
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-lg-6">
                            
                            <ng-container *ngFor="let emp of employeesWithoutPhoto; let i=index">
                                <div *ngIf="!emp.photo_adult && i<halfEmployeesWithoutPhoto">
                                    <span class="typo_6 mr-16r">{{emp.firstname}} {{emp.lastname}}</span>
                                    <span class="typo_4">{{emp.function}}</span>
                                </div>
                            </ng-container>

                        </div>
                        <div class="col-12 col-lg-6">

                            <ng-container *ngFor="let emp of employeesWithoutPhoto; let i=index">
                                <div *ngIf="!emp.photo_adult && i>=halfEmployeesWithoutPhoto">
                                    <span class="typo_6 mr-16r">{{emp.firstname}} {{emp.lastname}}</span>
                                    <span class="typo_4">{{emp.function}}</span>
                                </div>
                            </ng-container>

                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    
    <separator heightRem="8"></separator>

</div>

<separator-color></separator-color>

<!-- Bilans d'activités -->
<div class="container-fluid">

    <separator id="bil" heightRem="8"></separator>
    <app-title title="Bilan d'activités"></app-title>
    
    <div class="container">
        <div class="row">
            
            <ng-container *ngFor="let annualReport of annualReports">
                <a class="linkButton typo_7 text-decoration-none color-blue-medium" [href]="backendUrl + annualReport.report.url" target="_blank">{{ annualReport.name }}</a> 
            </ng-container>

        </div>
    </div>
    
    <separator heightRem="8"></separator>
</div>

<separator-color></separator-color>

<!-- Partenaires -->
<app-bg-adaptable bgColor="bg-blue-gradient">

    <separator id="par"></separator>

    <div class="container">
        <div class="row title_1">
            <div class="col typo_1 color-blue-light">Partenaires scientifiques</div>
        </div>
        <img class="mt-8r mb-32r" src="assets/images/Separator_Multi.svg"/>
    </div>

    <app-organizer [organizer]="organizerPartnersScientific" bgColor="bg-blue-medium" color="color-blue-light" [bgDisableInLargeScreen]="true"></app-organizer>

    <separator heightRem="8"></separator>

</app-bg-adaptable>

<separator-color></separator-color>

<!-- Partenaires financiers -->
<div class="container-fluid">

    <separator></separator>
    <app-title title="Partenaires financiers"></app-title>
    <div class="container">
        <div class="row">

            <div class="row row-cols-3 row-cols-md-6 align-items-center">
                <div class="col mb-24r" *ngFor="let partner of partners">
                    <a [href]="partner.link" target="_blank">
                        <img [src]="backendUrl + partner.logo.url" class="card-img-top">
                    </a>
                </div>
            </div>

        </div>
        <separator heightRem="8"></separator>
    </div>

</div>

<separator-color></separator-color>

<!-- Partenaires prestataires -->
<app-bg-adaptable bgColor="bg-blue-gradient">

    <separator></separator>

    <div class="container">
        <div class="row title_1">
            <div class="col typo_1 color-blue-light">Nos partenaires prestataires</div>
        </div>
        <img class="mt-8r mb-32r" src="assets/images/Separator_Multi.svg"/>
    </div>
    
    <app-organizer [organizer]="organizerFriends" bgColor="bg-blue-medium" color="color-blue-light" [bgDisableInLargeScreen]="true"></app-organizer>

    <separator heightRem="8"></separator>

</app-bg-adaptable>

<separator-color></separator-color>

<!-- Partenaires Assoc -->
<div class="container-fluid">

    <separator></separator>
    <app-title title="Structures et associations à découvrir"></app-title>
    <div class="container g-0">
        <div class="typo_3 ml-32r mb-48r">Ces structures et association ont une éthique et des actions que nous approuvons, allez les découvrir et les soutenir !</div>
    </div>
    <app-organizer [organizer]="organizerPartnersAssoc"></app-organizer>
    
    <separator heightRem="8"></separator>
    
</div>
