
<div class="position-relative card-type-c">
    
    <div class="position-absolute top-0 start-0 w-100 mask-img">
        <img class="position-absolute top-0 start-0 w-100" [src]="urlImageStandard" />
        <img *ngIf="urlImageHover" class="position-absolute top-0 start-0 w-100 img-above" [src]="urlImageHover" />
    </div>

    <svg class="position-absolute">
        <clipPath id="svgmask" clipPathUnits="objectBoundingBox">
            <path d="M0.194,0.254c0.163-0.208,0.545-0.166,0.639,0.04c0.124,0.275,0.018,0.462-0.118,0.538 C0.609,0.891,0.326,0.937,0.187,0.731C0.06,0.543,0.117,0.352,0.194,0.254z"></path>
        </clipPath>
    </svg>

    <div class="position-absolute start-0 w-100 px-56r text-center top-delta section-mid">
        <div class="typo_6 color-blue-dark">{{ name }}</div>
        <div class="typo_10_C mb-16r">{{ subname }}</div>
        <div class="typo_4_B color-blue-dark mb-48r">{{ description }}</div>
    </div>
</div>