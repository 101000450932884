
<div class="background-cover bg-img-footer">

    <div class="container h-100">
        <div class="row h-100 align-items-center text-center g-0">

            <div class="col-5 typo_footer">
                Mieux comprendre
            </div>

            <div class="col-2">
                <img src="assets/images/logo_memb_footer.svg" />
            </div>

            <div class="col-5 typo_footer">
                Mieux protéger
            </div>

        </div>
    </div>

</div>