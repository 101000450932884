
<div class="container">
    <div class="row" [ngClass]="{'align-items-center': verticalAlign === 'center'}">

        <div class="col-12 col-lg-6 typo_4 mb-32r">
            <ng-content select="[content-left]"></ng-content>
        </div>

        <div class="col-12 col-lg-6 typo_4 mb-32r">
            <ng-content select="[content-right]"></ng-content>
        </div>

    </div>
</div>