import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'separator-color',
  templateUrl: './separator-color.component.html',
  styleUrls: ['./separator-color.component.scss']
})
export class SeparatorColorComponent
{
}
