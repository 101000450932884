
<div class="container">

    <div class="row justify-content-center" *ngIf="organizer">
        
        <ng-container *ngFor="let element of organizer.elements">
            <div class="col-12 col-md-6 col-xl-4 mb-32r">

                <app-subtitle-txt   [buttonName]="element.buttonName" [buttonUrl]="element.buttonUrl" [urlTarget]="element.urlTarget" [useCta]="element.buttonUseCta"
                                    [iconUrl]="element.icon" [bgColor]="bgColor" [bgDisableInLargeScreen]="bgDisableInLargeScreen">
                    <div title [ngClass]="color">{{ element.title }}</div>
                    <div content [ngClass]="color" [innerHTML]="element.description"></div>
                </app-subtitle-txt>

            </div>
        </ng-container>

    </div>
</div>