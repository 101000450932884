
<div class="position-relative">

    <div class="position-absolute container w-100 h-100 start-0 end-0 g-0">
        <div class="row g-0">
            <div class="col"><div class="rounded-top-right" [ngClass]="classBgColor"></div></div>
            <div class="col"><div class="rounded-top-left" [ngClass]="classBgColor"></div></div>
        </div>
        <div class="row h-100 g-0">
            <div class="col"><div class="rounded-btm-left-right" [ngClass]="classBgColor"></div></div>
        </div>
    </div>

    <div class="position-relative d-flex flex-column align-items-center px-16r pt-16r pb-32r">
        <img *ngIf="iconUrl" [src]="backendUrl + iconUrl" class="img-fluid icon-neg-margin">
    
        <div class="typo_2 mb-16r text-center">
            <ng-content select="[title]"></ng-content>
        </div>
        <div class="typo_4 text-center pre-line">
            <ng-content select="[content]"></ng-content>
        </div>
    
        <div *ngIf="buttonName" class="mt-32r">
            <a *ngIf="!useCta" class="standardButton typo_8" (click)="onBtnClicked()">{{ buttonName }}</a>
            <btncta *ngIf="useCta" (click)="onBtnClicked()">{{ buttonName }}</btncta>
        </div>
    </div>

</div>