

import gql from "graphql-tag";
import { Cetacean } from "../cetaceans/cetaceans";

export interface Home
{
    cetaceans: Cetacean[];
}

export interface HomeResponse
{
    home: Home;
}

export const GET_HOME_DATA = gql`
query Home
{
    home(id: 1)
    {
        cetaceans
        {
            id
            commonName
            primeImage
            {
                url
            }
        }
    }
}`;