import { Quiz } from '@/apollo/queries/quiz/quiz';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss']
})
export class QuizComponent implements OnInit
{
    idQuestion: number = 0;

    @Input() quizzes: Quiz[] | undefined;
    
    idCurrentQuizz: number = 0;
    currentQuiz: Quiz | undefined;

    answerIdCorrect: number = 0;
    answerCorrect: boolean = false;
    answerGiven: boolean = false;
    isLastQuestion: boolean = false;

    constructor()
    {
    }

    ngOnInit(): void
    {
        if (this.quizzes && this.quizzes.length > 1)
        {
            this.idCurrentQuizz = -1;
            this.gotoNextQuestion();
        }
    }

    onSelectAnswer(idAnswer: number)
    {
        if (this.quizzes && !this.answerGiven)
        {
            this.answerIdCorrect = this.quizzes[this.idCurrentQuizz].answerCorrectId;
            this.answerCorrect = this.answerIdCorrect === idAnswer;
            this.answerGiven = true;

            console.log(this.answerCorrect + " ... " + idAnswer + " ... " + this.answerIdCorrect);
        }
    }

    onNextQuestionClick()
    {
        this.gotoNextQuestion();
    }

    gotoNextQuestion()
    {
        if (this.quizzes && this.quizzes.length > 1)
        {
            this.idCurrentQuizz++;
            
            if (this.idCurrentQuizz >= this.quizzes.length) this.idCurrentQuizz = 0;
            this.isLastQuestion = this.idCurrentQuizz == this.quizzes.length - 1;
            this.currentQuiz = this.quizzes[this.idCurrentQuizz];
    
            this.answerGiven = false;
        }
    }
}
