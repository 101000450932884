import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss']
})
export class TitleComponent
{
    @Input() useTopSpace: boolean = false;
    @Input() title: string | undefined;

    @Input() useThemeDark: boolean = false;
    @Input() useFlag: boolean = false;
}
