
<div *ngIf="links" class="sticky-top" [ngClass]="useThemeDark ? 'bg-blue-dark' : 'bg-blue-light'">

    <div class="container">
        <div class="row">
            <div class="col">
                <div class="spy-bar">
                    <div class="spy-link-container d-flex flex-row">
                        <a *ngFor="let link of links" class="spy-link typo_submenu mx-3" [ngClass]="useThemeDark ? 'color-theme-dark' : 'color-theme-light'" [href]="link.url">{{link.name}}</a>
                    </div>

                    <div *ngIf="dllFr != '' || dllEn != '' || dllEs != ''" class="col-lg-2 d-flex flex-row align-items-center justify-content-end">
                        <div class="typo_8 downloadButton position-relative">
                            <div class="position-absolute px-16r py-16r actcor">
                                <div class="downloadBtn d-flex flex-row align-items-center justify-content-between" *ngIf="dllFr != ''" (click)="onDownloadFr()">
                                    <div class="mr-16r">Français</div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15.24" height="16" viewBox="0 0 26.204 27.43">
                                        <path d="M-6785.089,2559.225a.993.993,0,0,1-.413-.089h-20.051a1,1,0,0,1-.413.089h-1.664a1,1,0,0,1-1-1V2552.9a1,1,0,0,1,1-1h1.664a1,1,0,0,1,1,1v2.575h18.876V2552.9a1,1,0,0,1,1-1h1.664a1,1,0,0,1,1,1v5.329a1,1,0,0,1-1,1Zm-11.512-8.094-7.414-5.227a1.853,1.853,0,0,1-.446-2.577,1.854,1.854,0,0,1,1.2-.764,1.855,1.855,0,0,1,1.391.319l4.486,3.134v-12.368a1.854,1.854,0,0,1,1.854-1.854,1.853,1.853,0,0,1,1.852,1.854v12.268l4.45-3.35a1.855,1.855,0,0,1,2.6.371,1.855,1.855,0,0,1-.371,2.6l-7.414,5.56a1.854,1.854,0,0,1-1.112.371h-.017A1.844,1.844,0,0,1-6796.6,2551.131Z" transform="translate(6808.629 -2531.795)" class="downloadSvg"/>
                                    </svg>                          
                                </div>
                                <div class="downloadBtn d-flex flex-row align-items-center justify-content-between mt-16r" *ngIf="dllEn != ''" (click)="onDownloadEn()">
                                    <div class="mr-16r">English</div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15.24" height="16" viewBox="0 0 26.204 27.43">
                                        <path d="M-6785.089,2559.225a.993.993,0,0,1-.413-.089h-20.051a1,1,0,0,1-.413.089h-1.664a1,1,0,0,1-1-1V2552.9a1,1,0,0,1,1-1h1.664a1,1,0,0,1,1,1v2.575h18.876V2552.9a1,1,0,0,1,1-1h1.664a1,1,0,0,1,1,1v5.329a1,1,0,0,1-1,1Zm-11.512-8.094-7.414-5.227a1.853,1.853,0,0,1-.446-2.577,1.854,1.854,0,0,1,1.2-.764,1.855,1.855,0,0,1,1.391.319l4.486,3.134v-12.368a1.854,1.854,0,0,1,1.854-1.854,1.853,1.853,0,0,1,1.852,1.854v12.268l4.45-3.35a1.855,1.855,0,0,1,2.6.371,1.855,1.855,0,0,1-.371,2.6l-7.414,5.56a1.854,1.854,0,0,1-1.112.371h-.017A1.844,1.844,0,0,1-6796.6,2551.131Z" transform="translate(6808.629 -2531.795)" class="downloadSvg"/>
                                    </svg> 
                                </div>
                                <div class="downloadBtn d-flex flex-row align-items-center justify-content-between mt-16r" *ngIf="dllEs != ''" (click)="onDownloadEs()">
                                    <div class="mr-16r">Español</div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15.24" height="16" viewBox="0 0 26.204 27.43">
                                        <path d="M-6785.089,2559.225a.993.993,0,0,1-.413-.089h-20.051a1,1,0,0,1-.413.089h-1.664a1,1,0,0,1-1-1V2552.9a1,1,0,0,1,1-1h1.664a1,1,0,0,1,1,1v2.575h18.876V2552.9a1,1,0,0,1,1-1h1.664a1,1,0,0,1,1,1v5.329a1,1,0,0,1-1,1Zm-11.512-8.094-7.414-5.227a1.853,1.853,0,0,1-.446-2.577,1.854,1.854,0,0,1,1.2-.764,1.855,1.855,0,0,1,1.391.319l4.486,3.134v-12.368a1.854,1.854,0,0,1,1.854-1.854,1.853,1.853,0,0,1,1.852,1.854v12.268l4.45-3.35a1.855,1.855,0,0,1,2.6.371,1.855,1.855,0,0,1-.371,2.6l-7.414,5.56a1.854,1.854,0,0,1-1.112.371h-.017A1.844,1.844,0,0,1-6796.6,2551.131Z" transform="translate(6808.629 -2531.795)" class="downloadSvg"/>
                                    </svg> 
                                </div>
                            </div>
                            TÉLÉCHARGER
                        </div>
                    </div>
                </div>
            </div>

        </div>    
    </div>

    <separator-color></separator-color>
</div>