
<div class="container">
    <div class="row">
        <div class="col-lg-8 col-min-height">

            <div id="renderCanvasLoading" class="justify-content-center align-items-center text-center h-100">
                <div class="typo_2 color-yellow-medium">Chargement...</div>
            </div>
            
            <canvas #rendererCanvas id="renderCanvas" (window:resize)="onResize($event)">
                Votre navigateur ne supporte pas la 3D.
            </canvas>

        </div>

        <div class="col-lg-4 text-center py-5">
            
            <div>
                <div class="col typo_2 white-spacer color-blue-light">Son anatomie</div>
                <img class="mt-8r mb-32r" src="assets/images/Separator_Multi.svg"/>
            </div>

            <div class="d-flex flex-wrap justify-content-center mb-48r">
                <ng-container *ngFor="let anatomy of cetaDefSelected?.anatomyParts">
                    <a class="standardButtonLight typo_10_B" (click)="onAnatomyClicked(anatomy)">{{ anatomy.title }}</a>
                </ng-container>
            </div>
            
            <ng-container *ngIf="cetaAnatomySelected"> 
                <div class="font-bold mb-16r">
                    <div class="typo_2 color-yellow-medium">{{ cetaAnatomySelected.title }}</div>
                </div>
                
                <div class="typo_4 color-blue-light">{{ cetaAnatomySelected.description }}</div>
            </ng-container>

            <ng-container *ngIf="!cetaAnatomySelected"> 
                <div class="font-bold mb-16r">
                    <div class="typo_2 color-yellow-medium">Visite 3D</div>
                </div>
                
                <div class="typo_4 color-blue-light">Clique sur un bouton du dessus pour avoir la description et explore notre cétacé en 3D !</div>
            </ng-container>

        </div>
    </div>
</div>