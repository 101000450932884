
import { Anatomy, AnatomyPart, CetaceanDef } from '@/models/cetacean';
import { CetaAnatomy3dService } from '@/services/ceta-anatomy-3d.service';
import { AfterContentInit, ElementRef, Input, ViewChild } from '@angular/core';
import { Component } from '@angular/core';

@Component({
    selector: 'app-ceta-anatomy',
    templateUrl: './ceta-anatomy.component.html',
    styleUrls: ['./ceta-anatomy.component.scss']
})
export class CetaAnatomyComponent implements AfterContentInit
{
    @Input() cetaceanId: string = '';

    @ViewChild('rendererCanvas', { static: true }) 
    rendererCanvas: ElementRef<HTMLCanvasElement> | undefined;

    cetaDefs: CetaceanDef[];
    cetaDefSelected: CetaceanDef | undefined;
    cetaAnatomySelected: Anatomy | undefined;

    constructor(private ceta3d: CetaAnatomy3dService)
    {
        this.cetaDefs = [
            {
                id: "1",
                cetaceanName: "Le Cachalot",
                meshName: "Cachalot",
                category: "BigCetaceans",
                zoom: 46,
                anatomyParts: [
                    {
                        part: AnatomyPart.Pectorales,
                        title: 'Nageoires pectorales',
                        description: "Elles sont utilisées pour la stabilisation."
                    },
                    {
                        part: AnatomyPart.Caudale,
                        title: 'Nageoire caudale',
                        description: "Elle est très puissante et lui sert à se propulser."
                    },
                    {
                        part: AnatomyPart.Dorsale,
                        title: 'Crête dorsale',
                        description: "La crête dorsale est suivie de plusieurs autres crêtes plus petites."
                    },
                    {
                        part: AnatomyPart.Oeil,
                        title: 'Oeil',
                        description: "Il a une bonne vue, aussi bien sous l’eau qu'à la surface."
                    },
                    {
                        part: AnatomyPart.Event,
                        title: 'Évent',
                        description: "Comme l'évent (narine) est sur l'avant gauche de sa tête, son souffle part à 45 ° vers la gauche. Il est donc facilement identifiable."
                    },
                    {
                        part: AnatomyPart.Spermaceti,
                        title: 'Spermaceti',
                        description: "Le spermaceti composé de matière grasse se liquéfie en se réchauffant et se durcit en se refroidissant. Cela modifie sa flottabilité et lui permet de descendre ou de remonter avec très peu de mouvements. Pratique !"
                    },
                ]
            },
            {
                id: "2",
                cetaceanName: "La Baleine à bosse",
                meshName: "Humpback",
                category: "BigCetaceans",
                zoom: 50,
                anatomyParts: [
                    {
                        part: AnatomyPart.Tubercules,
                        title: 'Tubercules',
                        description: "Dans chacun des tubercules se trouve un poil sensoriel qui lui permet d'obtenir des informations sur la salinité de l'eau, la température, les courants..."
                    },
                    {
                        part: AnatomyPart.Pectorales,
                        title: 'Nageoires pectorales',
                        description: "Elles peuvent mesurer 4 à 6 mètres !!!! Elles sont utilisées pour se stabiliser, mais aussi claquées pour se défendre ou communiquer. "
                    },
                    {
                        part: AnatomyPart.Caudale,
                        title: 'Nageoire caudale',
                        description: "Les individus sont différenciables grâce à la forme et aux motifs de couleur de leur nageoire caudale."
                    },
                    {
                        part: AnatomyPart.Oeil,
                        title: 'Oeil',
                        description: "Il est souvent fermé quand elle est à la surface."
                    },
                    {
                        part: AnatomyPart.Event,
                        title: 'Évent',
                        description: "Son souffle peut atteindre trois mètres de haut. On le remarque de loin."
                    },
                    {
                        part: AnatomyPart.Bosse,
                        title: 'Bosse',
                        description: "Cette bosse devant son aileron est surtout visible quand la baleine plonge. Ce sont les chasseurs qui l'ont appelée 'Baleine à bosse', quand la chasse était encore pratiquée."
                    },
                    {
                        part: AnatomyPart.SillonsGulaires,
                        title: 'Sillons gulaires',
                        description: "Ses sillons sous la gorge se détendent, lui permettant de faire entrer beaucoup d’eau et de nourriture dans sa bouche."
                    },
                ]
            },
            {
                id: "3",
                cetaceanName: "Le grand dauphin",
                meshName: "Bottlenose",
                category: "SmallMedCetaceans",
                zoom: 14,
                anatomyParts: [
                    {
                        part: AnatomyPart.Pectorales,
                        title: 'Nageoires pectorales',
                        description: "Elles sont utilisées pour la stabilisation."
                    },
                    {
                        part: AnatomyPart.Caudale,
                        title: 'Nageoire caudale',
                        description: "Elle est très puissante et lui sert à se propulser."
                    },
                    {
                        part: AnatomyPart.Dorsale,
                        title: 'Nageoire dorsale',
                        description: "C'est grâce à une photo de sa nageoire dorsale que l'on peut identifier les différents individus."
                    },
                    {
                        part: AnatomyPart.Oeil,
                        title: 'Oeil',
                        description: "Les yeux des cétacés sont munis de paupières."
                    },
                    {
                        part: AnatomyPart.Event,
                        title: 'Event',
                        description: "Il s'ouvre et se ferme de façon volontaire pour la respiration. On peut parfois apercevoir son souffle en surface."
                    },
                    {
                        part: AnatomyPart.Melon,
                        title: 'Melon',
                        description: "C'est grâce au melon que le Grand dauphin émet les clics utiles pour l'écholocation."
                    },
                    {
                        part: AnatomyPart.Bouche,
                        title: 'Bouche',
                        description: "Sa forme en sourire a longtemps fait penser qu'il était heureux même en captivité. Alors que c'est juste la forme anatomique de sa bouche."
                    },
                    {
                        part: AnatomyPart.Fentes,
                        title: 'Fentes mammaires',
                        description: "La femelle s'en sert pour allaiter son petit."
                    },
                    {
                        part: AnatomyPart.SillonGA,
                        title: 'Fentes génito-anal',
                        description: "Chez la femelle, la fente génitale et l'anus sont très proches et encadrés de deux fentes mammaires. Chez le mâle, la fente génitale et l’anus sont bien séparés."
                    },
                ]
            },
            {
                id: "4",
                cetaceanName: "La Baleine à bec de Gervais",
                meshName: "Cuvier",
                category: "SmallMedCetaceans",
                zoom: 20,
                anatomyParts: [
                    {
                        part: AnatomyPart.Tete,
                        title: 'Têtes',
                        description: "La tête est claire avec un creux derrière l'évent."
                    },
                    {
                        part: AnatomyPart.Caudale,
                        title: 'Nageoire caudale',
                        description: "Elle est très puissante et lui sert à se propulser."
                    },
                    {
                        part: AnatomyPart.Dorsale,
                        title: 'Nageoire dorsale',
                        description: "La nageoire dorsale est petite et placée en arrière."
                    },
                    {
                        part: AnatomyPart.Oeil,
                        title: 'Oeil',
                        description: "Elle a une bonne vue, aussi bien sous l’eau qu'à la surface."
                    },
                    {
                        part: AnatomyPart.Event,
                        title: 'Évent',
                        description: "La Baleine à bec de Cuvier est la championne d’apnée, pouvant rester 2 heures sous l'eau sans ouvrir son évent pour respirer."
                    },
                ]
            },
            {
                id: "5",
                cetaceanName: "Le Dauphin de Fraser",
                meshName: "Fraser",
                category: "SmallMedCetaceans",
                zoom: 8,
                anatomyParts: [
                    {
                        part: AnatomyPart.Pectorales,
                        title: 'Nageoires pectorales',
                        description: "Elles sont utilisées pour la stabilisation."
                    },
                    {
                        part: AnatomyPart.Caudale,
                        title: 'Nageoires caudales',
                        description: "Elle est très puissante et lui sert à se propulser."
                    },
                    {
                        part: AnatomyPart.Dorsale,
                        title: 'Nageoire dorsale',
                        description: "C'est grâce à une photo de sa nageoire dorsale que l'on peut identifier les différents individus."
                    },
                    {
                        part: AnatomyPart.Oeil,
                        title: 'Oeil',
                        description: "Il a une bonne vue, aussi bien sous l’eau qu'à la surface."
                    },
                    {
                        part: AnatomyPart.Rostre,
                        title: 'Rostre',
                        description: "Le Dauphin de Fraser a le rostre très court."
                    },
                    {
                        part: AnatomyPart.VentreRose,
                        title: 'Ventre Rose',
                        description: "Avec son ventre rose et son rostre court, on le surnomme le Marsouin cochon !"
                    },
                ]
            },
            {
                id: "6",
                cetaceanName: "Le Dauphin tacheté pantropical",
                meshName: "Pantropicale",
                category: "SmallMedCetaceans",
                zoom: 8,
                anatomyParts: [
                    {
                        part: AnatomyPart.Caudale,
                        title: 'Nageoire caudale',
                        description: "Elle est très puissante et lui sert à se propulser."
                    },
                    {
                        part: AnatomyPart.Dorsale,
                        title: 'Nageoire dorsale',
                        description: "C'est grâce à une photo de sa nageoire dorsale que l'on peut identifier les différents individus."
                    },
                    {
                        part: AnatomyPart.Oeil,
                        title: 'Oeil',
                        description: "Ses yeux ne fabriquent pas de larmes mais sont protégés par une huile sécrétée par des glandes situées près des paupières."
                    },
                    {
                        part: AnatomyPart.Rostre,
                        title: 'Rostre',
                        description: "Le rostre blanchit avec l'âge."
                    },
                    {
                        part: AnatomyPart.Taches,
                        title: 'Tâches',
                        description: "Les nouveau-nés ne sont pas tachetés. La densité des taches varie suivant l'âge."
                    },
                ]
            },
            {
                id: "7",
                cetaceanName: "Le Pseudorque",
                meshName: "Pseudorque",
                category: "SmallMedCetaceans",
                zoom: 20,
                anatomyParts: [
                    {
                        part: AnatomyPart.Tete,
                        title: 'Tête',
                        description: "Sa tête est en forme d'obus."
                    },
                    {
                        part: AnatomyPart.Pectorales,
                        title: 'Nageoires pectorales',
                        description: "Elles sont utilisées pour la stabilisation. Elles sont assez courtes et coudées."
                    },
                    {
                        part: AnatomyPart.Caudale,
                        title: 'Nageoire caudale',
                        description: "Elle est très puissante et lui sert à se propulser."
                    },
                    {
                        part: AnatomyPart.Dorsale,
                        title: 'Nageoire dorsale',
                        description: "C'est grâce à une photo de sa nageoire dorsale que l'on peut identifier les différents individus."
                    },
                    {
                        part: AnatomyPart.Oeil,
                        title: 'Oeil',
                        description: "Il a une bonne vue, aussi bien sous l’eau qu'à la surface."
                    },
                    {
                        part: AnatomyPart.Event,
                        title: 'Évent',
                        description: "Il s'ouvre et se ferme de façon volontaire pour la respiration."
                    },
                    {
                        part: AnatomyPart.Melon,
                        title: 'Melon',
                        description: "Il est très utile pour l'écholocation. Il sert de lentille acoustique et concentre les ondes sonores en faisceau qui se dirige alors devant le cétacé."
                    },
                    {
                        part: AnatomyPart.Bouche,
                        title: 'Bouche',
                        description: "Sans rostre (ou bec)."
                    },
                ]
            },
            {
                id: "8",
                cetaceanName: "L'Orque",
                meshName: "Orca",
                category: "SmallMedCetaceans",
                zoom: 20,
                anatomyParts: [
                    {
                        part: AnatomyPart.Pectorales,
                        title: 'Nageoires pectorales',
                        description: "Elles sont tellement puissantes que l'Orque peut assommer un poisson avec ses nageoires pectorales et caudale."
                    },
                    {
                        part: AnatomyPart.Caudale,
                        title: 'Nageoire caudale',
                        description: "Elle est très puissante et lui sert à se propulser."
                    },
                    {
                        part: AnatomyPart.Dorsale,
                        title: 'Nageoire dorsale',
                        description: "Dans un groupe d'Orques, on distingue facilement les mâles qui ont les plus grandes nageoires dorsales, jusqu'à 2 mètres."
                    },
                    {
                        part: AnatomyPart.Oeil,
                        title: 'Oeil',
                        description: "Noir sur noir, on ne le voit presque pas !"
                    },
                    {
                        part: AnatomyPart.Event,
                        title: 'Évent',
                        description: "L'Orque est un odontocète, il n'a qu'un seul évent (narine) qui lui sert à respirer."
                    },
                    {
                        part: AnatomyPart.Fentes,
                        title: 'Fentes mammaires',
                        description: "La femelle s'en sert pour allaiter son petit."
                    },
                    {
                        part: AnatomyPart.SillonGA,
                        title: 'Sillon génito-anal',
                        description: "Chez la femelle, la fente génitale et l'anus sont très proches et encadrés de deux fentes mammaires. Chez le mâle, la fente génitale et l’anus sont bien séparés."
                    },
                    {
                        part: AnatomyPart.Melon,
                        title: 'Melon',
                        description: "C'est grâce au melon que l'Orque émet les clics utiles pour l'écholocation."
                    },
                ]
            },
            {
                id: "9",
                cetaceanName: "Le Sténo rostré",
                meshName: "Steno",
                category: "SmallMedCetaceans",
                zoom: 8,
                anatomyParts: [
                    {
                        part: AnatomyPart.Pectorales,
                        title: 'Nageoires pectorales',
                        description: "Elles sont utilisées pour la stabilisation."
                    },
                    {
                        part: AnatomyPart.Caudale,
                        title: 'Nageoire caudale',
                        description: "Elle est très puissante et lui sert à se propulser."
                    },
                    {
                        part: AnatomyPart.Dorsale,
                        title: 'Nageoire dorsale',
                        description: "C'est grâce à une photo de sa nageoire dorsale que l'on peut identifier les différents individus."
                    },
                    {
                        part: AnatomyPart.Oeil,
                        title: 'Oeil',
                        description: "On le reconait à ses grands yeux. Il a une bonne vue, aussi bien sous l’eau qu'à la surface."
                    },
                    {
                        part: AnatomyPart.Event,
                        title: 'Évent',
                        description: "Il s'ouvre et se ferme de façon volontaire pour la respiration."
                    },
                    {
                        part: AnatomyPart.Melon,
                        title: 'Melon',
                        description: "Il est très utile pour l'écholocation. Il sert de lentille acoustique et concentre les ondes sonores en faisceau qui se dirigent alors devant le cétacé."
                    },
                    {
                        part: AnatomyPart.Rostre,
                        title: 'Rostre',
                        description: "Son nom 'sténo' signifie en grec 'étroit' et désigne la forme particulière de son rostre ou bec."
                    },
                ]
            },
            {
                id: "10",
                cetaceanName: "Le globicéphale",
                meshName: "Globi",
                category: "SmallMedCetaceans",
                zoom: 20,
                anatomyParts: [
                    {
                        part: AnatomyPart.Tete,
                        title: 'Tête',
                        description: "La tête est volumineuse avec un front en forme de bulbe et une bouche petite et presque sans bec."
                    },
                    {
                        part: AnatomyPart.Pectorales,
                        title: 'Nageoires pectorales',
                        description: "Elles sont falciformes, c'est à dire courbées. "
                    },
                    {
                        part: AnatomyPart.Caudale,
                        title: 'Nageoire caudale',
                        description: "Elle est très puissante et lui sert à se propulser."
                    },
                    {
                        part: AnatomyPart.Dorsale,
                        title: 'Nageoire dorsale',
                        description: "Les femelles ont un aileron plus falciforme et moins massif que les mâles."
                    },
                    {
                        part: AnatomyPart.Melon,
                        title: 'Melon',
                        description: "C'est son melon en forme de globe qui lui a donné son nom. Dans les Antilles, on l'appelle aussi le Marsouin calebasse."
                    },
                    {
                        part: AnatomyPart.SillonGA,
                        title: 'Sillon génito-anal',
                        description: "Chez la femelle, la fente génitale et l'anus sont très proches et encadrés de deux fentes mammaires. Chez le mâle, la fente génitale et l’anus sont bien séparés."
                    },
                    {
                        part: AnatomyPart.Bouche,
                        title: 'Bouche',
                        description: "Il n'a presque pas de museau ou rostre."
                    },
                ]
            }
        ]
    }
    
    ngAfterContentInit(): void
    {
        if (this.rendererCanvas !== undefined)
        {
            let ceta = this.cetaDefs.find(x=> x.id === this.cetaceanId);
            if (ceta && ceta.meshName)
            {
                this.cetaDefSelected = ceta;
                this.ceta3d.createScene(this.rendererCanvas, this.cetaDefSelected.meshName, this.cetaDefSelected.zoom);
                this.ceta3d.animate();
            }
        }
        else
        {
            console.log("RendererCanvas for 3D Anatomy is undefined");
        }
    }

    onResize(event : any): void
    {
        this.ceta3d.resizeEngine();
    }

    onAnatomyClicked(anatomy: Anatomy): void
    {
        if (this.cetaDefSelected)
        {
            this.cetaAnatomySelected = anatomy;
            this.ceta3d.showAnatomy(this.cetaDefSelected.meshName, this.cetaDefSelected?.category, this.cetaAnatomySelected.part);
        }
    }
}
