<div class="container-fluid background-cetacean">

    <app-presentation title="Nos activités à imprimer" [content]="introContent" [useFlag]="true"></app-presentation>

</div>

<div id="stickyFilter" class="sticky-top bg-blue-light overflow-hidden">

    <div class="container" [ngClass]="isFilterPanelOpen ? 'anim-filter-open' : 'anim-filter-close'">
        <div class="row align-items-center filter-bar">
            <div class="col-6 typo_6" *ngIf="paperActivities">
                {{ paperActivities.length }} activités trouvées
            </div>
            <div class="col-6 d-flex flex-row justify-content-end">
                <button *ngIf="!isFilterPanelOpen" class="standardButton typo_8" (click)="onOpenCloseFilterClick()">Filtre & Tri</button>
                <button *ngIf="isFilterPanelOpen" class="standardButton typo_8" (click)="onStartFilterClick()">OK</button>
                <button *ngIf="isFilterPanelOpen" class="standardButton typo_8 ms-4" (click)="onClearFilterClick()">Effacer</button>
            </div>
        </div>

        <div *ngIf="isFilterPanelOpen" class="row text-center mt-24r">
            <div class="col-5 typo_2 d-flex flex-column align-items-center">
                <div class="mb-8r">Activités</div>
                <img class="separator" src="../../../assets/images/Separator_Med_Green.svg" />
            </div>
            <div class="col-5 typo_2 d-flex flex-column align-items-center">
                <div class="mb-8r">Sujets</div>
                <img class="separator" src="../../../assets/images/Separator_Med_Blue.svg" />
            </div>
            <div class="col-2 typo_2 d-flex flex-column align-items-center">
                <div class="mb-8r">Trier par</div>
                <img class="separator" src="../../../assets/images/Separator_Med_Yellow.svg" />
            </div>
        </div>
        
        <div *ngIf="isFilterPanelOpen" class="row mt-24r mb-48r">
            <div class="col-5 text-center" *ngIf="activityTypes">
                <ng-container *ngFor="let filterTypes of activityTypes">
                    <btnfilter [color]='2' [name]="filterTypes.name" [isSelected]="filterTypes.selected" (click)="onFilterTypeClick(filterTypes.id)"></btnfilter>
                </ng-container>
            </div>

            <div class="col-5 text-center" *ngIf="activitySubjects">
                <ng-container *ngFor="let filterSubjects of activitySubjects">
                    <btnfilter [color]='1' [name]="filterSubjects.name" [isSelected]="filterSubjects.selected" (click)="onFilterSubjectClick(filterSubjects.id)"></btnfilter>
                </ng-container>
            </div>

            <div class="col-2 text-center">
                <btnfilter [color]='0' name="Plus difficile" [isSelected]="!sortDifficultyIncrease" [useCross]="false" (click)="onDifficultyChanged(false)"></btnfilter>
                <btnfilter [color]='0' name="Moins difficile" [isSelected]="sortDifficultyIncrease" [useCross]="false" (click)="onDifficultyChanged(true)"></btnfilter>
                <div class="typo_10_A mt-8r">* Les difficultés vont de {{ sortDifficultyIncrease ? "1 à 5" : "5 à 1" }}</div>
            </div>
        </div>
    
    </div>

    <separator-color></separator-color>
    
</div>

<div class="container-fluid bg-blue-dark pt-48r pb-128r">
    <div class="container">
        <div class="row" id="scrollTarget">

            <app-card-type-a *ngFor="let activity of paperActivities; let i = index" class="col-xs-12 col-lg-3 g-0" 
                [idCard]="i"
                [name]="activity.name"
                [subname]="activity.activity_type ? activity.activity_type.name : ''"
                [description]="activity.description"
                [difficulty]="activity.activity_difficulty ? activity.activity_difficulty.difficulty : '?'"
                [urlImage]="activity.image ? backendUrl + activity.image.url : ''"
                [urlActivityFr]="activity.pdf_fr ? backendUrl + activity.pdf_fr.url : ''"
                [urlActivityEn]="activity.pdf_en ? backendUrl + activity.pdf_en.url : ''"
                [urlActivityEs]="activity.pdf_es ? backendUrl + activity.pdf_es.url : ''"
                [urlCorrectionFr]="activity.correction_fr ? backendUrl + activity.correction_fr.url : ''"
                [urlCorrectionEn]="activity.correction_en ? backendUrl + activity.correction_en.url : ''"
                [urlCorrectionEs]="activity.correction_es ? backendUrl + activity.correction_es.url : ''">
            </app-card-type-a>
            
        </div>
    </div>
</div>