
<div class="w-100">
    <div class="container-fluid">
        <div class="row">

            <div class="col blueline"></div>
            <div class="col yellowline"></div>
            <div class="col turquoiseline"></div>
            
            <div class="col blueline"></div>
            <div class="col yellowline"></div>
            <div class="col turquoiseline"></div>
            
            <div class="col blueline"></div>
            <div class="col yellowline"></div>
            <div class="col turquoiseline"></div>
        </div>
    </div>
</div>