import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-type-c',
  templateUrl: './card-type-c.component.html',
  styleUrls: ['./card-type-c.component.scss']
})
export class CardTypeCComponent
{
    @Input() name: string = '';
    @Input() subname: string = '';
    @Input() description: string = '';
    
    @Input() urlImageStandard: string = '';
    @Input() urlImageHover: string = '';
}
