import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-content-double',
  templateUrl: './content-double.component.html',
  styleUrls: ['./content-double.component.scss']
})
export class ContentDoubleComponent
{
    @Input() verticalAlign: string = 'top';
}
