

import gql from "graphql-tag";

export interface Tuto
{
    title: string;
    description: string;

    videoUrl: string;
}

export interface Tutos
{
    tutos: Tuto[];
}

export const FIND_TUTOS = gql`
query Tutos
{
    tutos
    {
        title
        description
        videoUrl
    }
}`;