import { CountNewsResponse, COUNT_NEWS, FIND_NEWS, New, News } from '@/apollo/queries/news/news';
import { Settings } from '@/settings';
import { Component, OnInit } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { forkJoin, Subscription } from 'rxjs';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit
{
    backendUrl: string = Settings.backEndUrl;

    news: New[] | undefined;
    newStep = 8;
    newLimit = 0;
    maxNews: number = 0;

    watchNewsSubscription : Subscription | undefined;

    constructor(private apollo: Apollo)
    {
    }

    ngOnInit(): void
    {
        this.apollo.query<CountNewsResponse>({ query: COUNT_NEWS }).subscribe(result => {
            this.maxNews = result.data.countNews;
        });
        
        this.loadMore();
    }

    loadMore(): void
    {
        this.newLimit += this.newStep;

        // News
        this.watchNewsSubscription = this.apollo.watchQuery<News>({
            query: FIND_NEWS,
            variables:
            {
                limit: this.newLimit,
            },
        })
        .valueChanges.subscribe(result => 
        {
            this.news = result.data.news;
        });
    }

    onLoadMoreClicked()
    {
        this.loadMore();
    }
}
