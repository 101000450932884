
import gql from "graphql-tag";
import { ActivityDifficulty, ActivitySubject, ActivityType } from "../activity-filters/activity-filters";
import { ImageMedia, PdfMedia } from "../general/general";

export interface PaperActivity
{
    name: string;
    description: string;

    image: ImageMedia;
    activity_type: ActivityType;
    activity_difficulty: ActivityDifficulty;
    activity_subjects: ActivitySubject[];

    pdf_fr: PdfMedia;
    pdf_en: PdfMedia;
    pdf_es: PdfMedia;
    correction_fr: PdfMedia;
    correction_en: PdfMedia;
    correction_es: PdfMedia;
}

export interface PaperActivitiesResponse
{
    getPaperActivityFiltered: PaperActivity[];
}

export const GET_PAPER_ACTIVITIES_FILTERED = gql`
query PaperActivities($activityTypes: [ID!], $activitySubjects: [ID!], $sortDifficultyIncrease: Boolean)
{
    getPaperActivityFiltered(activityTypes: $activityTypes, activitySubjects: $activitySubjects, sortDifficultyIncrease: $sortDifficultyIncrease)
}`;