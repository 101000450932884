
<div class="container" id="faq">

    <div *ngFor="let qanda of qandaList; let i = index; let last = last" [ngClass]="{'mb-16r': !last}">
        <div class="d-flex flex-row align-items-center justify-content-between faqUnderline pb-8r " [ngClass]="{'mb-8r': !last}" (click)="onAandQClick(i)">
            <div class="typo_3 cursor-pointer">{{ qanda.question }}</div>
            <arrow [orientation]="qanda.isOpen ? 'up' : 'down'" strokeColor="stroke-blue-dark"></arrow>
        </div>
        <div class="typo_4 retract" [ngClass]="{'extract': qanda.isOpen}">
            {{ qanda.answer }}
        </div>
    </div>

</div>