
import gql from "graphql-tag";

export interface Friend
{
    name: string;
    link: string;
    description: string;
}

export interface Friends
{
    friends: Friend[];
}

export const FIND_FRIENDS = gql`
query Friends
{
    friends
    {
        name
        link
        description
    }
}`;