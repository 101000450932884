import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-bg-adaptable',
  templateUrl: './bg-adaptable.component.html',
  styleUrls: ['./bg-adaptable.component.scss']
})
export class BgAdaptableComponent implements OnInit
{
    useImage: boolean = false;

    @Input() urlImage: string | undefined;
    @Input() bgColor: string = "bg-blue-light";
    @Input() bgPosition: string = "bgCenter";
    @Input() minHeight: string = '512px';

    constructor()
    {
    }
    
    ngOnInit(): void
    {
        this.useImage = this.urlImage != undefined;
    }
}
