

import gql from "graphql-tag";
import { ImageMedia } from "../general/general";

export interface NewAlignement
{
    alignement: string;
}

export interface NewBackgroundColor
{
    backgroundColor: string;
}

export interface NewRow
{
    title: string;
    description: string;
    linkName: string;
    linkUrl: string;
    media: ImageMedia;
    embedMedia: string;
    new_align: NewAlignement;
}

export interface NewSection
{
    new_background_color: NewBackgroundColor;

    NewRow: NewRow[];
}

export interface New
{
    id: string;
    title: string;
    creationDate: Date;

    primeImage: ImageMedia;
    gallery: ImageMedia[];

    newSection: NewSection[];
}

export interface News
{
    news: New[];
}

export interface NewResponse
{
    new: New;
}

export interface CountNewsResponse
{
    countNews: number;
}

export interface GetNewAndNeighbourResponse
{
    getNewAndNeighbour: New[];
}

export const FIND_NEWS = gql`
query News($limit: Int!)
{
    news(limit: $limit, sort: "creationDate:desc")
    {
        id
        title
        creationDate
        primeImage
        {
            url
        }
    }
}`;

export const COUNT_NEWS = gql`
query CountNews
{
    countNews
}`;

export const GET_NEW_AND_NEIGHBOUR = gql`
query GetNewAndNeighbour($newId: ID!)
{
    getNewAndNeighbour(newId: $newId)
}`;