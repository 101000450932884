

import gql from "graphql-tag";
import { ActivityDifficulty } from "../activity-filters/activity-filters";
import { ImageMedia, PdfMedia } from "../general/general";

export interface VariousActivity
{
    title: string;
    description: string;

    image: ImageMedia;
    activity_difficulty: ActivityDifficulty;
    
    pdf_fr: PdfMedia;
    pdf_en: PdfMedia;
    pdf_es: PdfMedia;
}

export interface VariousActivities
{
    variousActivities: VariousActivity[];
}

export const FIND_VARIOUS_ACTIVITIES = gql`
query VariousActivity
{
    variousActivities
    {
        title
        description

        image { url }
        pdf_fr { url }
        pdf_en { url }
        pdf_es { url }
        
        activity_difficulty
        {
            difficulty
        }
    }
}`;