import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'flags',
  templateUrl: './flags.component.html',
  styleUrls: ['./flags.component.scss']
})
export class FlagsComponent
{
    @Input() useInline: boolean = false;
}
