
import { FIND_TUTOS, Tuto, Tutos } from '@/apollo/queries/tutos/tutos';
import { Settings } from '@/settings';
import { Component, OnInit } from '@angular/core';
import { Apollo } from 'apollo-angular';

@Component({
  selector: 'app-tutos',
  templateUrl: './tutos.component.html',
  styleUrls: ['./tutos.component.scss']
})
export class TutosComponent implements OnInit
{
    introContent: string;
    backendUrl: string = Settings.backEndUrl;

    tutos: Tuto[] | undefined;

    constructor(private apollo: Apollo)
    {
        this.introContent = "Voici des tutos vidéos et guides écrits pour vous aider à utiliser nos outils disponibles dans plusieurs langues et pour différents publics.<br><br>Here are video tutorials and guides written to help you use our tools available in several languages and for different audiences.<br><br>Aquí hay guías y tutoriales en video escritos para ayudarlo a usar nuestras herramientas disponibles en varios idiomas y para diferentes públicos.";
    }

    ngOnInit(): void
    {
        // Tutos
        this.apollo.query<Tutos>({ query: FIND_TUTOS }).subscribe(result => 
        {
            this.tutos = result.data.tutos;
        });
    }
}
