
import gql from "graphql-tag";

export interface EmployeeType
{
    id: string;
    suffix: string;
}

export interface Photo
{
    url: string;
}

export interface Employee
{
    firstname: string;
    lastname: string;
    description: string;
    function: string;

    order: number;

    photo_adult: Photo;
    photo_child: Photo;
    employee_type: EmployeeType;
}

export interface Employees
{
    employees: Employee[];
}

export const FIND_EMPLOYEES = gql`
query Employees
{
    employees(sort: "order:asc")
    {
        firstname
        lastname
        description
        function

        photo_adult
        {
            url
        }
        photo_child
        {
            url
        }

        employee_type
        {
            id
            suffix
        }

        order
    }
}`;