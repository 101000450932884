<div class="container-fluid background-cetacean">

    <app-presentation title="Nos vidéos" [content]="introContent"></app-presentation>

</div>

<separator-color></separator-color>
    
<ng-container *ngFor="let video of videos; let i=index">

    <app-bg-adaptable bgColor="{{ i % 2 === 0 ? 'bg-blue-medium' : 'bg-blue-light' }}">

        <separator></separator>
    
        <ng-container *ngIf="i % 2 === 0">
            <app-content-double verticalAlign="center">
                <div content-left>
            
                    <app-subtitle-txt [bgDisableInLargeScreen]="true">
                        <div title>{{ video.title }}</div>
                        <div content class="d-flex flex-column align-items-center">
                            <div class="mb-48r pre-line color-blue-light">{{ video.description }}</div>
                        </div>
                    </app-subtitle-txt>
                    
                </div>
                <div content-right>
                    <div class="iframe-parent"><iframe class="position-absolute top-0 start-0 w-100 h-100" [src]="video.videoUrl | safe"></iframe></div>
                </div>
            </app-content-double>
        </ng-container>
        <ng-container *ngIf="i % 2 !== 0">
            <app-content-double verticalAlign="center">
                <div content-right>
            
                    <app-subtitle-txt [bgDisableInLargeScreen]="true">
                        <div title>{{ video.title }}</div>
                        <div content class="d-flex flex-column align-items-center">
                            <div class="mb-48r pre-line">{{ video.description }}</div>
                        </div>
                    </app-subtitle-txt>
                    
                </div>
                <div content-left>
                    <div class="iframe-parent"><iframe class="position-absolute top-0 start-0 w-100 h-100" [src]="video.videoUrl | safe"></iframe></div>
                </div>
            </app-content-double>
        </ng-container>
        
        <separator heightRem="4"></separator>
    
    </app-bg-adaptable>
    
</ng-container>