import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from '@/app-routing.module';
import { GraphQLModule } from '@/graphql.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MainComponent } from '@/pages/main/main.component';
import { MenuComponent } from './components/menu/menu.component';
import { HeaderComponent } from './components/header/header.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HomeComponent } from './pages/home/home.component';
import { CetaceanComponent } from './pages/cetacean/cetacean.component';
import { CetaAnatomyComponent } from './components/ceta-anatomy/ceta-anatomy.component';
import { OurToolsComponent } from './pages/our-tools/our-tools.component';
import { AssociationComponent } from './pages/association/association.component';
import { NewsComponent } from './pages/news/news.component';
import { HelpUsComponent } from './pages/help-us/help-us.component';
import { FooterFollowUsComponent } from './components/footer/footer-follow-us/footer-follow-us.component';
import { FooterPagesListComponent } from './components/footer/footer-pages-list/footer-pages-list.component';
import { FooterCopyrightComponent } from './components/footer/footer-copyright/footer-copyright.component';
import { FooterUnderstandComponent } from './components/footer/footer-understand/footer-understand.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { NewsletterComponent } from './pages/newsletter/newsletter.component';
import { JojosousleauComponent } from './pages/jojosousleau/jojosousleau.component';
import { StoriesComponent } from './pages/stories/stories.component';
import { PaperActivitiesComponent } from './pages/paper-activities/paper-activities.component';
import { OurGamesComponent } from './pages/our-games/our-games.component';
import { GeneralAntillesComponent } from './pages/general-antilles/general-antilles.component';
import { GeneralCetaceansComponent } from './pages/general-cetaceans/general-cetaceans.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { BtnctaComponent } from './components/btncta/btncta.component';
import { IntroComponent } from './components/intro/intro.component';
import { OrganizerComponent } from './components/organizer/organizer.component';
import { TitleComponent } from './components/title/title.component';
import { QuizComponent } from './components/quiz/quiz.component';
import { NewComponent } from './pages/new/new.component';
import { ArrowComponent } from './components/arrow/arrow.component';
import { PresentationComponent } from './components/presentation/presentation.component';
import { FaqComponent } from './components/faq/faq.component';
import { BtnfilterComponent } from './components/btnfilter/btnfilter.component';
import { SpecificitiesComponent } from './components/specificities/specificities.component';
import { CetaceansComponent } from './pages/cetaceans/cetaceans.component';
import { BgFluidComponent } from './components/bg-fluid/bg-fluid.component';
import { MembadminComponent } from './pages/membadmin/membadmin.component';
import { MembadminNewsletterComponent } from './pages/membadmin-newsletter/membadmin-newsletter.component';
import { ContentDoubleComponent } from './components/content-double/content-double.component';
import { ContentSingleCenterComponent } from './components/content-single-center/content-single-center.component';
import { SubtitleTxtComponent } from './components/subtitle-txt/subtitle-txt.component';
import { BgAdaptableComponent } from './components/bg-adaptable/bg-adaptable.component';
import { SeparatorComponent } from './components/separator/separator.component';
import { ContentSingleButtonComponent } from './components/content-single-button/content-single-button.component';
import { VoteComponent } from './components/vote/vote.component';
import { CardTypeAComponent } from './components/card-type-a/card-type-a.component';
import { CardTypeBComponent } from './components/card-type-b/card-type-b.component';
import { CardTypeCComponent } from './components/card-type-c/card-type-c.component';
import { CardTypeDComponent } from './components/card-type-d/card-type-d.component';
import { SubmenuComponent } from './components/submenu/submenu.component';
import { SafePipe } from './pipe/safepipe';
import { CardTypeEComponent } from './components/card-type-e/card-type-e.component';

import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { SeparatorColorComponent } from './components/separator-color/separator-color.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { TutosComponent } from './pages/tutos/tutos.component';
import { VideosComponent } from './pages/videos/videos.component';
import { FlagsComponent } from './components/flags/flags.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';

registerLocaleData(localeFr);

@NgModule({
    declarations: [

        // Main
        MainComponent,

        // Pages
        HomeComponent,
        PageNotFoundComponent,
        CetaceansComponent,
        CetaceanComponent,

        GeneralAntillesComponent,
        GeneralCetaceansComponent,

        OurToolsComponent,
        StoriesComponent,
        PaperActivitiesComponent,
        JojosousleauComponent,
        OurGamesComponent,
        AssociationComponent,

        NewsComponent,
        HelpUsComponent,
        ContactUsComponent,
        NewsletterComponent,

        MembadminComponent,
        MembadminNewsletterComponent,

        // Composants
        HeaderComponent,
        MenuComponent,
        CetaAnatomyComponent,
        BtnctaComponent,
        ArrowComponent,
        IntroComponent,
        PresentationComponent,
        OrganizerComponent,
        TitleComponent,
        QuizComponent,
        NewComponent,
        FaqComponent,
        BtnfilterComponent,
        SpecificitiesComponent,
        BgFluidComponent,
        ContentDoubleComponent,
        ContentSingleCenterComponent,
        ContentSingleButtonComponent,
        SubtitleTxtComponent,
        BgAdaptableComponent,
        SeparatorComponent,
        SeparatorColorComponent,
        VoteComponent,
        CardTypeAComponent,
        CardTypeBComponent,
        CardTypeCComponent,
        CardTypeDComponent,
        CardTypeEComponent,
        SubmenuComponent,
        CarouselComponent,

        // Footer
        FooterFollowUsComponent,
        FooterPagesListComponent,
        FooterUnderstandComponent,
        FooterCopyrightComponent,

        SafePipe,

        TutosComponent,

        VideosComponent,

        FlagsComponent,

        PrivacyComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        GraphQLModule,
        HttpClientModule,
        ReactiveFormsModule,
        FormsModule,
        NgbModule
    ],
    providers: [
        { provide: LOCALE_ID, useValue: "fr-FR" }      
    ],
    bootstrap: [
        MainComponent
    ]
})
export class AppModule { }
