
<div class="container-fluid bg-vote py-96r">
    <div class="container">
        <div class="row mb-96r">
            <div class="col-12 typo_1 text-white text-center">À vous de voter !</div>
        </div>
        <div class="row mb-48r">
            <div class="col-12 typo_3 text-white text-center">Il reste <b>{{ daysRemaining }} jours</b> pour voter</div>
        </div>
        <div class="row">
            <div class="col-5 offset-1">
                <div class="typo_3 text-white text-center">
                    Trois nouveaux coloriages
                </div>
            </div>
            <div class="col-5">
                <div class="typo_3 text-white text-center">
                    Les nouvelles aventures de Léa et Mathieux
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-5 offset-1">
                <div class="position-relative d-flex flex-column">
                    <img class="vote" src="../../../assets/images/vote_top.svg" />
                    
                    <img class="position-absolute vote-label" src="../../../assets/images/vote_label.svg" />
                    <img class="position-absolute vote-shadow" src="../../../assets/images/vote_shadow.svg" />
                </div>
                <div class="d-flex flex-column">
                    <img class="vote" src="../../../assets/images/vote_bottom.svg" />
                </div>

            </div>
            <div class="col-5">

            </div>
        </div>
    </div>
</div>