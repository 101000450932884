import { Component, Input, OnInit } from '@angular/core';
import { FaqComponent } from '../faq/faq.component';

@Component({
  selector: 'app-card-type-a',
  templateUrl: './card-type-a.component.html',
  styleUrls: ['./card-type-a.component.scss']
})
export class CardTypeAComponent implements OnInit
{
    @Input() name: string = '';
    @Input() subname: string = '';
    @Input() description: string = '';
    @Input() difficulty: string = '';

    @Input() urlImage: string = '';
    @Input() urlActivityFr: string = '';
    @Input() urlActivityEn: string = '';
    @Input() urlActivityEs: string = '';
    @Input() urlCorrectionFr: string = '';
    @Input() urlCorrectionEn: string = '';
    @Input() urlCorrectionEs: string = '';

    @Input() idCard: number = 0;
    @Input() scienceBubbleAlternative: boolean = false;

    isOverCard: boolean = false;
    pouet: string = '';

    constructor()
    {
    }

    ngOnInit(): void
    {
    }

    onActivityFrClicked()
    {
        window.open(this.urlActivityFr, "_blank");
    }
    onActivityEnClicked()
    {
        window.open(this.urlActivityEn, "_blank");
    }
    onActivityEsClicked()
    {
        window.open(this.urlActivityEs, "_blank");
    }
    onCorrectionFrClicked()
    {
        window.open(this.urlCorrectionFr, "_blank");
    }
    onCorrectionEnClicked()
    {
        window.open(this.urlCorrectionEn, "_blank");
    }
    onCorrectionEsClicked()
    {
        window.open(this.urlCorrectionEs, "_blank");
    }
}
