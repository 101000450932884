
export enum AnatomyPart
{
    Tubercules = 'Tubercules',
    Pectorales = 'Pectorales',
    Caudale = 'Caudale',
    Dorsale = 'Dorsale',
    Oeil = 'Oeil',
    Event = 'Event',
    Bosse = 'Bosse',
    Fanons = 'Fanons',
    SillonsGulaires = 'SillonsGulaires',
    Fentes = 'Fentes',
    Spermaceti = 'Spermaceti',
    Bouche = 'Bouche',
    Melon = 'Melon',
    Rostre = 'Rostre',
    SillonGA = 'SillonGA',
    Taches = 'Taches',
    Tete = 'Tete',
    VentreRose = 'VentreRose',
    Dents = "Dents",
}

export interface Anatomy
{
    part: AnatomyPart,
    title: string,
    description: string,
}

export interface CetaceanDef
{
    id: string,
    cetaceanName: string,
    meshName: string,
    category: string,
    zoom: number,

    anatomyParts: Anatomy[]
}