import { Organizer } from '@/models/organizer';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-organizer',
  templateUrl: './organizer.component.html',
  styleUrls: ['./organizer.component.scss']
})
export class OrganizerComponent implements OnInit
{
    @Input() organizer: Organizer | undefined;
    
    @Input() color: string = "color-blue-dark";
    @Input() bgColor: string = "bg-blue-light";
    @Input() bgDisableInLargeScreen: boolean = false;

    constructor()
    {
    }
    
    ngOnInit(): void
    {
    }
}
