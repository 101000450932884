

import gql from "graphql-tag";
import { ImageMedia, PdfMedia } from "../general/general";
import { Quiz } from "../quiz/quiz";

export interface CetaceanBehaviorType
{
    name: string;
    icon: ImageMedia;
}

export interface Behavior
{
    cetacean_behavior_type: CetaceanBehaviorType;
    description: string;
}

export interface Didyouknow
{
    description: string;
}

export interface Specificity
{
    quantity: string;
    name: string;
    description: string;
}

export interface Cetacean
{
    id: string;

    commonName: string;
    scientificName: string;
    familyName: string;
    UICN: string;

    sentenceIntro: string;
    sentenceWhereToFindIt: string;

    primeImage: ImageMedia;
    backgroundImage: ImageMedia[];
    galleryImage: ImageMedia[];
    
    paperToPrintFr: PdfMedia;
    paperToPrintEn: PdfMedia;
    paperToPrintEs: PdfMedia;

    behaviors: Behavior[];
    quizzes: Quiz[];
    didyouknows: Didyouknow[];
    specificities: Specificity[];
}

export interface Cetaceans
{
    cetaceans: Cetacean[];
}

export interface CetaceanResponse
{
    cetacean: Cetacean;
}

export const FIND_CETACEANS = gql`
query Cetaceans
{
    cetaceans
    {
        id

        commonName
        primeImage
        {
            url
        }
    }
}`;

export const FIND_CETACEAN = gql`
query Cetacean($cetaceanId: ID!)
{
    cetacean(id: $cetaceanId)
    {
        id
        commonName
        scientificName
        familyName
        UICN

        sentenceIntro
        sentenceWhereToFindIt

        primeImage
        {
            url
        }
        backgroundImage
        {
            url
        }
        galleryImage
        {
            url
            caption
        }
        paperToPrintFr
        {
            url
        }
        paperToPrintEn
        {
            url
        }
        paperToPrintEs
        {
            url
        }
        behaviors
        {
            cetacean_behavior_type
            {
                name
                icon
                {
                    url
                }
            }
            description
        }
        quizzes
        {
            question
            answer1
            answer2
            answer3
            answer4
            resultGood
            resultBad
            answerCorrectId
        }
      	didyouknows
        {
            description
        }
        specificities
        {
            quantity
            name
            description
        }
    }
}`;