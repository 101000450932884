
import { Specificity } from '@/apollo/queries/cetaceans/cetaceans';
import { Link } from '@/models/link';
import { Organizer } from '@/models/organizer';
import { Route } from '@/models/route';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-general-cetaceans',
  templateUrl: './general-cetaceans.component.html',
  styleUrls: ['./general-cetaceans.component.scss']
})
export class GeneralCetaceansComponent implements OnInit
{
    links : Array<Link>;
    
    introContent: string;

    sensoryAbilities: Specificity[];
    sensoryCommunication: Specificity[];

    constructor()
    {
        this.introContent = "Géants et fragiles";

        this.sensoryAbilities = [
            { quantity: "", name: "La vue", description: "Ils ont une bonne vue, aussi bien sous l’eau que hors de l’eau. " },
            { quantity: "", name: "L'ouïe", description: "Ils entendent très bien sous l’eau, à des dizaines de kilomètres et contrairement à nous, perçoivent parfaitement la provenance des sons." },
            { quantity: "", name: "L'odorat", description: "De récentes publications montrent que les cétacés sentent les odeurs dans l'air. D'autres études sont en cours." },
            { quantity: "", name: "Le goût", description: "Le sens du goût leur permet de choisir leurs proies et d’avoir des informations sur la salinité de l’eau, pouvant alors les aider à s’orienter." },
            { quantity: "", name: "Le toucher", description: "Le contact entre eux est très important notamment entre la mère et son petit mais aussi entre individus lors des jeux sociaux ou sexuels." },
            { quantity: "", name: "L'orientation", description: "Les cétacés pourraient être sensibles au magnétisme terrestre, aux courants marins,aux bruits de fond de l'océan... On en sait encore très peu à ce sujet." }
        ];

        this.sensoryCommunication = [
            { quantity: "", name: "Sons", description: "Les cétacés sont des animaux fondamentalement acoustiques. Par exemple, les dauphins sifflent, les Baleines à bosse mâles chantent et les Cachalots cliquent." },
            { quantity: "", name: "Comportements", description: "Ils communiquent aussi entre eux en faisant des sauts, en claquant leurs nageoires pectorales ou leur caudale et en se frottant les uns contre les autres." }
        ];
        
        this.links = [
            { url: Route.GeneralCetaceans + "#qec", name: "Les cétacés" },
            { url: Route.GeneralCetaceans + "#evo", name: "Évolution" },
            { url: Route.GeneralCetaceans + "#cas", name: "Capacités sensorielles" },
            { url: Route.GeneralCetaceans + "#eco", name: "Écholocation" },
            { url: Route.GeneralCetaceans + "#cap", name: "Capacités physiques" },
            { url: Route.GeneralCetaceans + "#men", name: "Menaces" },
        ];
    }

    ngOnInit(): void
    {
    }
}
