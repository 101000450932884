import { Injectable, NgZone, ElementRef } from '@angular/core';
import {
    Engine,
    ArcRotateCamera,
    Scene,
    Light,
    Color4,
    Vector3,
    Color3,
    HemisphericLight,
    SceneLoader,
    StandardMaterial,
    Texture
} from 'babylonjs';
import { EngineLoadingScreen } from '@/models/enginLoadingScreen';

import 'babylonjs-loaders';

@Injectable({
  providedIn: 'root'
})
export class CetaAnatomy3dService
{
    private canvas: HTMLCanvasElement | undefined;
    private engine: Engine | undefined;
    private camera: ArcRotateCamera | undefined;
    private scene: Scene | undefined;
    private light: Light | undefined;

    public constructor(private ngZone: NgZone)
    {
    }
  
    public createScene(canvas: ElementRef<HTMLCanvasElement>, nameModel: string, zoom: number): void
    {
        this.canvas = canvas.nativeElement;
    
        // Charge le moteur 3D
        this.engine = new Engine(this.canvas, true);
        this.engine.loadingScreen = new EngineLoadingScreen("I'm loading!!");
        this.engine.displayLoadingUI();
    
        // Creer une scene de base
        this.scene = new Scene(this.engine);
        this.scene.clearColor = new Color4(0, 0, 0, 0);
        this.scene.autoClear = true;
    
        // Camera orbit
        this.camera = new ArcRotateCamera("camera", Math.PI, Math.PI / 2, zoom, Vector3.Zero(), this.scene);
        this.camera.lowerRadiusLimit = zoom;
        this.camera.upperRadiusLimit = zoom;
        this.camera.lowerBetaLimit = Math.PI / 2.6;
        this.camera.upperBetaLimit = Math.PI / 1.4;
        this.camera.wheelPrecision = 0;
        this.camera.fov = .35;
        this.camera.attachControl(this.canvas, true);
    
        // Light
        this.light = new HemisphericLight("light", new Vector3(0, 1, 0), this.scene);

        // Model
        SceneLoader.ImportMesh(nameModel + "_LOD1_Babylon", "../../assets/3d/", nameModel + ".babylon", this.scene, () =>
        {
            this.engine?.hideLoadingUI();
        });
    }

    public animate(): void
    {
        // We have to run this outside angular zones,
        // because it could trigger heavy changeDetection cycles.
        this.ngZone.runOutsideAngular(() =>
        {
            const rendererLoopCallback = () =>
            {
                this.scene!.render();
            };
    
            this.engine!.runRenderLoop(rendererLoopCallback);
        });
    }

    public resizeEngine(): void
    {
        if (this.engine)
        {
            this.engine.resize();
        }
    }

    public showAnatomy(meshName: string, suffixCeta: string, anatomyPart: string): void
    {
        if (!this.scene) return;

        // Mask texture
        let mask = new Texture("../../assets/3d/" + suffixCeta + "_Mask_" + anatomyPart +".png", this.scene);

        let anatomyMat = this.scene!.getMeshByName(meshName + "_LOD1_Babylon")?.material as StandardMaterial;
        anatomyMat!.transparencyMode = 1;
        anatomyMat!.alpha = .8;
        anatomyMat!.emissiveColor = new Color3(28 / 255, 130 / 255, 0 / 255);
        anatomyMat!.emissiveTexture = mask;
    }
}
