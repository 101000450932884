import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-presentation',
  templateUrl: './presentation.component.html',
  styleUrls: ['./presentation.component.scss']
})
export class PresentationComponent implements OnInit
{
    useImage: boolean = false;

    @Input() urlImage: string | undefined;
    @Input() title: string | undefined;
    @Input() content: string | undefined;
    @Input() removeGradient: boolean = false;
    @Input() useFlag: boolean = false;

    constructor()
    {
    }

    ngOnInit(): void
    {
        this.useImage = this.urlImage != undefined;
    }
}
