
<div class="container-fluid bg-cloud">

    <app-title title="Abonnement à nos actualités" [useTopSpace]="true"></app-title>

    <div class="container" >

        <!-- Formulaire -->
        <div class="row" *ngIf="!submitSuccess && !submitError && !isLoading">
            <div class="container">
                <div class="row mb-128r">
                    <div class="col-12 col-lg-4">

                        <form [formGroup]="newsletterForm">
                        
                            <label for="exampleFormControlInput1" class="form-label typo_9_B color-blue-medium">Email</label>
                            <input [class.inputInvalid]="submittedForm && form.email.errors" id="exampleFormControlInput1" placeholder="votremail@exemple.com" formControlName="email">

                            <div *ngIf="submittedForm && form.email.errors">
                                <div class="color-error" *ngIf="form.email.errors.required">L'email est obligatoire</div>
                                <div class="color-error" *ngIf="form.email.errors.email">Votre email est invalide</div>
                            </div>

                            <btncta class="mb-4 mb-lg-0 mt-32r d-flex flex-column align-items-center" (click)="onNewsletterSubmit()">Me tenir au courant</btncta>
                        </form>

                    </div>

                    <div class="col-12 col-lg-7 offset-1 d-flex align-items-center typo_3">
                        On vous tient au courant de nos interventions passées, les stands à venir, les outils créés, les infos et actus sur les cétacés etc.<br><br>
                        Nous avons l'habitude d'envoyer un message bimestriel.
                    </div>
                </div>
            </div>
        </div>
        
        <!-- Loading -->
        <div class="row d-flex justify-content-center mb-96r" *ngIf="!submitSuccess && !submitError && isLoading">

            <div class="spinner-border text-center" role="status" >
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>

        <!-- Envoi Success -->
        <div class="row d-flex justify-content-center mb-96r" *ngIf="submitSuccess">

            <div class="text-center typo_4 color-success">
                Votre abonnement à bien été pris en compte !<br>
                Bienvenue parmi nous !
            </div>

        </div>

        <!-- Envoi Erreur -->
        <div class="row d-flex justify-content-center mb-96r" *ngIf="submitError">

            <div *ngIf="!duplicateError" class="text-center typo_4 color-error">
                Une erreur s'est produite, veuillez réessayer ultèrieument.
            </div>

            <div *ngIf="duplicateError" class="text-center typo_4 color-success">
                Vous faites déjà parti de nos listes !<br>Merci.
            </div>

        </div>
    </div>

</div>