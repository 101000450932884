

<!-- Comment -->
<app-presentation title="Pourquoi aider l'association ?" [content]="helpusContent"></app-presentation>

<div class="container-fluid bg-blue-gradient">

    <div class="container">
        <div class="row title_1">
            <separator></separator>
            <div class="col typo_1 color-blue-light">Comment puis-je aider ?</div>
        </div>
        <img class="mt-8r mb-32r" src="assets/images/Separator_Multi.svg"/>
    </div>
    
    <app-organizer [organizer]="organizerHelpus" bgColor="bg-blue-medium" color="color-blue-light" [bgDisableInLargeScreen]="true"></app-organizer>
    <separator heightRem="8"></separator>

</div>


<div class="container-fluid">

    <separator></separator>
    
    <app-title title="Foire aux questions ..."></app-title>
    <app-faq></app-faq>

    <separator heightRem="8"></separator>

</div>