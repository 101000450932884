import { Antenna, Antennas, FIND_ANTENNAS } from '@/apollo/queries/antennas/antennas';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Apollo } from 'apollo-angular';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit
{
    get form() { return this.contactUsForm.controls; }

    submittedForm: boolean = false;

    contactUsForm : FormGroup;

    antennas: Antenna[] | undefined;
    
    watchAntennasSubscription : Subscription | undefined;
    
    constructor(private formBuilder : FormBuilder, private apollo: Apollo)
    {
        this.contactUsForm = this.formBuilder.group({
            antennasSelect: [0, Validators.required],
            subject: [null, Validators.required],
            name: [null, Validators.required],
            phone: [null]
        });

        // Antennas
        this.watchAntennasSubscription = this.apollo.watchQuery<Antennas>({
            query: FIND_ANTENNAS
        })
        .valueChanges.subscribe(result => 
        {
            this.antennas = result.data.antennas;
            this.contactUsForm.controls['antennasSelect'].setValue(this.antennas[0]);
        });;
    }

    ngOnInit(): void
    {
    }

    onContactUsSubmit()
    {
        this.submittedForm = true;

        if (this.contactUsForm.valid)
        {
            let mailto: string = "mailto:" + this.contactUsForm.value.antennasSelect.email +
                "?subject=" + this.contactUsForm.value.subject + 
                "&body=%0D%0DDe: " + this.contactUsForm.value.name;

            if (this.contactUsForm.value.phone !== null)
            {
                mailto += "%0DTel: " + this.contactUsForm.value.phone;
            }

            window.location.href = mailto;
        }
    }
}
