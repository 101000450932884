
<!-- Nos activités papiers -->
<app-presentation title="Jojo Sous L'eau" [content]="introContent" [useFlag]="true" urlImage="assets/images/jojosousleau.png"></app-presentation>

<app-submenu [links]="links"></app-submenu>

<div class="container-fluid bg-blue-dark pb-128r">

    <separator id="tel"></separator>
    <app-title title="Disponible sous :" [useThemeDark]="true"></app-title>

    <div class="container">
        <div class="row">
            <div class="col col-lg-4 mb-5 mb-lg-0 d-flex flex-column align-items-center" (click)="dllWindows()">
                <div class="typo_10_E text-center">7 jeux disponibles en 3 langues<br>pour PC</div>
                <div class="iconWindows iconDll mb-3"></div>
                <btncta>Version Windows</btncta>
            </div>
            <!--
            <div class="col-6 col-lg-3 mb-5 mb-lg-0 d-flex justify-content-center">
                <btncta>Version Mac</btncta>
            </div>
            -->
            <div class="col col-lg-4 mb-5 mb-lg-0 d-flex flex-column align-items-center" (click)="dllIOS()">
                <div class="typo_10_E text-center">6 jeux disponibles en 3 langues<br>pour téléphones et tablettes Android</div>
                <div class="iconIOS iconDll mb-3"></div>
                <btncta>Mobile iOS</btncta>
            </div>
            <div class="col col-lg-4 mb-5 mb-lg-0 d-flex flex-column align-items-center" (click)="dllAndroid()">
                <div class="typo_10_E text-center">6 jeux disponibles en 3 langues<br>pour téléphones et tablettes iOS</div>
                <div class="iconAndroid iconDll mb-3"></div>
                <btncta>Mobile Android</btncta>
            </div>
        </div>
    </div>

</div>

<separator-color></separator-color>

<div class="container-fluid bg-blue-dark pb-128r">
    <separator id="dec"></separator>
    <app-title title="Jojo sous l'eau, c'est :" [useThemeDark]="true"></app-title>
    <app-specificities [specificities]="specWhatisJojo"></app-specificities>
</div>

<separator-color></separator-color>

<div class="container-fluid background-paper">
        
    <separator id="per"></separator>
    <app-title title="Les personnages"></app-title>

    <div class="container">
        <div class="row">

            <app-card-type-c class="col-12 col-md-6 col-lg-4 g-0" 
                name="Jojo"
                description="C'est un Grand dauphin qui nous invite à découvrir son univers."
                urlImageStandard="assets/images/jojosousleau/Jojo.png">
            </app-card-type-c>

            <app-card-type-c class="col-12 col-md-6 col-lg-4 g-0" 
                name="Strombi"
                description="Notre ami Strombi est un Lambi dont le nom latin est Strombus gigas. C'est le sage, celui qui a la connaissance et nous teste par ses quizz amusants qui nous apportent de nombreuses informations."
                urlImageStandard="assets/images/jojosousleau/Strombi.png">
            </app-card-type-c>
            
            <app-card-type-c class="col-12 col-md-6 col-lg-4 g-0" 
                name="Calmos"
                description="C'est un Calamar de récif qui connait parfaitement son milieu : suivons le guide !"
                urlImageStandard="assets/images/jojosousleau/Calmos.png">
            </app-card-type-c>

        </div>
    </div>

    <separator heightRem="4"></separator>
</div>