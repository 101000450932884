
<div class="container-fluid g-0 background-cetacean">
    
    <app-presentation title="Nos histoires" [content]="introContent" [useFlag]="true"></app-presentation>

</div>

<!-- Sous-menu -->
<app-submenu [links]="links" [useThemeDark]="false"></app-submenu>

<!-- Histoires -->
<div class="container-fluid bg-blue-dark">
    
    <separator id="his"></separator>

    <div class="container">
        <div class="row">
    
            <app-card-type-a *ngFor="let story of stories; let i = index" class="col-xs-12 col-lg-4 g-0" 
                [idCard]="i"
                [name]="story.name"
                [description]="story.description"
                [difficulty]="story.activity_difficulty ? story.activity_difficulty.difficulty : '?'"
                [urlImage]="backendUrl + story.image.url"
                [urlActivityFr]="story.pdf_fr ? backendUrl + story.pdf_fr.url : ''"
                [urlActivityEn]="story.pdf_en ? backendUrl + story.pdf_en.url : ''"
                [urlActivityEs]="story.pdf_es ? backendUrl + story.pdf_es.url : ''">
            </app-card-type-a>
            
        </div>
    </div>
    
    <separator heightRem="8"></separator>

</div>

<separator-color></separator-color>

<!-- Les versions imprimés -->
<div class="container-fluid background-paper">
    
    <div class="container">
        <div class="row">

            <separator id="imp"></separator>

            <div class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center">
                
                <app-title title="Les versions imprimées"></app-title>

                <div class="typo_3">
                    Vous pouvez également vous procurer les versions imprimées actuellement en vente sur notre boutique.<br><br>
                    Pour les enseignants, contactez-nous pour les recevoir gratuitement. 
                </div>

                <btncta class="mt-48r mb-48r" (click)="onOpenShop()">Notre boutique</btncta>
            </div>

            <div class="col-12 col-lg-6">
                <img class="img-fluid" src="assets/images/package_stories.png" />
            </div>

        </div>
    </div>
    
    <separator heightRem="8"></separator>

</div>


<!--
<app-quiz [quizzes]="quizzes"></app-quiz>
-->