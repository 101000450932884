import { Route } from '@/models/route';
import { AuthenticationService } from '@/services/authentication.service';
import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit
{
    isSanctuaryActif: boolean = false;
    isOurToolsActif: boolean = false;
    isAssociationActif: boolean = false;
    isNewsActif: boolean = false;
    isMembAdminActif: boolean = false;

    isGeneralCetaceansActif: boolean = false;
    isGeneralAntillesActif: boolean = false;
    isCetaceansActif: boolean = false;
    isVideosActif: boolean = false;
    isAllCetaceansActif: boolean = false;

    isTutosActif: boolean = false;
    isStoriesActif: boolean = false;
    isPaperActivitiesActif: boolean = false;
    isJojoActif: boolean = false;
    isFlashGamesActif: boolean = false;
    isMembAdminNewletter: boolean = false;

    menuSelected: number = 1;
    menuMobileOpened: boolean = false;
    navbarExtensionOpened: boolean = false;

    constructor(private eRef: ElementRef, private router: Router, private authentication: AuthenticationService)
    {
        this.router.events.subscribe((url) => 
        {
            this.isSanctuaryActif = this.router.url === "/" + Route.GeneralCetaceans || this.router.url === "/" + Route.GeneralAntilles;
            this.isOurToolsActif = this.router.url === "/" + Route.Stories || this.router.url === "/" + Route.PaperActivities || this.router.url === "/" + Route.JojoSousLeau || this.router.url === "/" + Route.OurGames;
            this.isAssociationActif = this.router.url === "/" + Route.Association;
            this.isNewsActif = this.router.url === "/" + Route.News;
            this.isMembAdminActif = this.router.url === "/" + Route.MembAdminNewletter;
            
            this.isTutosActif = this.router.url === "/" + Route.Tutos;
            this.isGeneralCetaceansActif = this.router.url === "/" + Route.GeneralCetaceans;
            this.isGeneralAntillesActif = this.router.url === "/" + Route.GeneralAntilles;
            this.isCetaceansActif = this.router.url === "/" + Route.Cetaceans,
            this.isVideosActif = this.router.url === "/" + Route.Videos,
            this.isStoriesActif = this.router.url === "/" + Route.Stories;
            this.isPaperActivitiesActif = this.router.url === "/" + Route.PaperActivities;
            this.isJojoActif = this.router.url === "/" + Route.JojoSousLeau;
            this.isFlashGamesActif = this.router.url === "/" + Route.OurGames;
            this.isMembAdminNewletter = this.router.url === "/" + Route.MembAdminNewletter;
            
            this.closeMenu();
        });
    }
  
    ngOnInit(): void
    {
    }

    @HostListener('document:click', ['$event'])
    clickout(event :any)
    {
        if(!this.eRef.nativeElement.contains(event.target))
        {
            this.closeMenu();
        }
    }

    onToggleMenuClick()
    {
        this.menuMobileOpened = !this.menuMobileOpened;

        if (!this.menuMobileOpened)
        {
            this.navbarExtensionOpened = false;
        }
    }

    onHomeClick()
    {
        this.navbarExtensionOpened = false;
        this.router.navigate([Route.Home]);
    }

    onSanctuClick()
    {
        this.menuSelected = 1;
        this.navbarExtensionOpened = true;
    }

    onOurToolsClick()
    {
        this.menuSelected = 2;
        this.navbarExtensionOpened = true;
    }

    onAssocClick()
    {
        this.navbarExtensionOpened = false;
        this.router.navigate([Route.Association]);
    }

    onActusClick()
    {
        this.navbarExtensionOpened = false;
        this.router.navigate([Route.News]);
    }

    onHelpUsClick()
    {
        this.navbarExtensionOpened = false;
        this.router.navigate([Route.HelpUs]);
    }

    onShopClick()
    {
        window.open("https://maboutiquemabaleine.com", "_blank");
    }
    
    onMembAdminClick()
    {
        this.menuSelected = 6;
        this.navbarExtensionOpened = true;
    }

    onStoriesClick()
    {
        this.navbarExtensionOpened = false;
        this.router.navigate([Route.Stories]);
    }

    onPaperActivitiesClick()
    {
        this.navbarExtensionOpened = false;
        this.router.navigate([Route.PaperActivities]);
    }

    onJojoClick()
    {
        this.navbarExtensionOpened = false;
        this.router.navigate([Route.JojoSousLeau]);
    }

    onFlashClick()
    {
        this.navbarExtensionOpened = false;
        this.router.navigate([Route.OurGames]);
    }

    onAntillesClick()
    {
        this.navbarExtensionOpened = false;
        this.router.navigate([Route.GeneralAntilles]);
    }

    onCetaceClick()
    {
        this.navbarExtensionOpened = false;
        this.router.navigate([Route.GeneralCetaceans]);
    }

    onCetaceansClick()
    {
        this.navbarExtensionOpened = false;
        this.router.navigate([Route.Cetaceans]);
    }
    
    onVideoClick()
    {
        this.navbarExtensionOpened = false;
        this.router.navigate([Route.Videos]);
    }

    onTutosClick()
    {
        this.navbarExtensionOpened = false;
        this.router.navigate([Route.Tutos]);
    }

    onMembAdminNewletterClick()
    {
        this.navbarExtensionOpened = false;
        this.router.navigate([Route.MembAdminNewletter]);
    }

    isAuthentified(): boolean
    {
        return this.authentication.currentUserValue.id != -1;
    }

    onLogOutClick()
    {
        this.navbarExtensionOpened = false;
        this.authentication.logout();
        
        this.router.navigate([Route.MembAdmin]);
    }

    private closeMenu()
    {
        this.menuMobileOpened = false;
        this.navbarExtensionOpened = false;
    }
}
