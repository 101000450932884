

export const Settings =
{
    backEndUrl: 'https://monecolemabaleine.com/api/',
    graphQLUrl: 'https://monecolemabaleine.com/api/graphql',
    authenticationUrl: 'https://monecolemabaleine.com/api/auth/local',
    
    /*
    backEndUrl: 'http://localhost:1337',
    graphQLUrl: 'http://localhost:1337/graphql',
    authenticationUrl: 'http://localhost:1337/auth/local',
    */
}