
<div class="bg-blue-light pt-48r pb-64r">

    <div class="container">

        <div class="row typo_10_B color-blue-dark text-center">
            <div class="col-6 col-md-3 d-flex flex-column mb-32r">
                <div class="typo_6 color-blue-medium mb-16r">Notre sanctuaire</div>
                <a class="link" href="./GeneralitesAntilles">Les Antilles françaises</a>
                <a class="link" href="./GeneralitesCetace">Généralités sur les cétacés</a>
                <a class="link" href="./ZoomCetaces">Zoom sur chaque cétacé</a>
            </div>
            <div class="col-6 col-md-3 d-flex flex-column mb-32r">
                <div class="typo_6 color-blue-medium mb-16r">Nos outils</div>
                <a class="link" href="./Histoires">Les histoires</a>
                <a class="link" href="./ActivitesPapier">Nos activités à imprimer</a>
                <a class="link" href="./JojoSousLeau">Jojo sous l'eau</a>
                <a class="link" href="./JeuxInteractifs">Nos jeux flash</a>
            </div>
            <div class="col-6 col-md-3 d-flex flex-column">
                <div class="typo_6 color-blue-medium mb-16r">L'association</div>
                <a class="link" href="./Association">L'association</a>
                <a class="link" href="./Actu">Nos actus</a>
                <a class="link" href="./Association#val">Nos valeurs & objectifs</a>
                <a class="link" href="./Association#int">Nos interventions</a>
                <a class="link" href="./Association#mem">Nos membres</a>
                <a class="link" href="./Association#bil">Bilans</a>
                <a class="link" href="./Association#par">Nos partenaires & amis</a>
            </div>
            <div class="col-6 col-md-3 d-flex flex-column">
                <div class="typo_6 color-blue-medium mb-16r">Nous aider</div>
                <a class="link" href="./AidezNous">Aider l'association</a>
                <a class="link" href="https://www.helloasso.com/associations/mon-ecole-ma-baleine" target="_blank">Adhérer à l'association</a>
                <a class="link" href="https://www.helloasso.com/associations/mon-ecole-ma-baleine" target="_blank">Faire un don</a>
                <a class="link" href="https://maboutiquemabaleine.com" target="_blank">Notre boutique</a>
                <a class="link" href="./AidezNous#faq">Foire aux questions</a>
                <a class="link" href="./ContactezNous">Contactez-nous</a>
            </div>
        </div>
    </div>

</div>