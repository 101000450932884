import { Link } from '@/models/link';
import { Organizer } from '@/models/organizer';
import { Route } from '@/models/route';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-general-antilles',
  templateUrl: './general-antilles.component.html',
  styleUrls: ['./general-antilles.component.scss']
})
export class GeneralAntillesComponent
{
    organizeConservation: Organizer | undefined;
    
    links : Array<Link>;

    constructor()
    {
        this.organizeConservation = {
            elements: [
                { icon: null, buttonName: null, buttonUrl: null, buttonUseCta: false, urlTarget: null, title: "Aire Marine Protégée", description: "C'est un espace délimité en mer qui répond<br>à des objectifs de protection de la nature<br>à long terme.<br><br>Il en existe plusieurs dans les Antilles françaises comme la Réserve Nationale Naturelle<br>de Saint-Martin, la Réserve Nationale Naturelle<br>de Petite-Terre en Guadeloupe, le Sanctuaire<br>de mammifères marins Agoa,<br>le Parc national de la Guadeloupe<br>et le Parc naturel marin en Martinique." },
                { icon: null, buttonName: "Sanctuaire Agoa", buttonUrl: "https://www.sanctuaire-agoa.fr", buttonUseCta: false, urlTarget: "_blank", title: "Regard sur le sanctuaire Agoa", description: "Le sanctuaire Agoa est une Aire Marine Protégée (AMP) couvrant tout l’espace marin<br>des Antilles françaises (Martinique, Guadeloupe, Saint-Martin et Saint-Barthélemy)<br>soit une superficie de 143&nbsp;256 km²<br>(127 fois la Martinique !).<br><br>C'est la deuxième plus grande AMP de France.<br>Son objectif est de protéger<br>les mammifères marins, ainsi que leurs habitats, des impacts négatifs directs ou indirects<br>des activités humaines.<br><br>Pour ce faire, le sanctuaire agit pour améliorer<br>les connaissances, communiquer, surveiller<br>et coopérer avec les autres états de la Caraïbe." },
                { icon: null, buttonName: null, buttonUrl: null, buttonUseCta: false, urlTarget: null, title: "Charte d'approche", description: "Et n’oublions pas que dans les Antilles françaises, nous avons la chance de voir les dauphins,<br>les Cachalots et les Baleines à bosse en liberté !<br><br>Une charte d’approche de ces cétacés protégés dans le sanctuaire Agoa, a été mise en place<br>afin de ne pas les perturber." },
                { icon: null, buttonName: "OFB", buttonUrl: "https://ofb.gouv.fr/", buttonUseCta: false, urlTarget: "_blank", title: "Zoom sur l'Office Français de la Biodiversité", description: "Le Sanctuaire Agoa fait partie<br>de l’Office Français de la Biodiversité.<br><br>C’est un établissement public du ministère<br>de l’environnement, dédié à la connaissance,<br>la protection, la gestion et la sensibilisation<br>à la biodiversité terrestre, aquatique et marine." },
                { icon: null, buttonName: "CAR SPAW", buttonUrl: "http://www.car-spaw-rac.org", buttonUseCta: false, urlTarget: "_blank", title: "Le CAR-SPAW", description: "Centre d’activités régional pour les espaces<br>et les espèces spécialement protégés<br>de la Caraïbe.<br><br>Cet organisme est dédié à la mise en oeuvre<br>de la convention de Carthagène,<br>et de son protocole SPAW<br>(Specially Protected Areas and Wildlife).<br><br>Sous la tutelle du Ministère de la Transition Ecologique et Solidaire, il oeuvre sous l'égide<br>des Nations-Unies pour l’Environnement<br>– programme Caraïbes -<br>et en appui aux pays et territoires caribéens pour répondre à leurs engagements sur la biodiversité et notamment les cétacés. Il est basé<br>en Guadeloupe." },
                { icon: null, buttonName: "Agence de l'environnement", buttonUrl: "http://www.agencedelenvironnement.fr", buttonUseCta: false, urlTarget: "_blank", title: "L'Agence Territoriale de l'Environnement de Saint-Barthélemy", description: "Cet établissement public est en charge<br>de la gestion de la Réserve Naturelle<br>de Saint-Barthélemy et de la protection<br>de la biodiversité de l'île." },
                { icon: null, buttonName: "Réserve naturelle", buttonUrl: "http://www.reservenaturelle-saint-martin.com", buttonUseCta: false, urlTarget: "_blank", title: "La Réserve Naturelle de Saint-Martin", description: "Association de gestion de la Réserve Naturelle Nationale de Saint-Martin.<br><br>Cette Aire Marine Protégée de 30km² se situe<br>au nord-est de l’île de Saint-Martin.<br>Créé en 1998, cet espace préserve les principaux écosystèmes de l’île, et donc les cétacés<br>qui y vivent.<br><br>La Réserve gère 11 kilomètres de rivages terrestres naturels, d'où l'on peut parfois apercevoir dauphins et baleines. Elle a ainsi fonction d'Observatoire des cétacés." },
                { icon: null, buttonName: null, buttonUrl: null, buttonUseCta: false, urlTarget: null, title: "Sensibillisation", description: "L'Education au Développement Durable fait partie intégrante du programme scolaire<br>et Mon École, Ma baleine en lien avec le rectorat sensibilise des jeunes en milieu scolaire<br>mais aussi des adultes dans les centres<br>de formation ou des prestataires du tourisme<br>qui travaillent sur l'eau.<br><br>Un réseau de nombreuses associations<br>et diverses structures s'est développé<br>dans les Antilles françaises de façon très active." },
                { icon: null, buttonName: "OMMAG", buttonUrl: "http://www.ommag971.jimdo.com", buttonUseCta: false, urlTarget: "_blank", title: "Science participative", description: "Toute personne observant un cétacé<br>est le bienvenu pour faire un signalement<br>afin de mieux connaitre ces animaux.<br><br>Par exemple en Guadeloupe, l'OMMAG : Observatoire des Mammifères Marins de l‘Archipel Guadeloupéen, étudie les cétacés<br>et réalise des catalogues d’individus pour suivre leurs déplacements en collaboration<br>avec des instituts internationaux.<br><br>Il collecte les données issues de la science participative et participe à des missions<br>de recherche.<br><br>Il y a aussi possibilité d'enregistrer en ligne<br>son observation avec l'application obsenmer." }
            ] 
        }

        // Menu links
        this.links = [
            { url: Route.GeneralAntilles + "#geo", name: "Géographie" },
            { url: Route.GeneralAntilles + "#his", name: "Histoire" },
            { url: Route.GeneralAntilles + "#nat", name: "Nature" },
            { url: Route.GeneralAntilles + "#dan", name: "Dangers" },
            { url: Route.GeneralAntilles + "#eff", name: "Effet papillon" },
            { url: Route.GeneralAntilles + "#sol", name: "Solutions" },
        ];
    }
}
