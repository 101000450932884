

import gql from "graphql-tag";
import { ImageMedia } from "../general/general";

export interface ToCome
{
    title: string;
    description: string;

    image: ImageMedia;
}

export interface ToComes
{
    toComes: ToCome[];
}

export const FIND_TO_COMES = gql`
query ToComes
{
    toComes
    {
        title
        description
        
        image
        {
            url
        }
    }
}`;