
<div class="container">
    <div class="row" *ngFor="let specificity of specificities; let i = index">

        <div class="col-lg-3 bubble-align" [ngClass]="'offset-lg-' + (randomNumber[i] || '0')">

            <div class="bubble d-flex flex-column align-items-center justify-content-center">
                <div *ngIf="specificity.quantity !== ''" class="typo_5 color-blue-light">{{ specificity.quantity }}</div> 
                <div class="typo_2 color-blue-light text-center">{{ specificity.name }}</div>
            </div>

        </div>
        <div class="col-lg-4 d-flex align-items-center typo_4_C color-blue-light text-specificity mb-5 mb-lg-0">
            {{ specificity.description }}
        </div>

    </div>
</div>