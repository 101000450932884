

import gql from "graphql-tag";

export interface Video
{
    title: string;
    description: string;

    videoUrl: string;
}

export interface Videos
{
    videos: Video[];
}

export const FIND_VIDEOS = gql`
query Videos
{
    videos
    {
        title
        description
        videoUrl
    }
}`;