import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'arrow',
  templateUrl: './arrow.component.html',
  styleUrls: ['./arrow.component.scss']
})
export class ArrowComponent
{
    @Input() orientation: string = "down";
    @Input() strokeColor: string = "blue-light";
}
