
<div class="container-fluid">

    <app-title title="Règles de confidentialité" [useTopSpace]="true" [useThemeDark]="false"></app-title>
    
    <div class="container">
        <div class="row">
            <div class="col typo_3">
                <b>JojoSousLeau</b> est édité par Mon École, Ma Baleine, association loi 1901, Route Hégesippe
                Légégitimus, Beauport, 97 117 Port Louis, Guadeloupe.<br>
                Notre <b>Application</b> est une application pour PC, Mac, mobile et tablette (Android / IOS) nommée « JojoSousLeau ».<br>
                Nos règles de confidentialité expliquent que nous ne collectons aucune données tel qu’elles soient.
            </div>
        </div>
    </div>
    
    <separator heightRem="8"></separator>
</div>

<div class="container-fluid">

    <separator id="nat"></separator>
    <app-title title="Données personnelles"></app-title>
    
    <div class="container">
        <div class="row">
            <div class="col typo_3">
                Depuis notre <b>Application</b>, nous ne demandons, ni ne collectons aucune donnée personnelle.
            </div>
        </div>
    </div>
    
    <separator heightRem="8"></separator>
</div>

<div class="container-fluid">

    <separator id="nat"></separator>
    <app-title title="Données relatives à la communication"></app-title>
    
    <div class="container">
        <div class="row">
            <div class="col typo_3">
                Lorsque vous utilisez notre <b>Application</b>, aucune donnée relative à la communication (type adresse IP) n’est collectée, ni demandée.
            </div>
        </div>
    </div>
    
    <separator heightRem="8"></separator>
</div>

<div class="container-fluid">

    <separator id="nat"></separator>
    <app-title title="Permissions demandées par l’Application"></app-title>
    
    <div class="container">
        <div class="row">
            <div class="col typo_3">
                Aucune permission (caméra, gps, lecteur empreinte, …) n'est nécessaire, ni demandée par l’<b>Application</b>.
            </div>
        </div>
    </div>
    
    <separator heightRem="8"></separator>
</div>

<div class="container-fluid">

    <separator id="nat"></separator>
    <app-title title="Pour les enfants moins de 13 ans"></app-title>
    
    <div class="container">
        <div class="row">
            <div class="col typo_3">
                Tous les outils, dessins, sont compatibles pour tout âge, notamment pour les enfants de moins de 13 ans.
            </div>
        </div>
    </div>
    
    <separator heightRem="8"></separator>
</div>
