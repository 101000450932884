<separator heightRem="5"></separator>

<ng-container *ngFor="let cetacean of cetaceans; let last = last">

    <div class="background-cover position-relative d-flex flex-column justify-content-center align-items-center cetacean"
        [ngStyle]="{'background-image': cetacean.primeImage ? 'url(' + backendUrl + cetacean.primeImage.url + ')' : 'none'}"
        [routerLink]="['/Cetace', cetacean.id]">
        
        <div class="position-absolute top-0 start-0 w-100 h-100 img-gradient"></div>

        <div class="position-relative typo_1 color-blue-light d-flex flex-column align-items-center">
            <div class="text-center mb-16r mx-1 mx-md-0">{{ cetacean.commonName }}</div>
            <img class="book-pos" src="assets/images/book.svg" />
        </div>
        <a class="position-absolute more-pos standardButton">EN SAVOIR PLUS</a>
    
    </div>

    <div *ngIf="!last" class="w-100">
        <div class="container-fluid">
            <div class="row">

                <div class="col blueline"></div>
                <div class="col yellowline"></div>
                <div class="col turquoiseline"></div>
                
                <div class="col blueline"></div>
                <div class="col yellowline"></div>
                <div class="col turquoiseline"></div>
                
                <div class="col blueline"></div>
                <div class="col yellowline"></div>
                <div class="col turquoiseline"></div>
            </div>
        </div>
    </div>

</ng-container>