<div class="container-fluid bg-blue-dark">
    
    <app-title title="Nos actus" [useTopSpace]="true" [useThemeDark]="true"></app-title>

    <div class="container">

        <div class="row">

            <ng-container *ngFor="let new of news">

                <app-card-type-b class="col-xs-12 col-lg-3 g-0" [routerLink]="['/Actu', new.id]"
                        [name]="new.title"
                        [subname]="new.creationDate | date : 'd MMMM YYYY'"
                        [urlImage]="new.primeImage ? backendUrl + new.primeImage.url : ''">
                </app-card-type-b>
                
            </ng-container>

        </div>
        
        <div *ngIf="newLimit < maxNews" class="d-flex justify-content-center mt-48r">
            <a class="standardButton typo_8" (click)="onLoadMoreClicked()">Voir plus</a>
        </div>

        <separator heightRem="8"></separator>
    </div>

</div>