
<div class="bg-blue-dark pt-48r pb-64r">

    <div class="container">
        <div class="row text-center">
            <div class="col d-flex flex-column align-items-center mb-5 mb-md-0">
                <div class="typo_2 mb-16r color-blue-light">Des questions ?</div>
                <btncta routerLink="/ContactezNous">Nous contacter</btncta>
            </div>
            <div class="col d-flex flex-column align-items-center mb-5 mb-md-0">
                <div class="typo_2 mb-16r color-blue-light">S'abonner aux actus ?</div>
                <btncta routerLink="/Newsletter">Abonnement</btncta>
            </div>
            <div class="col d-flex flex-column align-items-center mb-5 mb-md-0">
                <div class="typo_2 mb-16r color-blue-light">Suivez-nous !</div>
                <div class="col d-flex flex-row">
                    <a class="facebook mx-2" href="https://www.facebook.com/monecolemabaleine.memb" target="_blank"></a>
                    <a class="instagram mx-2" href="https://www.instagram.com/monecolemabaleine/" target="_blank"></a>
                    <a class="vimeo mx-2" href="https://vimeo.com/user10863143" target="_blank"></a>
                    <a class="youtube mx-2" href="https://www.youtube.com/user/Nellypelisson" target="_blank"></a>
                </div>
            </div>
        </div>
    </div>

</div>