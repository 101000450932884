
import { ActivitySubject, ActivitySubjects, ActivityTheme, ActivityThemes, ActivityType, ActivityTypes, FIND_ACTIVITY_SUBJECTS, FIND_ACTIVITY_THEMES, FIND_ACTIVITY_TYPES } from '@/apollo/queries/activity-filters/activity-filters';
import { GET_PAPER_ACTIVITIES_FILTERED, PaperActivitiesResponse, PaperActivity } from '@/apollo/queries/paper-activities/paper-activities';
import { Settings } from '@/settings';
import { Component, OnInit } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-paper-activities',
  templateUrl: './paper-activities.component.html',
  styleUrls: ['./paper-activities.component.scss']
})
export class PaperActivitiesComponent implements OnInit
{
    isFilterPanelOpen: boolean = false;

    sortDifficultyIncrease: boolean = true;

    introContent: string;

    backendUrl: string = Settings.backEndUrl;
    
    paperActivities: PaperActivity[] | undefined;

    activitySubjects: ActivitySubject[] | undefined;
    activitySubjectsSelected: string[] = [];
    activityTypes: ActivityType[] | undefined;
    activityTypesSelected: string[] = [];

    constructor(private apollo: Apollo)
    {
        this.introContent = "Sors tes crayons de couleurs et lance-toi dans toutes sortes d'activités.<br><br>" +
            "Coloriage, énigme, labyrinthe, puzzle sur nos personnages et plus généralement sur les cétacés. Nous sommes heureux de te proposer ces activités qui te permettront de mieux connaitre et identifier les cétacés. Plus de secret après.<br><br>" +
            "Il y en a pour tous les goûts, toutes les difficultés, et pour tous les créatifs en herbe.<br><br>" +
            "Au maximum trois langues sont disponibles : anglais, français ou espagnol.<br>" +
            "Sers-toi, c'est gratuit.<br><br>" +
            "Attention quand même aux déchets produits par le papier et les cartouches d'impression.";
    }

    ngOnInit(): void
    {
        // Filter Subjects
        this.apollo.query<ActivitySubjects>({ query: FIND_ACTIVITY_SUBJECTS }).subscribe(result => 
        {
            this.activitySubjects = [];
            result.data.activitySubjects.forEach((activity) => {
                let subject: ActivitySubject = { id: activity.id, name: activity.name, selected: false };
                this.activitySubjects?.push(subject);
            });
        });

        // Filter Types
        this.apollo.query<ActivityTypes>({ query: FIND_ACTIVITY_TYPES }).subscribe(result => 
        {
            this.activityTypes = [];
            result.data.activityTypes.forEach((activity) => {
                let type: ActivityType = { id: activity.id, name: activity.name, selected: false };
                this.activityTypes?.push(type);
            });
        });

        this.loadActivity();
    }

    reloadActivity()
    {
        this.gotoPanelView();
        this.loadActivity();
    }

    loadActivity()
    {
        if (this.activityTypes && this.activitySubjects)
        {
            this.activityTypesSelected = this.activityTypes?.filter(at => at.selected).map(at => at.id);
            this.activitySubjectsSelected = this.activitySubjects?.filter(as => as.selected).map(as => as.id);
        }

        // Paper Activities
        this.apollo.query<PaperActivitiesResponse>(
        { 
            query: GET_PAPER_ACTIVITIES_FILTERED, 
            variables:
            {
                activityTypes: this.activityTypesSelected,
                activitySubjects: this.activitySubjectsSelected,
                sortDifficultyIncrease: this.sortDifficultyIncrease
            }
        }).subscribe(result => 
        {
            this.paperActivities = result.data.getPaperActivityFiltered;
        });
    }

    onOpenCloseFilterClick()
    {
        if (this.isFilterPanelOpen)
        {
            this.isFilterPanelOpen = false;
        }
        else
        {
            this.gotoPanelView();
            this.isFilterPanelOpen = true;
        }
    }

    onStartFilterClick()
    {
        this.gotoPanelView();

        this.isFilterPanelOpen = false;
    }

    onClearFilterClick()
    {
        this.activitySubjects?.forEach((activity) => { activity.selected = false; });
        this.activityTypes?.forEach((activity) => { activity.selected = false; });

        this.isFilterPanelOpen = false;
        this.sortDifficultyIncrease = true;
        
        this.reloadActivity();
    }

    onFilterSubjectClick(filterId: string)
    {
        let founded = this.activitySubjects?.find(x => x.id == filterId);
        if (founded)
        {
            founded.selected = !founded.selected;
            
            this.reloadActivity();
        }
    }

    onFilterTypeClick(filterId: string)
    {
        let founded = this.activityTypes?.find(x => x.id == filterId);
        if (founded)
        {
            founded.selected = !founded.selected;

            this.reloadActivity();
        }
    }

    onDifficultyChanged(isIncreasing: boolean)
    {
        this.sortDifficultyIncrease = isIncreasing;
        
        this.reloadActivity();
    }

    gotoPanelView()
    {
        const el = document.getElementById("scrollTarget")?.offsetTop;
        const sf = document.getElementById("stickyFilter")?.offsetHeight;
        if (el && sf) window.scrollTo(0, el - sf)
    }
}
