import { ILoadingScreen } from "babylonjs";

export class EngineLoadingScreen implements ILoadingScreen {
    //optional, but needed due to interface definitions
    public loadingUIBackgroundColor: string;
    constructor(public loadingUIText: string)
    {
        this.loadingUIBackgroundColor = "";
        this.loadingUIText = "Veuillez patienter pendant le chargement de la 3D ! ..."
    }
    public displayLoadingUI()
    {
        const loading = document.getElementById("renderCanvasLoading");
        if (loading) 
        {
            loading.style.display = "flex";
            console.log("displayLoadingUI");
        }
    }
    public hideLoadingUI()
    {
        const loading = document.getElementById("renderCanvasLoading");
        if (loading)
        {
            loading.style.display = "none";
            console.log("hideLoadingUI: ", loading.style.display);
        }
    }
  }