
<div class="position-absolute top-0 left-0 w-100 menu">

    <!-- NavBar -->
    <nav class="navbar navbar-expand-lg navbar-light navbar-zindex" [ngClass]="navbarExtensionOpened ? 'anim-navbar-open' : 'anim-navbar-close'">
        <div class="container">
            <a class="mr-48r" (click)="onHomeClick()">
                <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 90 90">
                    <defs><style>.b{fill:none;}</style></defs>
                    <g transform="translate(-255 -115)">
                    <g transform="translate(258 136)"><path [ngClass]="navbarExtensionOpened ? 'logo-memb-open' : 'logo-memb-close'" d="M434.439,510.4c1.2-1.824,2.368-1.893,4.3-.635,4.128,2.681,8.551,1.711,12.891.255,14.358-4.815,19-4.83,24.767-.08,7.91-6.928,15.983-2.456,23.945-.155,5.207,1.506,10.186,2.793,15.148-.738,1.724-1.226,2.357.352,2.583,1.781.939,5.957-2.206,9.634-6.884,12.619-6.341,4.048-13.523,5.385-20.712,6.528-6.977,1.111-8.753,5.115-7.56,11.46.833,4.424,1.4,8.894,2.124,13.579h-17.22c.716-4.649,1.245-9.123,2.121-13.525,1.3-6.528-.689-10.452-7.7-11.553-6.9-1.083-13.782-2.378-19.93-6.11-3.606-2.191-6.429-4.931-7.873-8.956V511.29C434.439,510.994,434.439,510.7,434.439,510.4Zm8.978,10.616c10.775-.136,18.959-6.64,29.918-4.059-6.408-3.168-11.572-2.812-17.2-.835,1-1.925,1.774-3.416,2.648-5.1C451.976,511.643,450.074,518.466,443.417,521.014Zm37.228-4.241c10.756-2.022,19.035,3.636,30.048,4.459-7.159-2.676-9.068-9.729-15.841-10.165.831,1.59,1.6,3.065,2.7,5.152A19.945,19.945,0,0,0,480.645,516.772Zm14.347-3.073c-2.646-2.546-2.756-6.675-7.253-6.17l2.255,3.667c-4.689-.337-7.9,1.72-11.24,5.521C484.037,513.607,488.873,512.347,494.992,513.7Zm-29.044-6.31c-4.6-.313-4.73,3.611-7.012,6.2,5.659-1.215,10.348-.11,15.629,2.991-3.226-3.978-6.5-5.564-10.829-5.445.442-.794.708-1.3,1-1.785C465.067,508.788,465.423,508.234,465.948,507.39Zm20.379,1.375c-1.131-1.8-2.525-1.921-5.626-.632-3.255,1.353-3.749,2.328-2.939,6.062C479.706,510.865,482.833,509.524,486.327,508.765Zm-10.944,5.59c1.165-4-1-5.633-4.12-6.4-1.318-.32-3.1-1.688-4.107.835C470.657,509.5,473.546,510.972,475.383,514.356Z" transform="translate(-434.439 -506.227)"/></g><rect class="b" width="90" height="90" transform="translate(255 115)"/></g>
                </svg>
            </a>
            
            <button class="navbar-toggler" (click)="onToggleMenuClick()">
                <span class="navbar-toggler-icon"></span>
            </button>
    
            <div class="navbar-collapse" [ngClass]="{'d-none' : !menuMobileOpened}">

                <div class="d-flex flex-row align-items-center justify-content-between cursor-pointer mr-32r menu-txt" (click)="onSanctuClick()">
                    <div class="typo_6" [ngClass]="isSanctuaryActif ? 'color-turquoise-medium' : (navbarExtensionOpened ? 'color-blue-light' : 'color-blue-dark')">Cétacés aux Antilles</div>
                    <arrow [orientation]="navbarExtensionOpened && menuSelected == 1 ? 'up' : 'down'" [strokeColor]="isSanctuaryActif ? 'stroke-turquoise-medium' : (navbarExtensionOpened ? 'stroke-blue-light' : 'stroke-blue-dark')"></arrow>
                </div>

                <div class="d-flex flex-row align-items-center justify-content-between cursor-pointer mr-32r menu-txt" (click)="onOurToolsClick()">
                    <div class="typo_6" [ngClass]="isOurToolsActif ? 'color-turquoise-medium' : (navbarExtensionOpened ? 'color-blue-light' : 'color-blue-dark')">Nos outils</div>
                    <arrow [orientation]="navbarExtensionOpened && menuSelected == 2 ? 'up' : 'down'" [strokeColor]="isOurToolsActif ? 'stroke-turquoise-medium' : (navbarExtensionOpened ? 'stroke-blue-light' : 'stroke-blue-dark')"></arrow>
                </div>

                <div class="d-flex flex-row align-items-center justify-content-between cursor-pointer mr-32r menu-txt" (click)="onAssocClick()">
                    <div class="typo_6" [ngClass]="isAssociationActif ? 'color-turquoise-medium' : (navbarExtensionOpened ? 'color-blue-light' : 'color-blue-dark')">L'association</div>
                </div>

                <div class="d-flex flex-row align-items-center justify-content-between cursor-pointer mr-32r menu-txt" (click)="onActusClick()">
                    <div class="typo_6" [ngClass]="isNewsActif ? 'color-turquoise-medium' : (navbarExtensionOpened ? 'color-blue-light' : 'color-blue-dark')">Actus</div>
                </div>

                <div *ngIf="isAuthentified()" class="d-flex flex-row align-items-center justify-content-between cursor-pointer mr-32r menu-txt" (click)="onMembAdminClick()">
                    <div class="typo_6" [ngClass]="isMembAdminActif ? 'color-turquoise-medium' : (navbarExtensionOpened ? 'color-blue-light' : 'color-blue-dark')">Administrer</div>
                    <arrow [orientation]="navbarExtensionOpened && menuSelected == 6 ? 'up' : 'down'" [strokeColor]="isSanctuaryActif ? 'stroke-turquoise-medium' : (navbarExtensionOpened ? 'stroke-blue-light' : 'stroke-blue-dark')"></arrow>
                </div>
                
                <div class="ms-auto mt-2 mb-4 my-lg-0 d-flex flex-row align-items-center justify-content-between">             
                    <btncta class="me-2" (click)="onShopClick()">Notre boutique</btncta>   
                    <btncta (click)="onHelpUsClick()">Nous aider</btncta>
                </div>
            </div>
    
        </div>
    </nav>
    
    <!-- NarBar extension-->
    <div class="container-fluid navbar-extension bg-blue-dark" [ngClass]="navbarExtensionOpened ? 'anim-panel-open' : 'anim-panel-close'">
        <div class="container" [ngClass]="navbarExtensionOpened ? 'anim-panel-open-opacity' : 'anim-panel-close-opacity'">
            
            <!-- Separator -->
            <div *ngIf="menuSelected == 1 || menuSelected == 2" class="row align-items-center pt-32r pb-16r">
                <div class="col-2 offset-lg-1">
                    <img src="assets/images/Separator_Multi.svg"/>
                </div>
                <div class="col-lg-7 text-end typo_10_E">Nos outils en ligne sont gratuits, à la disposition de tous, sauf si c'est pour une structure politique, religieuse, commerciale ou détenant les animaux en captivité.</div>
            </div>

            <!-- Extension Sanctuary -->
            <div *ngIf="menuSelected == 1" class="row mb-lg-4">
                
                <div class="col-12 col-lg-4 offset-lg-1">
                    <div class="menu-button anim-button anim-button-opacity-max" (click)="onAntillesClick()">
                        
                        <div class="d-flex flex-row align-items-center justify-content-between">
                            <div class="typo_3" [ngClass]="isGeneralAntillesActif ? 'color-turquoise-medium' : 'color-blue-medium'">Les Antilles françaises</div>
                            <arrow orientation="right" [strokeColor]="isGeneralAntillesActif ? 'stroke-turquoise-medium' : 'stroke-blue-medium'"></arrow>
                        </div>

                        <div class="d-none d-lg-flex typo_4_B anim-button-opacity-min">Un coin de paradis à protéger ...</div>
                    </div>
                </div>

                <div class="col-12 col-lg-4 offset-lg-1">
                    <div class="menu-button anim-button anim-button-opacity-max" (click)="onCetaceansClick()">

                        <div class="d-flex flex-row align-items-center justify-content-between mb-8r">
                            <div class="typo_3" [ngClass]="isCetaceansActif ? 'color-turquoise-medium' : 'color-blue-medium'">
                                <div>Zoom sur chaque cétacé</div>
                                <flags></flags>
                            </div>
                            
                            <arrow orientation="right" [strokeColor]="isCetaceansActif ? 'stroke-turquoise-medium' : 'stroke-blue-medium'"></arrow>
                        </div>

                        <div class="d-none d-lg-flex typo_4_B anim-button-opacity-min">Découvrons les plus emblématiques</div>
                    </div>
                </div>
    
            </div>

            <div *ngIf="menuSelected == 1" class="row pb-96r">

                <div class="col-12 col-lg-4 offset-lg-1">
                    <div class="menu-button anim-button anim-button-opacity-max" (click)="onCetaceClick()">
                        
                        <div class="d-flex flex-row align-items-center justify-content-between">
                            <div class="typo_3" [ngClass]="isGeneralCetaceansActif ? 'color-turquoise-medium' : 'color-blue-medium'">Généralités sur les cétacés</div>
                            <arrow orientation="right" [strokeColor]="isGeneralCetaceansActif ? 'stroke-turquoise-medium' : 'stroke-blue-medium'"></arrow>
                        </div>

                        <div class="d-none d-lg-flex typo_4_B anim-button-opacity-min">C'est quoi un cétacé ?</div>
                    </div>
                </div>

                <div class="col-12 col-lg-4 offset-lg-1">
                    <div class="menu-button anim-button anim-button-opacity-max" (click)="onVideoClick()">
                        
                        <div class="d-flex flex-row align-items-center justify-content-between">
                            <div class="typo_3" [ngClass]="isVideosActif ? 'color-turquoise-medium' : 'color-blue-medium'">Nos vidéos</div>
                            <arrow orientation="right" [strokeColor]="isVideosActif ? 'stroke-turquoise-medium' : 'stroke-blue-medium'"></arrow>
                        </div>

                        <div class="d-none d-lg-flex typo_4_B anim-button-opacity-min">Sortez les pop corn !</div>
                    </div>
                </div>

            </div>
            
            <!-- Extension Nos outils -->
            <div *ngIf="menuSelected == 2" class="row mb-lg-4">

                <div class="col-12 col-lg-9 offset-lg-1">
                    <div class="menu-button anim-button anim-button-opacity-max" (click)="onTutosClick()">

                        <div class="d-flex flex-row align-items-center justify-content-between">
                            <div class="typo_3" [ngClass]="isTutosActif ? 'color-turquoise-medium' : 'color-blue-medium'">
                                <span>Nos tutos</span>
                                <flags></flags>
                            </div>
                            <arrow orientation="right" [strokeColor]="isTutosActif ? 'stroke-turquoise-medium' : 'stroke-blue-medium'"></arrow>
                        </div>

                        <div class="d-none d-lg-flex typo_4_B anim-button-opacity-min">Quelques tutoriels pour vous aider à découvrir nos activités</div>
                    </div>
                    
                    <hr class="hr-sep mt-24r mb-8r">
                </div>

            </div>

            <div *ngIf="menuSelected == 2" class="row mb-lg-4">

                <div class="col-12 col-lg-4 offset-lg-1">
                    <div class="menu-button anim-button anim-button-opacity-max" (click)="onStoriesClick()">

                        <div class="d-flex flex-row align-items-center justify-content-between mb-8r">
                            <div class="typo_3" [ngClass]="isStoriesActif ? 'color-turquoise-medium' : 'color-blue-medium'">
                                <div>Les histoires</div>
                                <flags></flags>
                            </div>
                            <arrow orientation="right" [strokeColor]="isStoriesActif ? 'stroke-turquoise-medium' : 'stroke-blue-medium'"></arrow>
                        </div>

                        <div class="d-none d-lg-flex typo_4_B anim-button-opacity-min">Suivez Léa et Matthieu dans leurs aventures</div>
                    </div>
                </div>

                <div class="col-12 col-lg-4 offset-lg-1">
                    
                    <div class="menu-button anim-button anim-button-opacity-max" (click)="onPaperActivitiesClick()">
                        
                        <div class="d-flex flex-row align-items-center justify-content-between mb-8r">
                            <div class="typo_3" [ngClass]="isPaperActivitiesActif ? 'color-turquoise-medium' : 'color-blue-medium'">
                                <div>Nos activités à imprimer</div>
                                <flags></flags>
                            </div>
                            <arrow orientation="right" [strokeColor]="isPaperActivitiesActif ? 'stroke-turquoise-medium' : 'stroke-blue-medium'"></arrow>
                        </div>

                        <div class="d-none d-lg-flex typo_4_B anim-button-opacity-min">Des activités pour tous les âges et en plusieurs langues</div>
                    </div>

                </div>

            </div>

            <div *ngIf="menuSelected == 2" class="row pb-96r">
    
                <div class="col-12 col-lg-4 offset-lg-1">
                    <div class="menu-button anim-button anim-button-opacity-max" (click)="onJojoClick()">
                        
                        <div class="d-flex flex-row align-items-center justify-content-between mb-8r">
                            <div class="typo_3" [ngClass]="isJojoActif ? 'color-turquoise-medium' : 'color-blue-medium'">
                                <div>Jojo sous l'eau</div>
                                <flags></flags>
                            </div>
                            <arrow orientation="right" [strokeColor]="isJojoActif ? 'stroke-turquoise-medium' : 'stroke-blue-medium'"></arrow>
                        </div>

                        <div class="d-none d-lg-flex typo_4_B anim-button-opacity-min">Jouons et découvrons la mer avec 7 jeux 3D</div>
                    </div>

                </div>

                <div class="col-12 col-lg-4 offset-lg-1">
                    
                    <div class="menu-button anim-button anim-button-opacity-max" (click)="onFlashClick()">
                        
                        <div class="d-flex flex-row align-items-center justify-content-between mb-8r">
                            <div class="typo_3" [ngClass]="isFlashGamesActif ? 'color-turquoise-medium' : 'color-blue-medium'">
                                <div>Nos escape games et autres</div>
                                <flags></flags>
                            </div>
                            <arrow orientation="right" [strokeColor]="isFlashGamesActif ? 'stroke-turquoise-medium' : 'stroke-blue-medium'"></arrow>
                        </div>

                        <div class="d-none d-lg-flex typo_4_B anim-button-opacity-min">Des jeux pour petits et grands</div>
                    </div>
                </div>
                
            </div>

            <!-- Extension Admin -->
            <div *ngIf="menuSelected == 6" class="row mb-lg-4 pb-96r">

                <div class="col-12 col-lg-4 offset-lg-1">
                    <div class="menu-button anim-button anim-button-opacity-max" (click)="onMembAdminNewletterClick()">

                        <div class="d-flex flex-row align-items-center justify-content-between">
                            <div class="typo_3" [ngClass]="isMembAdminNewletter ? 'color-turquoise-medium' : 'color-blue-medium'">Newsletter</div>
                            <arrow orientation="right" [strokeColor]="isMembAdminNewletter ? 'stroke-turquoise-medium' : 'stroke-blue-medium'"></arrow>
                        </div>

                        <div class="d-none d-lg-flex typo_4_B anim-button-opacity-min">Ecrire une newsletters aux abonnés</div>
                    </div>
                </div>

                <div class="col-12 col-lg-4 offset-lg-1">
                    
                    <div class="menu-button anim-button anim-button-opacity-max" (click)="onLogOutClick()">
                        
                        <div class="d-flex flex-row align-items-center justify-content-between">
                            <div class="typo_3" [ngClass]="isPaperActivitiesActif ? 'color-turquoise-medium' : 'color-blue-medium'">Déconnexion</div>
                            <arrow orientation="right" [strokeColor]="isPaperActivitiesActif ? 'stroke-turquoise-medium' : 'stroke-blue-medium'"></arrow>
                        </div>

                        <div class="d-none d-lg-flex typo_4_B anim-button-opacity-min">Se déconnecter de l'espace admin ...</div>
                    </div>

                </div>

            </div>

        </div>
    </div>

</div>
