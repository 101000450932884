import { EscapeGame, EscapeGames, FIND_ESCAPEGAMES } from '@/apollo/queries/escape-games/escape-games';
import { FIND_TO_COMES, ToCome, ToComes } from '@/apollo/queries/to-come/to-come';
import { FIND_VARIOUS_ACTIVITIES, VariousActivities, VariousActivity } from '@/apollo/queries/various-activity/various-activity';
import { Link } from '@/models/link';
import { Route } from '@/models/route';
import { Settings } from '@/settings';
import { Component, OnInit } from '@angular/core';
import { Apollo } from 'apollo-angular';

@Component({
  selector: 'app-our-games',
  templateUrl: './our-games.component.html',
  styleUrls: ['./our-games.component.scss']
})
export class OurGamesComponent implements OnInit
{
    backendUrl: string = Settings.backEndUrl;

    introContent: string;

    escapeGames: EscapeGame[] | undefined;
    variousActivities: VariousActivity[] | undefined;
    toComes: ToCome[] | undefined;

    links : Array<Link>;
 
    constructor(private apollo: Apollo)
    {
        this.introContent = "Découvre 6 jeux flash à utiliser sur l'ordinateur.<br><br>" + 
            "Un petit memory ? Le son des cétacés ? ou encore du coloriage ?<br>" +
            "Va vite les découvrir en famille.<br><br>Découvre aussi nos bulles de science sur le Grand dauphin, la Baleine à bosse et le Cachalot disponibles en 3 langues.";

        // Menu links
        this.links = [
            { url: Route.OurGames + "#epg", name: "Escape Games" },
            { url: Route.OurGames + "#jfl", name: "Jeux Flash" },
            { url: Route.OurGames + "#bds", name: "Bulles de sciences" },
            { url: Route.OurGames + "#dvs", name: "Divers" },
            { url: Route.OurGames + "#cmu", name: "A venir" },
        ];
    }

    ngOnInit(): void
    {
        // Escape Games
        this.apollo.query<EscapeGames>({ query: FIND_ESCAPEGAMES }).subscribe(result => 
        {
            this.escapeGames = result.data.escapeGames;
        });

        // Divers
        this.apollo.query<VariousActivities>({ query: FIND_VARIOUS_ACTIVITIES }).subscribe(result => 
        {
            this.variousActivities = result.data.variousActivities;
        });

        // A venir
        this.apollo.query<ToComes>({ query: FIND_TO_COMES }).subscribe(result => 
        {
            this.toComes = result.data.toComes;
        });
    }

    openUrl(link: string)
    {
        window.open(link, "_blank");
    }
}
