
<!-- Photo Full Viewport -->
<app-presentation title="Mon École, Ma Baleine" [content]="introContent" urlImage="assets/images/cetaces/GrandDauphin/GRA_3.jpg"></app-presentation>

<app-bg-adaptable urlImage="assets/images/cetaces/Autres/IMG_6260a-gpe-actif-Frederic-SIZE.jpg" bgColor="bg-blue-dark">

    <separator></separator>

    <app-content-single-button title="Les cétacés des Antilles" buttonName="En savoir plus" buttonUrl="./GeneralitesCetace">       
        Ces grands mammifères marins puissants mais fragiles nagent au large de nos côtes.<br><br>Parfois timides, joueurs et curieux, ils sont appelés cétacés.
    </app-content-single-button>

    <separator heighRem="8"></separator>

</app-bg-adaptable>

<app-bg-adaptable bgColor="bg-blue-dark">

    <separator></separator>

    <app-title title="Les cétacés de la semaine" [useThemeDark]="true"></app-title>
    <div class="container">
        <div class="row">
                
            <ng-container *ngFor="let cotw of cetaceanOfTheWeek">
                <app-card-type-e class="col-12 col-md-6 col-lg-4 g-0" 
                    [name]="cotw.commonName"
                    [cetaceanId]="cotw.id"
                    [urlImageStandard]="cotw.primeImage ? backendUrl + cotw.primeImage.url : ''">
                </app-card-type-e>
            </ng-container>
    
        </div>
    </div>

    <separator heighRem="8"></separator>

</app-bg-adaptable>

<app-bg-adaptable urlImage="assets/images/antilles/Fond_Antilles.png" bgColor="bg-blue-dark">

    <separator></separator>

    <app-content-single-button title="Les petites Antilles" buttonName="En savoir plus" buttonUrl="./GeneralitesAntilles">       
        Survolons ensemble ce qui fait la particularité des petites Antilles.<br><br>Quelles sont les caractéristiques des petites Antilles ?<br>Où se situent-elles ?<br>Quels animaux les peuplent ?
    </app-content-single-button>

    <separator heighRem="8"></separator>

</app-bg-adaptable>

<app-bg-adaptable bgColor="bg-blue-dark">

    <separator></separator>
    
    <app-title title="Nos dernières actus" [useThemeDark]="true"></app-title>
    <div class="container">
        <div class="row">
            
            <ng-container *ngFor="let new of news">
                <app-card-type-b class="col-xs-12 col-lg-3 g-0" [routerLink]="['/Actu', new.id]"
                    [name]="new.title"
                    [subname]="new.creationDate | date : 'd MMMM YYYY'"
                    [urlImage]="new.primeImage ? backendUrl + new.primeImage.url : ''">
                </app-card-type-b>
            </ng-container>
            
        </div>
    </div>

    <separator heighRem="8"></separator>

</app-bg-adaptable>