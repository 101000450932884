
<div class="position-relative">
    <div *ngIf="useImage" class="position-absolute top-0 left-0 w-100 h-100 background-cover" [ngStyle]="{'background-image': 'url(' + urlImage + ')'}"></div>
    <!--<div *ngIf="useImage && !removeGradient" class="position-absolute top-0 left-0 w-100 h-100 degrade-blue"></div>-->
    <div *ngIf="useImage && !removeGradient" class="position-absolute top-0 left-0 w-100 h-100 degrade-black"></div>
    
    <div class="container d-flex flex-column align-items-center position-inherit" [ngClass]="useImage ? 'withImage' : 'withoutImage'">

        <flags *ngIf="useFlag"></flags>
        <div class="typo_1 text-center" [ngClass]="useImage ? 'color-blue-light' : 'color-blue-dark'">{{ title }}</div>
    
        <img class="mt-8r mb-32r" src="assets/images/Separator_Multi.svg"/>

        <div class="typo_3 text-center" [ngClass]="useImage ? 'color-blue-light' : 'color-blue-dark'">         
            <div class="mx-16r mb-96r" [innerHTML]="content"></div>
        </div>

    </div>
</div>