import { Component } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';

import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent, setCurrentScreen } from "firebase/analytics";

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent
{
    constructor(private router: Router)
    {
        const firebaseConfig =
        {
            apiKey: "AIzaSyDink0NQpncnE2QcM_sUK_Qk3iGlJBy3zs",
            authDomain: "monecolemabaleine-dc251.firebaseapp.com",
            projectId: "monecolemabaleine-dc251",
            storageBucket: "monecolemabaleine-dc251.appspot.com",
            messagingSenderId: "309870647981",
            appId: "1:309870647981:web:bfea8199a4b87b90ab4424",
            measurementId: "G-JW9X2KKNXF"
        };

        const app = initializeApp(firebaseConfig);
        const analytics = getAnalytics(app);

        router.events.subscribe((evt) =>
        {
            if (evt instanceof NavigationStart)
            {
                setCurrentScreen(analytics, evt.url);
                logEvent(analytics, "screen_view");
            }
        });
    }

    onActivate()
    {
        window.scroll(0,0);
    }
}
