
import gql from "graphql-tag";

export interface Report
{
    url: string;
}

export interface AnnualReport
{
    name: string;
    year: number;

    report: Report;
}

export interface AnnualReports
{
    annualReports: AnnualReport[];
}

export const FIND_ANNUAL_REPORTS = gql`
query AnnualReports
{
    annualReports(sort: "year:desc")
    {
        name
        report
        {
            url
        }
        year
    }
}`;