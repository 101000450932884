
<div class="position-relative card-type-a">

    <!-- Image + Mask -->
    <div class="position-absolute top-0 start-0 w-100 mask-img" [ngStyle]="{'clip-path': 'url(#svgmask' + idCard + ')'}">
        <div class="position-absolute background-cover top-0 start-0 w-100 pt-100" [ngStyle]="{'background-image': 'url(' + urlImage + ')'}"></div>
    </div>
    
    <svg class="position-absolute">
        <clipPath [attr.id]="'svgmask' + idCard" class="svganim" clipPathUnits="objectBoundingBox">
            <path d="M0.194,0.254c0.163-0.208,0.545-0.166,0.639,0.04c0.124,0.275,0.018,0.462-0.118,0.538 C0.609,0.891,0.326,0.937,0.187,0.731C0.06,0.543,0.117,0.352,0.194,0.254z"></path>
        </clipPath>
    </svg>

    <!-- Difficulty -->
    <div *ngIf="difficulty != ''" class="position-absolute typo_6 difficultyChip">
        <div class="position-relative d-flex justify-content-center align-items-center h-100">
            {{ difficulty }}
            <div class="position-absolute difficultyChipInfo typo_10_D h-100 d-flex justify-content-center align-items-center text-center">
                <div *ngIf="difficulty == '1'">2 - 5ans</div>
                <div *ngIf="difficulty == '2'">6 - 7ans</div>
                <div *ngIf="difficulty == '3'">8 - 9ans</div>
                <div *ngIf="difficulty == '4'">10 - 11ans</div>
                <div *ngIf="difficulty == '5'">12ans et +</div>
            </div>
        </div>
    </div>

    <!-- Core -->
    <div class="position-absolute start-0 w-100 text-center top-delta section-mid">
        <div class="typo_6 color-turquoise-medium mb-16r">{{ name }}</div>
        <div *ngIf="subname" class="typo_10_C color-blue-medium">{{ subname }}</div>
    </div>
    
    <!-- Core Hover -->
    <div class="position-absolute bottom-0 start-0 w-100 bg-wave px-24r pt-96r text-center pb-40r">
        <div class="typo_6 color-blue-light mb-16r">{{ name }}</div>
        <div class="typo_4_B color-blue-light mb-48r">{{ description }}</div>

        <div class="d-flex flex-wrap align-items-center justify-content-center">
            
            <div class="typo_8 downloadButton mx-8r mb-16r position-relative" *ngIf="urlActivityFr != ''">
                <div class="position-absolute px-16r py-16r actcor">
                    <div class="downloadBtn d-flex flex-row align-items-center justify-content-between" (click)="onActivityFrClicked()">
                        <div class="mr-16r">{{ !scienceBubbleAlternative ? "Activité" : "PC" }}</div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="15.24" height="16" viewBox="0 0 26.204 27.43">
                            <path d="M-6785.089,2559.225a.993.993,0,0,1-.413-.089h-20.051a1,1,0,0,1-.413.089h-1.664a1,1,0,0,1-1-1V2552.9a1,1,0,0,1,1-1h1.664a1,1,0,0,1,1,1v2.575h18.876V2552.9a1,1,0,0,1,1-1h1.664a1,1,0,0,1,1,1v5.329a1,1,0,0,1-1,1Zm-11.512-8.094-7.414-5.227a1.853,1.853,0,0,1-.446-2.577,1.854,1.854,0,0,1,1.2-.764,1.855,1.855,0,0,1,1.391.319l4.486,3.134v-12.368a1.854,1.854,0,0,1,1.854-1.854,1.853,1.853,0,0,1,1.852,1.854v12.268l4.45-3.35a1.855,1.855,0,0,1,2.6.371,1.855,1.855,0,0,1-.371,2.6l-7.414,5.56a1.854,1.854,0,0,1-1.112.371h-.017A1.844,1.844,0,0,1-6796.6,2551.131Z" transform="translate(6808.629 -2531.795)" class="downloadSvg"/>
                        </svg>                          
                    </div>
                    <div class="downloadBtn d-flex flex-row align-items-center justify-content-between mt-16r" *ngIf="urlCorrectionFr != ''" (click)="onCorrectionFrClicked()">
                        <div class="mr-16r">{{ !scienceBubbleAlternative ? "Correction" : "MAC" }}</div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="15.24" height="16" viewBox="0 0 26.204 27.43">
                            <path d="M-6785.089,2559.225a.993.993,0,0,1-.413-.089h-20.051a1,1,0,0,1-.413.089h-1.664a1,1,0,0,1-1-1V2552.9a1,1,0,0,1,1-1h1.664a1,1,0,0,1,1,1v2.575h18.876V2552.9a1,1,0,0,1,1-1h1.664a1,1,0,0,1,1,1v5.329a1,1,0,0,1-1,1Zm-11.512-8.094-7.414-5.227a1.853,1.853,0,0,1-.446-2.577,1.854,1.854,0,0,1,1.2-.764,1.855,1.855,0,0,1,1.391.319l4.486,3.134v-12.368a1.854,1.854,0,0,1,1.854-1.854,1.853,1.853,0,0,1,1.852,1.854v12.268l4.45-3.35a1.855,1.855,0,0,1,2.6.371,1.855,1.855,0,0,1-.371,2.6l-7.414,5.56a1.854,1.854,0,0,1-1.112.371h-.017A1.844,1.844,0,0,1-6796.6,2551.131Z" transform="translate(6808.629 -2531.795)" class="downloadSvg"/>
                        </svg> 
                    </div>
                </div>
                FRANCAIS
            </div>

            <div class="typo_8 downloadButton mx-8r mb-16r position-relative" *ngIf="urlActivityEn != ''">
                <div class="position-absolute px-16r py-16r actcor">
                    <div class="downloadBtn d-flex flex-row align-items-center justify-content-between" (click)="onActivityEnClicked()">
                        <div class="mr-16r">{{ !scienceBubbleAlternative ? "Activity" : "PC" }}</div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="15.24" height="16" viewBox="0 0 26.204 27.43">
                            <path d="M-6785.089,2559.225a.993.993,0,0,1-.413-.089h-20.051a1,1,0,0,1-.413.089h-1.664a1,1,0,0,1-1-1V2552.9a1,1,0,0,1,1-1h1.664a1,1,0,0,1,1,1v2.575h18.876V2552.9a1,1,0,0,1,1-1h1.664a1,1,0,0,1,1,1v5.329a1,1,0,0,1-1,1Zm-11.512-8.094-7.414-5.227a1.853,1.853,0,0,1-.446-2.577,1.854,1.854,0,0,1,1.2-.764,1.855,1.855,0,0,1,1.391.319l4.486,3.134v-12.368a1.854,1.854,0,0,1,1.854-1.854,1.853,1.853,0,0,1,1.852,1.854v12.268l4.45-3.35a1.855,1.855,0,0,1,2.6.371,1.855,1.855,0,0,1-.371,2.6l-7.414,5.56a1.854,1.854,0,0,1-1.112.371h-.017A1.844,1.844,0,0,1-6796.6,2551.131Z" transform="translate(6808.629 -2531.795)" class="downloadSvg"/>
                        </svg> 
                    </div>
                    <div class="downloadBtn d-flex flex-row align-items-center justify-content-between mt-16r" *ngIf="urlCorrectionEn != ''"  (click)="onCorrectionEnClicked()">
                        <div class="mr-16r">{{ !scienceBubbleAlternative ? "Correction" : "MAC" }}</div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="15.24" height="16" viewBox="0 0 26.204 27.43">
                            <path d="M-6785.089,2559.225a.993.993,0,0,1-.413-.089h-20.051a1,1,0,0,1-.413.089h-1.664a1,1,0,0,1-1-1V2552.9a1,1,0,0,1,1-1h1.664a1,1,0,0,1,1,1v2.575h18.876V2552.9a1,1,0,0,1,1-1h1.664a1,1,0,0,1,1,1v5.329a1,1,0,0,1-1,1Zm-11.512-8.094-7.414-5.227a1.853,1.853,0,0,1-.446-2.577,1.854,1.854,0,0,1,1.2-.764,1.855,1.855,0,0,1,1.391.319l4.486,3.134v-12.368a1.854,1.854,0,0,1,1.854-1.854,1.853,1.853,0,0,1,1.852,1.854v12.268l4.45-3.35a1.855,1.855,0,0,1,2.6.371,1.855,1.855,0,0,1-.371,2.6l-7.414,5.56a1.854,1.854,0,0,1-1.112.371h-.017A1.844,1.844,0,0,1-6796.6,2551.131Z" transform="translate(6808.629 -2531.795)" class="downloadSvg"/>
                        </svg> 
                    </div>
                </div>
                Anglais
            </div>

            <div class="typo_8 downloadButton mx-8r mb-16r position-relative" *ngIf="urlActivityEs != ''">
                <div class="position-absolute px-16r py-16r actcor">
                    <div class="downloadBtn d-flex flex-row align-items-center justify-content-between" (click)="onActivityEsClicked()">
                        <div class="mr-16r">{{ !scienceBubbleAlternative ? "Actividad" : "PC" }}</div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="15.24" height="16" viewBox="0 0 26.204 27.43">
                            <path d="M-6785.089,2559.225a.993.993,0,0,1-.413-.089h-20.051a1,1,0,0,1-.413.089h-1.664a1,1,0,0,1-1-1V2552.9a1,1,0,0,1,1-1h1.664a1,1,0,0,1,1,1v2.575h18.876V2552.9a1,1,0,0,1,1-1h1.664a1,1,0,0,1,1,1v5.329a1,1,0,0,1-1,1Zm-11.512-8.094-7.414-5.227a1.853,1.853,0,0,1-.446-2.577,1.854,1.854,0,0,1,1.2-.764,1.855,1.855,0,0,1,1.391.319l4.486,3.134v-12.368a1.854,1.854,0,0,1,1.854-1.854,1.853,1.853,0,0,1,1.852,1.854v12.268l4.45-3.35a1.855,1.855,0,0,1,2.6.371,1.855,1.855,0,0,1-.371,2.6l-7.414,5.56a1.854,1.854,0,0,1-1.112.371h-.017A1.844,1.844,0,0,1-6796.6,2551.131Z" transform="translate(6808.629 -2531.795)" class="downloadSvg"/>
                        </svg>
                    </div>
                    <div class="downloadBtn d-flex flex-row align-items-center justify-content-between mt-16r" *ngIf="urlCorrectionEs != ''" (click)="onCorrectionEsClicked()">
                        <div class="mr-16r">{{ !scienceBubbleAlternative ? "Corrección" : "MAC" }}</div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="15.24" height="16" viewBox="0 0 26.204 27.43">
                            <path d="M-6785.089,2559.225a.993.993,0,0,1-.413-.089h-20.051a1,1,0,0,1-.413.089h-1.664a1,1,0,0,1-1-1V2552.9a1,1,0,0,1,1-1h1.664a1,1,0,0,1,1,1v2.575h18.876V2552.9a1,1,0,0,1,1-1h1.664a1,1,0,0,1,1,1v5.329a1,1,0,0,1-1,1Zm-11.512-8.094-7.414-5.227a1.853,1.853,0,0,1-.446-2.577,1.854,1.854,0,0,1,1.2-.764,1.855,1.855,0,0,1,1.391.319l4.486,3.134v-12.368a1.854,1.854,0,0,1,1.854-1.854,1.853,1.853,0,0,1,1.852,1.854v12.268l4.45-3.35a1.855,1.855,0,0,1,2.6.371,1.855,1.855,0,0,1-.371,2.6l-7.414,5.56a1.854,1.854,0,0,1-1.112.371h-.017A1.844,1.844,0,0,1-6796.6,2551.131Z" transform="translate(6808.629 -2531.795)" class="downloadSvg"/>
                        </svg> 
                    </div>
                </div>
                Espagnol
            </div>

        </div>
    </div>
</div>