import { Settings } from '@/settings';
import { Component, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-subtitle-txt',
  templateUrl: './subtitle-txt.component.html',
  styleUrls: ['./subtitle-txt.component.scss']
})
export class SubtitleTxtComponent implements OnInit
{
    @Input() iconUrl: string | null = null;
    @Input() useCta: boolean = false;
    @Input() buttonName: string | null = null;
    @Input() urlTarget: string | null = "_self";
    @Input() buttonUrl: string | null  = null;
    
    @Input() bgColor: string = "bg-blue-light";
    @Input() bgDisableInLargeScreen: boolean = false;

    backendUrl: string = Settings.backEndUrl;

    classBgColor: string = this.bgColor;
  
    constructor()
    {
    }

    ngOnInit(): void 
    {
        this.classBgColor = this.bgDisableInLargeScreen ? this.bgColor + "-wa-dils" : this.bgColor + "-wa-std";
    }

    onBtnClicked() :void
    {
        if (this.buttonUrl && this.urlTarget)
        {
            window.open(this.buttonUrl, this.urlTarget)
        }
    }
}
