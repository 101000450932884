
import gql from "graphql-tag";

export interface Logo
{
    url: string;
}

export interface PartnerScientific
{
    name: string;
    link: string;
    description: string;
}
export interface PartnerAssoc
{
    name: string;
    link: string;
    description: string;
}
export interface Partner
{
    name: string;
    link: string;

    logo: Logo;
}

export interface Partners
{
    partners: Partner[];
}
export interface PartnersScientific
{
    partnersScientifics: PartnerScientific[];
}
export interface PartnersAssociations
{
    partnersAssociations: PartnerAssoc[];
}

export const FIND_PARTNERS = gql`
query Partners
{
    partners
    {
        name
        link
        logo
        {
            url
        }
    }
}`;

export const FIND_PARTNERS_ASSOC = gql`
query PartnersAssociation
{
    partnersAssociations
    {
        name
        link
        description
    }
}`;

export const FIND_PARTNERS_SCIENTIFIC = gql`
query PartnersScientific
{
    partnersScientifics
    {
        name
        link
        description
    }
}`;